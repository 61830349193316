import React, { useEffect} from "react";
import { connect, ConnectedProps } from "react-redux";
import { Row, Col, Button } from "reactstrap";

import classes from "../IntegrationPage/integration.module.css";

import {
  getAccount,
  editAccount,
  editPassword,
} from "../../store/account/actions";
import LoadingButton from "../../components/Common/LoadingButton";
import Actions from "../../store/account/actionTypes";
import LoadingResultAlert from "../../components/Common/LoadingResultAlert";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store";
import useAuth from "../../customHooks/useAuth";
import { getCustomServerValidation } from "../../helpers/validation_helper";

const { AvForm, AvField, AvGroup } = require("availity-reactstrap-validation");

type Props = ReduxProps;

const AccountTab = ({
  getAccount,
  account,
  editAccount,
  editPassword,
  error,
}: Props) => {
  const { t } = useTranslation();

  const handleChangePassClick = (event: Event, values: any) => {
    editPassword(values);
  };

  const handleValidSubmit = (event: Event, values: any) => {
    editAccount(values);
  };

  const defaultValues = account || false;
  const {info} = useAuth();

  useEffect(() => {
    getAccount();
  }, []);

  return (
    <React.Fragment>
      <h4 style={{ marginBottom: "2.5rem" }}>{t("Account.title")}</h4>
      <Row>
        <Col
          lg="2"
          className={`${classes.col__horizontal_centered} ${classes.border_right}`}
        >
          <img
            src="/feedbacks/users/avatarDefault.png"
            style={{ borderRadius: "50%", maxWidth: "100%" }}
            alt={"avatarDefault"}/>
          <br />
          <br />
          <Button
              color="secondary"
              style={{ maxWidth: "100%", padding: "5px 8px" }}
          >
            {info?.email != undefined ? ((info?.email?.length > 15 ) ? info?.email?.replace("@", "\n @") : info?.email) : ''}
          </Button>
        </Col>
        <Col lg="10" className={classes.col__padding_bigger}>
          {defaultValues && (
            <>
              <h5 style={{ marginBottom: "2rem" }}>
                {t("Account.basicInformation")}
              </h5>
            <AvForm
              model={defaultValues}
              className="form-horizontal"
              onValidSubmit={handleValidSubmit}
            >
                <LoadingResultAlert actiontype={Actions.EDIT_ACCOUNT} />
                <div className="mb-3">
                  <AvField
                    name="firstname"
                    label={t("Account.firstname")}
                    className="form-control"
                    placeholder={t("Account.firstname")}
                    type="text"
                    required
                    errorMessage={t("Common.required")}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="surname"
                    label={t("Account.surname")}
                    className="form-control"
                    placeholder={t("Account.surname")}
                    type="text"
                    required
                    errorMessage={t("Common.required")}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="email"
                    label={t("Common.email")}
                    className="form-control"
                    placeholder={t("Common.email")}
                    type="email"
                    required
                    errorMessage={t("Common.required")}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="phone"
                    label={t("Account.phone")}
                    className="form-control"
                    placeholder={t("Account.phone")}
                    type="text"
                    required
                    errorMessage={t("Common.required")}
                  />
                </div>
                <div className="text-end">
                  <LoadingButton
                    color="primary"
                    actiontype={Actions.EDIT_ACCOUNT}
                    title={t("Account.save")}
                    type="submit"
                  ></LoadingButton>
                </div>
            </AvForm>
           </>
          )}
          <h5 style={{ marginBottom: "2rem", marginTop: "2rem" }}>
            {t("Account.changePassword")}
          </h5>
          <LoadingResultAlert actiontype={Actions.EDIT_PASSWORD} />
          <AvForm onValidSubmit={handleChangePassClick}>
            <div className="mb-3">
              <AvField
                  name="oldPwd"
                  label={t("Account.oldPassword")}
                  className="form-control"
                  placeholder={t("Account.oldPassword")}
                  type="password"
                  required
                  errorMessage={t("Common.required")}
                  validate={getCustomServerValidation("oldPwd", error)}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="newPwd"
                label={t("Account.newPassword")}
                className="form-control"
                placeholder={t("Account.newPassword")}
                type="password"
                required
                errorMessage={t("Common.required")}
                validate={getCustomServerValidation("newPwd", error)}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="checkNewPwd"
                label={t("Account.confirmNewPassword")}
                className="form-control"
                placeholder={t("Account.confirmNewPassword")}
                type="password"
                required
                validate={
                  {match:{value:'newPwd', errorMessage: t("Account.passNotSame")}} ||
                  getCustomServerValidation("checkNewPwd", error)
                }
                errorMessage={t("Common.required")}
              />
            </div>
            <div className="text-end">
              <LoadingButton
                color="primary"
                actiontype={Actions.EDIT_PASSWORD}
                title={t("Account.changePasswordButton")}
                type="submit"
              ></LoadingButton>
            </div>
          </AvForm>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const connector = connect(
  (state: RootState) => ({ account: state.EditAccount.account.data, error: state.EditAccount.password.error }),
  {
    getAccount,
    editAccount,
    editPassword,
  }
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AccountTab);
