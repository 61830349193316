import Actions from "./actionTypes";

import { combineReducers } from "redux";
import {
    GetHeurekaSettings,
    GetHeurekaSettingsSuccess,
    SetHeurekaSettings,
    SetHeurekaSettingsSuccess,
    SetHeurekaSettingsFail,
    GetZboziSettings,
    GetZboziSettingsSuccess,
    SetZboziSettings,
    SetZboziSettingsSuccess,
    SetZboziSettingsFail, ClearZboziSettingsError
} from "./actions";

import {
  IntegrationHeurekaSettingModel,
  IntegrationZboziczSettingModel,
} from "../../models/common/fromGenerated";
import {IValidationError} from "../../models/common/saga";

const initialHeurekaState: IHeurekaState = {
  data: null,
  error: null,
  setdata: null,
};

interface IHeurekaState {
  data: IntegrationHeurekaSettingModel | null;
  error: IValidationError | any;
  setdata: any;
}

type HeurekaActions = GetHeurekaSettings
    | GetHeurekaSettingsSuccess
    | SetHeurekaSettings
    | SetHeurekaSettingsSuccess
    | SetHeurekaSettingsFail;

const heureka = (
  state = initialHeurekaState,
  action: HeurekaActions
): IHeurekaState => {
  switch (action.type) {
    case Actions.GET_HEUREKA_SETTINGS_SUCCESS:
      return { ...state, data: action.payload };

    case Actions.GET_HEUREKA_SETTINGS:
      return { ...state, data: null };

    case Actions.SET_HEUREKA_SETTINGS:
      return { ...state };

    case Actions.SET_HEUREKA_SETTINGS_SUCCESS:
      return { ...state, setdata: action.payload };

    case Actions.SET_HEUREKA_SETTINGS_FAIL:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

const initialZboziState: IZboziState = {
  data: null,
  error: null,
  setdata: null,
};

interface IZboziState {
  data: IntegrationZboziczSettingModel | null;
  error: IValidationError | any;
  setdata: any;
}

type ZboziActions = GetZboziSettings
    | GetZboziSettingsSuccess
    | SetZboziSettings
    | SetZboziSettingsSuccess
    | SetZboziSettingsFail
    | ClearZboziSettingsError;

const zbozi = (
  state = initialZboziState,
  action: ZboziActions
): IZboziState => {
  switch (action.type) {
    case Actions.GET_ZBOZI_SETTINGS_SUCCESS:
      return { ...state, data: action.payload };

    case Actions.GET_ZBOZI_SETTINGS:
      return { ...state, data: null };

    case Actions.SET_ZBOZI_SETTINGS:
      return { ...state };

    case Actions.SET_ZBOZI_SETTINGS_SUCCESS:
      return { ...state, setdata: action.payload };

    case Actions.SET_ZBOZI_SETTINGS_FAIL:
      return { ...state, error: action.payload };

    case Actions.SET_ZBOZI_SETTINGS_CLEAR_ERROR:
      return {...state, error:null};

    default:
      return state;
  }
};

export default combineReducers({
  heureka,
  zbozi,
});
