import {
  IntegrationHeurekaSettingModel,
  IntegrationZboziczSettingModel,
} from "../../models/common/fromGenerated";
import { ErrorFromApi, IServerError, IValidationError, reduxAction, reduxActionBasic } from "../../models/common/saga";
import Actions from "./actionTypes";
import EAction from "../eshop/actionTypes";

export const getHeurekaSettings = () =>
  reduxActionBasic(Actions.GET_HEUREKA_SETTINGS);

export const getHeurekaSettingsSuccess = (
  payload: IntegrationHeurekaSettingModel
) => reduxAction(Actions.GET_HEUREKA_SETTINGS_SUCCESS, payload);

export const getHeurekaSettingsFail = (payload: ErrorFromApi) =>
  reduxAction(Actions.GET_HEUREKA_SETTINGS_FAIL, payload);

export const setHeurekaSettings = (data: IntegrationHeurekaSettingModel) =>
  reduxAction(Actions.SET_HEUREKA_SETTINGS, data);

export const setHeurekaSettingsSuccess = ( response: any, message: string) =>
  reduxAction(Actions.SET_HEUREKA_SETTINGS_SUCCESS, { response, message });

export const setHeurekaSettingsFail = (error: ErrorFromApi) =>
  reduxAction(Actions.SET_HEUREKA_SETTINGS_FAIL, error);

export const getZboziSettings = () =>
  reduxActionBasic(Actions.GET_ZBOZI_SETTINGS);

export const getZboziSettingsSuccess = (data: IntegrationZboziczSettingModel) =>
  reduxAction(Actions.GET_ZBOZI_SETTINGS_SUCCESS, data);

export const getZboziSettingsFail = (payload: ErrorFromApi) =>
  reduxAction(Actions.GET_ZBOZI_SETTINGS_FAIL, payload);

export const setZboziSettings = (data: IntegrationZboziczSettingModel) =>
  reduxAction(Actions.SET_ZBOZI_SETTINGS, data);

export const setZboziSettingsSuccess = (response: any, message: string) =>
  reduxAction(Actions.SET_ZBOZI_SETTINGS_SUCCESS, { response, message });

export const setZboziSettingsFail = (payload: ErrorFromApi) =>
  reduxAction(Actions.SET_ZBOZI_SETTINGS_FAIL, payload);

export const clearZboziSettingsError = () =>
  reduxActionBasic(Actions.SET_ZBOZI_SETTINGS_CLEAR_ERROR);

export type GetHeurekaSettings = ReturnType<typeof getHeurekaSettings>;
export type GetHeurekaSettingsSuccess = ReturnType<
  typeof getHeurekaSettingsSuccess
>;
export type GetHeurekaSettingsFail = ReturnType<typeof getHeurekaSettingsFail>;
export type SetHeurekaSettings = ReturnType<typeof setHeurekaSettings>;
export type SetHeurekaSettingsSuccess = ReturnType<
  typeof setHeurekaSettingsSuccess
>;
export type SetHeurekaSettingsFail = ReturnType<typeof setHeurekaSettingsFail>;
export type GetZboziSettings = ReturnType<typeof getZboziSettings>;
export type GetZboziSettingsSuccess = ReturnType<
  typeof getZboziSettingsSuccess
>;
export type GetZboziSettingsFail = ReturnType<typeof getZboziSettingsFail>;
export type SetZboziSettings = ReturnType<typeof setZboziSettings>;
export type SetZboziSettingsSuccess = ReturnType<
  typeof setZboziSettingsSuccess
>;
export type SetZboziSettingsFail = ReturnType<typeof setZboziSettingsFail>;
export type ClearZboziSettingsError = ReturnType<typeof clearZboziSettingsError>;
