enum Eaction {
  GET_HEUREKA_SETTINGS = "GET_HEUREKA_SETTINGS",
  GET_HEUREKA_SETTINGS_SUCCESS = "GET_HEUREKA_SETTINGS_SUCCESS",
  GET_HEUREKA_SETTINGS_FAIL = "GET_HEUREKA_SETTINGS_FAIL",

  SET_HEUREKA_SETTINGS = "SET_HEUREKA_SETTINGS",
  SET_HEUREKA_SETTINGS_SUCCESS = "SET_HEUREKA_SETTINGS_SUCCESS",
  SET_HEUREKA_SETTINGS_FAIL = "SET_HEUREKA_SETTINGS_FAIL",

  GET_ZBOZI_SETTINGS = "GET_ZBOZI_SETTINGS",
  GET_ZBOZI_SETTINGS_SUCCESS = "GET_ZBOZI_SETTINGS_SUCCESS",
  GET_ZBOZI_SETTINGS_FAIL = "GET_ZBOZI_SETTINGS_FAIL",

  SET_ZBOZI_SETTINGS = "SET_ZBOZI_SETTINGS",
  SET_ZBOZI_SETTINGS_SUCCESS = "SET_ZBOZI_SETTINGS_SUCCESS",
  SET_ZBOZI_SETTINGS_FAIL = "SET_ZBOZI_SETTINGS_FAIL",
  SET_ZBOZI_SETTINGS_CLEAR_ERROR = "SET_ZBOZI_SETTINGS_CLEAR_ERROR",
}
export default Eaction;
