import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup, Modal, ModalBody, ModalHeader,
} from "reactstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { TableCellProps, UseTableCellProps } from "react-table";
import { ColumnData } from "./EventsTable";
import {FiEdit, FiX} from "react-icons/fi";

const ActionCellComponent = (props: UseTableCellProps<ColumnData>) => {

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [hover, setHover] = useState(false);
  const onHover = () => {setHover(true);};
  const onLeave = () => {setHover(false);};

  let longMessage = props.row.original.longMessage.replaceAll("\n","<br/>");

  const { t } = useTranslation();
  return (
    <>
      <div className="d-flex justify-content-end">
        {
          props.row.original.longMessage &&
              <ButtonGroup>
                  <Button
                      style={{position: "relative"}}
                      color="primary"
                      onClick={handleOpenModal}
                          onMouseOver={onHover}
                          onMouseLeave={onLeave}>
                      <FiEdit size={20}/>
                      {hover &&
                          <div
                              style={{position: "absolute",
                                  left: "30%",
                                  top: "-10px",
                                  color: "#495057",
                                  backgroundColor: "#ffffff",
                                  border: "solid 1px #495057",
                                  padding: "1px 4px",
                                  whiteSpace: "nowrap"}}
                          >
                              {t("Events.table.detail")}
                          </div>
                      }
                  </Button>

              </ButtonGroup>
        }
      </div>
      <Modal
          centered
          isOpen={showModal}
          onHide={handleCloseModal}
      >
          <div className="d-flex justify-content-end">
            <Button
                data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top"
                centered
                style={{backgroundColor: "transparent", border: "none"}}
                className="text-uppercase"
                onClick={handleCloseModal}
            >
                <FiX color="black" size="20"/>
            </Button>
          </div>
          <div className="px-5 d-block text-center">
            <h5 className="mb-3 mt-0">
                {props.row.original.message}
            </h5>
            <p className="mb-4">
                <div dangerouslySetInnerHTML={{__html: longMessage}} />
            </p>
          </div>
      </Modal>
    </>
  );
};


export const ActionCell = ActionCellComponent;

export const EventsTypeCell = (props: any) => {
  return props.value;
};

EventsTypeCell.propTypes = {
  value: PropTypes.string,
};
