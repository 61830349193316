enum StoreTypeEnum {
  SHOP = "SHOP",
  PRODUCT = "PRODUCT",
}

export const getStoreTypeEnumFromString = (input: string) : StoreTypeEnum => {
  const typedColorString = input as keyof typeof StoreTypeEnum;
  return StoreTypeEnum[typedColorString];
}

export default StoreTypeEnum;
