import { takeEvery, put, call } from "redux-saga/effects";
import EAction from "./actionTypes";
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  UserForgetPassword,
} from "./actions";

import { forgetPassword } from "../../../helpers/api";
import {ResetPasswordByEmailRequestModel} from "../../../generated-sources/openapi";
import {isAxiosError} from "../../../models/common/saga";
import {convertAxiosErrorToValidationError} from "../../../utils/errorUtils";

function* forgetUser(action: UserForgetPassword) {
  try {
    const response: ResetPasswordByEmailRequestModel = yield call(forgetPassword, action.payload.user);
    yield put(userForgetPasswordSuccess(response, 'ForgetPassword.mailSent'));
  } catch (error) {
    if (isAxiosError(error)) {
      const validationError = convertAxiosErrorToValidationError(
          error,
          action.payload.user
      );
      yield put(userForgetPasswordError(validationError));
    } else {
      yield put(userForgetPasswordError(error as Error));
    }
  }
}


function* forgetPasswordSaga() {
  yield takeEvery(EAction.FORGET_PASSWORD, forgetUser);
}

export default forgetPasswordSaga;
