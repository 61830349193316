import { AuthInfoModel } from "../generated-sources/openapi/api";

const AUTH_SAVED_TIME = "auth_saved_datetime";
const AUTH_USER_KEY = "authUser";

export const setAuth = (
  value: AuthInfoModel,
  { wantpersist }: { wantpersist: boolean }
) => {
  if (wantpersist) {
    localStorage.setItem(AUTH_USER_KEY, JSON.stringify(value));
    localStorage.setItem(AUTH_SAVED_TIME, Date.now().toString());
  } else {
    sessionStorage.setItem(AUTH_USER_KEY, JSON.stringify(value));
  }
};

const days30 = 30 * 24 * 60 * 60 * 1000;

export const getAuth = (): AuthInfoModel | null => {
  const local = localStorage.getItem(AUTH_USER_KEY);
  const session = sessionStorage.getItem(AUTH_USER_KEY);

  const savedTimeStamp = localStorage.getItem(AUTH_SAVED_TIME);
  const timeStamp: number = savedTimeStamp != null ? Number(savedTimeStamp) : 0;

  //   console.log("minus", timeStamp, Date.now() - timeStamp);
  //   console.log(local);
  //   console.log(session);

  try {
    if (session) return JSON.parse(session) as AuthInfoModel;
    if (local && Math.abs(Date.now() - timeStamp) < days30)
      return JSON.parse(local) as AuthInfoModel;
    return null;
  } catch {
    return null;
  }
};
