import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getFeedbacksLatest,
  changeFeedbacksLatestPaging,
} from "../../store/feedbacks/actions";

import TablePaging from "../../components/Common/TablePaging";
import { RootState } from "../../store";
import { ISimpleReduxAction } from "../../models/common/saga";

export default connect(
  (state : RootState) => ({
    // feedbacks: state.Feedbacks.latest.data,
    paging: state.Feedbacks.latest.page || {},
    totalItems: state.Feedbacks.latest.total,
  }),
  {
    refreshData: getFeedbacksLatest,
    changePaging: changeFeedbacksLatestPaging,
  }
)(TablePaging);
