enum EAction {
  GET_FEEDBACKS = "GET_FEEDBACKS",
  GET_FEEDBACKS_SUCCESS = "GET_FEEDBACKS_SUCCESS",
  GET_FEEDBACKS_FAIL = "GET_FEEDBACKS_FAIL",

  GET_FEEDBACKS_OVERVIEW = "GET_FEEDBACKS_OVERVIEW",
  GET_FEEDBACKS_OVERVIEW_SUCCESS = "GET_FEEDBACKS_OVERVIEW_SUCCESS",
  GET_FEEDBACKS_OVERVIEW_FAIL = "GET_FEEDBACKS_OVERVIEW_FAIL",

  GET_FEEDBACKS_LATEST = "GET_FEEDBACKS_LATEST",
  GET_FEEDBACKS_LATEST_SUCCESS = "GET_FEEDBACKS_LATEST_SUCCESS",
  GET_FEEDBACKS_LATEST_FAIL = "GET_FEEDBACKS_LATEST_FAIL",

  CHANGE_FEEDBACKS_OVERVIEW_PAGING = "CHANGE_FEEDBACKS_OVERVIEW_PAGING",
  CHANGE_FEEDBACKS_OVERVIEW_FILTER = "CHANGE_FEEDBACKS_OVERVIEW_FILTER",

  CHANGE_FEEDBACKS_LATEST_PAGING = "CHANGE_FEEDBACKS_LATEST_PAGING",

  SYNC_FILTERS_PAGING_FROM_LATEST_TO_OVERVIEW = "SYNC_FILTERS_PAGING_FROM_LATEST_TO_OVERVIEW",

  GET_FEEDBACK_BY_ID = "GET_FEEDBACK_BY_ID",
  GET_FEEDBACK_BY_ID_SUCCESS = "GET_FEEDBACK_BY_ID_SUCCESS",
  GET_FEEDBACK_BY_ID_FAIL = "GET_FEEDBACK_BY_ID_FAIL",

  SEND_FEEDBACK_RESPONSE = "SEND_FEEDBACK_RESPONSE",
  SEND_FEEDBACK_RESPONSE_SUCCESS = "SEND_FEEDBACK_RESPONSE_SUCCESS",
  SEND_FEEDBACK_RESPONSE_FAIL = "SEND_FEEDBACK_RESPONSE_FAIL",

  SET_READ_FEEDBACK_BY_ID = "SET_READ_FEEDBACK_BY_ID",
  SET_READ_FEEDBACK_BY_ID_SUCCESS = "SET_READ_FEEDBACK_BY_ID_SUCCESS",
  SET_READ_FEEDBACK_BY_ID_FAIL = "SET_READ_FEEDBACK_BY_ID_FAIL",
}
export default EAction;
