enum EAction {
  EDIT_ACCOUNT = "EDIT_ACCOUNT",
  EDIT_ACCOUNT_SUCCESS = "EDIT_ACCOUNT_SUCCESS",
  EDIT_ACCOUNT_FAIL = "EDIT_ACCOUNT_FAIL",

  GET_ACCOUNT = "GET_ACCOUNT",
  GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS",
  GET_ACCOUNT_FAIL = "GET_ACCOUNT_FAIL",

  EDIT_PASSWORD = "EDIT_PASSWORD",
  EDIT_PASSWORD_SUCCESS = "EDIT_PASSWORD_SUCCESS",
  EDIT_PASSWORD_FAIL = "EDIT_PASSWORD_FAIL",

  RESET_PASSWORD = "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL",
}

export default EAction;