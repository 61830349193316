import React from "react";
import { Container, Row, Col } from "reactstrap";
import GitInfo from "react-git-info/macro";
import { useTranslation } from "react-i18next";

const version = GitInfo();

const Footer = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={8}>{new Date().getFullYear()} © TrustBite</Col>
            <Col md={4} className={"d-flex justify-content-end"}>
              <div>
                {t("Common.version")} {version.major}.{version.minor}.
                {version.buildNumber}
              </div>
            </Col>
            {/* <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Developed by TrustBite
              </div>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
