import React, { useEffect } from "react";
import { getFeedbacksLatest } from "../../store/feedbacks/actions";
import { connect, ConnectedProps } from "react-redux";
import LatestFeedbackPaging from "./LatestFeedbackPaging";
import FeedbackDashboardCard from "./FeedbackDashboardCard";
import LoadingAware from "../../components/Common/LoadingAware";
import FeedbackActions from "../../store/feedbacks/actionTypes";
import Skeleton from "react-loading-skeleton";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";


const Placeholder = () => (
  <React.Fragment>
    <Skeleton count={5} height={150} className={"mb-3"}></Skeleton>
  </React.Fragment>
);

const FeedbackDashboard = ({ feedbacks, getFeedbacksLatest } : Props) => {
  useEffect(() => {
    getFeedbacksLatest();
  }, []);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <LoadingAware
        actiontypes={[FeedbackActions.GET_FEEDBACKS_LATEST]}
        placeholder={<Placeholder />}
      >
        {(feedbacks != null && feedbacks.length > 0)
            ? (<React.Fragment>{feedbacks.map((feedback, id) => (<FeedbackDashboardCard feedback={feedback} key={id}></FeedbackDashboardCard>) )}<LatestFeedbackPaging /></React.Fragment>)
            : (<React.Fragment>{t("Feedbacks.no_review_avaliable")}</React.Fragment>)
        }
      </LoadingAware>

    </React.Fragment>
  );
};



const connector = connect(
  (state : RootState) => ({ feedbacks: state.Feedbacks.latest.data }),
  {
    getFeedbacksLatest,
  }
);

type Props = ConnectedProps<typeof connector>;

export default connector(FeedbackDashboard);
