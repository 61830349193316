import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect, ConnectedProps } from "react-redux";
import { Button, Container, Col, Row, Label, Spinner } from "reactstrap";

import classes from "./integration.module.css";

import {
  getHeurekaSettings, setHeurekaSettings
} from "../../store/integration/actions";

import {
  AvCheckbox,
  AvField,
  AvForm,
  AvInput,
  AvGroup,
} from "availity-reactstrap-validation";
import { composeInitialProps, useTranslation } from "react-i18next";
import LoadingButton from "../../components/Common/LoadingButton";
import Actions from "../../store/integration/actionTypes";
import LoadingResultAlert from "../../components/Common/LoadingResultAlert";
import LoadingAware from "../../components/Common/LoadingAware";

import EConfigurationAction from "../../store/configuration/actionTypes";

import Skeleton from "react-loading-skeleton";
import { RootState } from "../../store";
import { ReviewService } from "../../models/common/fromGenerated";
import {getCustomServerValidation} from "../../helpers/validation_helper";

const getServiceName = (services : ReviewService[], id : string) => {
  const service = (services || []).filter(s => s.id == id)[0];
  return service && service.title;
};

const Placeholder = () => (
  <React.Fragment>
    <Skeleton width={"50%"} count={1} height={30} className={"mb-5"}></Skeleton>
    <Skeleton count={2} height={30} className={"mb-5"}></Skeleton>
    <Skeleton count={1} width={"45%"} height={80} className={"mb-5"}></Skeleton>
    <Skeleton width={"20%"} height={30}></Skeleton>
  </React.Fragment>
);

const HeurekaTab = ({
  getHeurekaSettings,
  setHeurekaSettings,
  eshopId,
  settings,
  heurekaTypes,
  reviewServices,
  error
} : Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (eshopId) getHeurekaSettings();
  }, [eshopId]);

  const handleSubmit = (event : Event, values : any) => {
    setHeurekaSettings(values);
  };

  return (
    <LoadingAware
      actiontypes={[Actions.GET_HEUREKA_SETTINGS, EConfigurationAction.GET_PORTAL_CONFIGURATION]}
      placeholder={<Placeholder></Placeholder>}
    >
      {settings && (
        <React.Fragment>
          <div className={classes.elements__on_sides}>
            <h5 style={{ marginBottom: "30px" }}>{t("Heureka.title")}</h5>
            <img src="/feedbacks/logo-heureka.png" />
          </div>
          <div>
            <AvForm model={settings} onValidSubmit={handleSubmit}>
              <Container>
                <div className="mb-3">
                  <AvField
                    name="code"
                    label={t("Heureka.key")}
                    className="form-control"
                    placeholder={t("Heureka.key")}
                    type="text"
                    validate={getCustomServerValidation("code", error)}
                  />
                </div>
                <div className="mb-3">
                  <AvField type="select" name="type" label={t("Heureka.type")}
                           validate={getCustomServerValidation("type", error)}
                  >
                    {heurekaTypes.map(type => (
                      <option
                        key={"key-" + type.internalName}
                        value={type.internalName}
                      >
                        {type.title}
                      </option>
                    ))}
                  </AvField>
                </div>
                <Row style={{ paddingTop: "1rem" }}>
                  <Col md="6" className="mb-3">
                    <h5>{t("Heureka.newReviewSettings")}</h5>
                  </Col>
                  <Col md="6"></Col>
                </Row>
                {settings.transferThresholds!.map((threshold, i) => (
                  <div key={"key" + i} className="mb-3">
                    <Row>
                      <Col md="3">
                        {/* <AvGroup check> */}
                        <Label>
                          {`${t("Heureka.transferTo")} ${getServiceName(
                            reviewServices,
                            threshold.serviceId!
                          )}`}
                        </Label>
                        {/* </AvGroup> */}
                      </Col>
                      <Col>
                        <AvInput
                          type="checkbox"
                          name={`transferThresholds[${i}].automaticTransfer`}
                          validate={getCustomServerValidation(`transferThresholds[${i}].automaticTransfer`, error)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Label check>{t("Heureka.minReviewPercent")}</Label>
                      </Col>
                      <Col md="2">
                        <AvField
                          key={"key" + i}
                          type="number"
                          name={`transferThresholds[${i}].threshold`}
                          validate={getCustomServerValidation(`transferThresholds[${i}].threshold`, error)}
                        ></AvField>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <AvField
                          type="hidden"
                          name={`transferThresholds[${i}].serviceId`}
                          validate={getCustomServerValidation(`transferThresholds[${i}].serviceId`, error)}
                        ></AvField>
                      </Col>
                    </Row>
                  </div>
                ))}

                <LoadingButton
                  title={t("Heureka.submit")}
                  className="btn btn-primary w-md mb-3"
                  type="submit"
                  actiontype={Actions.SET_HEUREKA_SETTINGS}
                ></LoadingButton>
                <LoadingResultAlert
                  actiontype={Actions.SET_HEUREKA_SETTINGS}
                ></LoadingResultAlert>
                <LoadingResultAlert
                  actiontype={Actions.GET_HEUREKA_SETTINGS}
                ></LoadingResultAlert>
              </Container>
            </AvForm>
          </div>
        </React.Fragment>
      )}
    </LoadingAware>
  );
};

const connector = connect(
  (state : RootState) => ({
    settings: state.Integration.heureka.data,
    eshopId: state.Eshop.eshopsList.selectedEshop,
    reviewServices:
      (state.Configuration.data && state.Configuration.data.reviewServices) ||
      [],
    heurekaTypes:
      (state.Configuration.data && state.Configuration.data.heurekaTypes) || [],
    error: state.Integration.heureka.error
  }),
  {
    getHeurekaSettings,
    setHeurekaSettings,
  }
)

type Props = ConnectedProps<typeof connector>

export default connector(HeurekaTab);
