import { reduxAction } from "../../../models/common/saga";
import { CredentialModel } from "../../../models/common/fromGenerated";
import EAction from "./actionTypes";

export const loginUser = (
  user: CredentialModel,
  history: any,
  rememberLogin: boolean
) => reduxAction(EAction.LOGIN_USER, { user, history, rememberLogin });


export const loginSuccess = (user: any) =>
reduxAction(EAction.LOGIN_SUCCESS, user);

export const loginFail = (error: any) =>
reduxAction(EAction.LOGIN_USER_FAIL, error);

export const logoutUser = (history: any) =>
reduxAction(EAction.LOGOUT_USER, { history });

export const logoutUserSuccess = () =>
reduxAction(EAction.LOGOUT_USER_SUCCESS, {});

export const apiError = (error: any) => reduxAction(EAction.API_ERROR, error);

export type LoginUserAction = ReturnType<typeof loginUser>;
export type LoginFailAction = ReturnType<typeof loginFail>;
export type LoginSuccessAction = ReturnType<typeof loginSuccess>;
export type LogoutUserAction = ReturnType<typeof logoutUser>;
export type LogoutUserSuccessAction = ReturnType<typeof logoutUserSuccess>;
export type ApiErrorAction = ReturnType<typeof apiError>;
