import Actions from "./actionTypes";

import { combineReducers } from "redux";
import {
  ChangeEshopsOverviewPagingAction,
  ClearConnectEshopError,
  ConnectEshopAction,
  ConnectEshopFailAction,
  ConnectEshopValidationFailAction,
  EditEshopByIdFailAction,
  EditEshopByIdValidationFailAction,
  GetAllEshopsAction,
  GetAllEshopsFailAction,
  GetAllEshopsSuccessAction,
  GetEshopByIdAction,
  GetEshopByIdSuccessAction,
  GetEshopsOverviewAction,
  GetEshopsOverviewSuccessAction,
  SetSelectedEshopAction,
} from "./actions";
import { EshopModel, PageModel } from "../../models/common/fromGenerated";
import { IValidationError } from "../../models/common/saga";

const initialAccountState = {
  error: null,
  validationError: null,
} as IConnectEshopState;

interface IConnectEshopState {
  error: Error | null;
  validationError: IValidationError | null;
}

type ConnectActions =
  | ConnectEshopAction
  | ConnectEshopFailAction
  | ConnectEshopValidationFailAction
  | ClearConnectEshopError;

const connectEshop = (
  state = initialAccountState,
  action: ConnectActions
): IConnectEshopState => {
  switch (action.type) {
    case Actions.CONNECT_ESHOP_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case Actions.CONNECT_ESHOP_VALIDATION_FAIL:
      return {
        ...state,
        validationError: action.payload,
      };
    case Actions.CONNECT_ESHOP_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        validationError: null,
      };
    default:
      return state;
  }
};

const initialState: IEshopsListState = {
  data: [],
  error: null,
  selectedEshop: null,
  loaded: false,
};

interface IEshopsListState {
  data: EshopModel[];
  error: Error | null;
  selectedEshop: string | null;
  loaded: boolean;
}

type EshopsListActions =
  | GetAllEshopsAction
  | GetAllEshopsFailAction
  | GetAllEshopsSuccessAction
  | SetSelectedEshopAction;

const eshopsList = (
  state = initialState,
  action: EshopsListActions
): IEshopsListState => {
  switch (action.type) {
    case Actions.GET_ALL_ESHOPS_SUCCESS: {
      const { payload } = action;
      const selectedEshop =
        state.selectedEshop || (payload.length > 0 ? payload[0].id : null);
      const selectedEshopExist = () =>
        payload.some(e => e.id === selectedEshop);
      return {
        ...state,
        data: payload,
        selectedEshop: selectedEshopExist() ? selectedEshop || null : null,
        loaded: true,
      };
    }
    case Actions.GET_ALL_ESHOPS_FAIL:
      return { ...state, error: action.payload };
    case Actions.SET_SELECTED_ESHOP:
      return { ...state, selectedEshop: action.payload };
    default:
      return state;
  }
};

const overviewInitialState = {
  data: [],
  page: {
    page: 0,
    recordPerPage: 5,
  },
  total: 100,
} as OverviewState;

type OverviewAction =
  | GetEshopsOverviewAction
  | ChangeEshopsOverviewPagingAction
  | GetEshopsOverviewSuccessAction;

interface OverviewState {
  data: EshopModel[];
  page?: PageModel;
  total?: number;
}

const eshopsOverview = (
  state = overviewInitialState,
  action: OverviewAction
): OverviewState => {
  switch (action.type) {
    case Actions.GET_ESHOPS_OVERVIEW_SUCCESS:
      return {
        ...state,
        data: action.payload.items || [],
        total: action.payload.totalItems,
        page: action.payload.page,
      };
    case Actions.CHANGE_ESHOPS_OVERVIEW_PAGING:
      return {
        ...state,
        page: {
          ...state.page,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

const initialEditEshopState = {
  data: null,
  validationError: null,
} as EditEshopState;

interface EditEshopState {
  data: EshopModel | null;
  validationError: IValidationError | null;
  error?: Error;
}

type EditEshopAction =
  | GetEshopByIdAction
  | GetEshopByIdSuccessAction
  | EditEshopByIdFailAction
  | EditEshopByIdValidationFailAction
  | ClearConnectEshopError;

const editEshop = (
  state = initialEditEshopState,
  action: EditEshopAction
): EditEshopState => {
  switch (action.type) {
    case Actions.GET_ESHOP_BY_ID_SUCCESS:
      return { ...state, data: action.payload };
    case Actions.GET_ESHOP_BY_ID: {
      return { ...state, data: null };
    }
    case Actions.EDIT_ESHOP_BY_ID_VALIDATION_FAIL:
      return { ...state, validationError: action.payload };
    case Actions.EDIT_ESHOP_BY_ID_FAIL:
      return { ...state, error: action.payload };
    case Actions.CONNECT_ESHOP_CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
        validationError: null,
      };
    default:
      return state;
  }
};

export default combineReducers({
  connectEshop,
  eshopsList,
  eshopsOverview,
  editEshop,
});
