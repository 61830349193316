import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect, ConnectedProps } from "react-redux";
import { Button, Container, Col, Row, Label, Spinner } from "reactstrap";

import classes from "./integration.module.css";

import {
  getZboziSettings, setZboziSettings, clearZboziSettingsError
} from "../../store/integration/actions";

import {
  AvCheckbox,
  AvField,
  AvForm,
  AvInput,
  AvGroup,
} from "availity-reactstrap-validation";
import { composeInitialProps, useTranslation } from "react-i18next";
import LoadingButton from "../../components/Common/LoadingButton";
import IntegrationActions from "../../store/integration/actionTypes";
import LoadingResultAlert from "../../components/Common/LoadingResultAlert";
import LoadingAware from "../../components/Common/LoadingAware";

import EConfigurationAction  from "../../store/configuration/actionTypes";

import Skeleton from "react-loading-skeleton";
import { RootState } from "../../store";
import { ReviewService } from "../../models/common/fromGenerated";
import {getCustomServerValidation} from "../../helpers/validation_helper";

const getServiceName = (services : ReviewService[], id : string) => {
  const service = (services || []).filter(s => s.id == id)[0];
  return service && service.title;
};

const Placeholder = () => (
  <React.Fragment>
    <Skeleton width={"50%"} count={1} height={30} className={"mb-5"}></Skeleton>
    <Skeleton count={2} height={30} className={"mb-5"}></Skeleton>
    <Skeleton count={1} width={"45%"} height={80} className={"mb-5"}></Skeleton>
    <Skeleton width={"20%"} height={30}></Skeleton>
  </React.Fragment>
);

const ZboziTab = ({
  getZboziSettings,
  setZboziSettings,
  clearZboziSettingsError,
  eshopId,
  settings,
  reviewServices,
  error,
} : Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (eshopId) getZboziSettings();
  }, [eshopId]);

  const handleSubmit = (event: Event, errors: any, values: any) => {
     clearZboziSettingsError();
     setZboziSettings(values);
  };


    return (
    <LoadingAware
      actiontypes={[IntegrationActions.GET_ZBOZI_SETTINGS, EConfigurationAction.GET_PORTAL_CONFIGURATION]}
      placeholder={<Placeholder></Placeholder>}
    >
      {settings && (
        <React.Fragment>
          <div className={classes.elements__on_sides}>
            <h5 style={{ marginBottom: "30px" }}>{t("Zbozi.title")}</h5>
            <img src="./feedbacks/logo-zbozi.png" />
          </div>
          <div>
            <AvForm model={settings} onSubmit={handleSubmit}>
              <Container>
                <div className="mb-3">
                  <AvField
                    name="shopId"
                    label={t("Zbozi.shopId")}
                    className="form-control"
                    placeholder={t("Zbozi.shopId")}
                    type="text"
                    validate={getCustomServerValidation("shopId", error)}
                  />
                </div>
                <div className="mb-3">
                <AvField
                    name="apiKey"
                    label={t("Zbozi.apiKey")}
                    className="form-control"
                    placeholder={t("Zbozi.apiKey")}
                    type="text"
                    validate={getCustomServerValidation("apiKey", error)}
                  />
                </div>
                <Row style={{ paddingTop: "1rem" }}>
                  <Col md="6" className="mb-3">
                    <h5>{t("Zbozi.newReviewSettings")}</h5>
                  </Col>
                  <Col md="6"></Col>
                </Row>
                {settings.transferThresholds!.map((threshold, i) => (
                  <div key={"key" + i} className="mb-3">
                    <Row>
                      <Col md="3">
                        {/* <AvGroup check> */}
                        <Label>
                          {`${t("Zbozi.transferTo")} ${getServiceName(
                            reviewServices,
                            threshold.serviceId!
                          )}`}
                        </Label>
                        {/* </AvGroup> */}
                      </Col>
                      <Col>
                        <AvInput
                          type="checkbox"
                          name={`transferThresholds[${i}].automaticTransfer`}
                          validate={getCustomServerValidation(`transferThresholds[${i}].automaticTransfer`, error)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Label check>{t("Zbozi.minReviewPercent")}</Label>
                      </Col>
                      <Col md="2">
                        <AvField
                          key={"key" + i}
                          type="number"
                          name={`transferThresholds[${i}].threshold`}
                          validate={getCustomServerValidation(`transferThresholds[${i}].threshold`, error)}
                        ></AvField>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <AvField
                          type="hidden"
                          name={`transferThresholds[${i}].serviceId`}
                          validate={getCustomServerValidation(`transferThresholds[${i}].serviceId`, error)}
                        ></AvField>
                      </Col>
                    </Row>
                  </div>
                ))}

                <LoadingButton
                  title={t("Zbozi.submit")}
                  className="btn btn-primary w-md mb-3"
                  type="submit"
                  actiontype={IntegrationActions.SET_ZBOZI_SETTINGS}
                ></LoadingButton>
                <LoadingResultAlert
                  actiontype={IntegrationActions.SET_ZBOZI_SETTINGS}
                ></LoadingResultAlert>
                <LoadingResultAlert
                  actiontype={IntegrationActions.GET_ZBOZI_SETTINGS}
                ></LoadingResultAlert>
              </Container>
            </AvForm>
          </div>
        </React.Fragment>
      )}
    </LoadingAware>
  );
};

const connector = connect(
  (state : RootState) => ({
    settings: state.Integration.zbozi.data,
    eshopId: state.Eshop.eshopsList.selectedEshop,
    reviewServices:
      (state.Configuration.data && state.Configuration.data.reviewServices) ||
      [],
    error: state.Integration.zbozi.error
  }),
  {
    getZboziSettings,
    setZboziSettings,
    clearZboziSettingsError
  }
);

type Props = ConnectedProps<typeof connector>

export default connector(ZboziTab);