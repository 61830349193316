import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import PropTypes from "prop-types";
import { Spinner, Button } from "reactstrap";
import { RootState } from "../../store";

const LOADING_AFTER_MS = 50;

const LoadingAware = ({
  loading,
  actiontypes,
  children,
  placeholder,
  ...otherProps
}: Props) => {
  const somethingIsLoading = actiontypes.reduce(
    (acc, cur) => loading.includes(cur) || acc,
    false
  );

  const timeoutRef = useRef<NodeJS.Timeout | undefined | null>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    if (somethingIsLoading) {
      timeoutRef.current = setTimeout(
        () => setIsLoading(true),
        LOADING_AFTER_MS
      );
    } else {
      setIsLoading(false);
    }

    return () => {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [somethingIsLoading]);

  return (
    <React.Fragment>
      {isLoading ? (
        placeholder ? (
          placeholder
        ) : (
          <Spinner size="sm" color="primary" />
        )
      ) : (
        children
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => {
  return { loading: state.Loading.current };
};

const mapDispatchToProps = {};

// LoadingAware.propTypes = {
//   title: PropTypes.string,
//   actiontypes: PropTypes.array,
//   children: PropTypes.any,
//   loading: PropTypes.array,
//   placeholder: PropTypes.any,
// };

const connector = connect(mapStateToProps, mapDispatchToProps);

interface IOwnProps {
  title?: string;
  actiontypes: string[];
  children: any;
  loading: string[];
  placeholder: JSX.Element;
}

type Props = ConnectedProps<typeof connector> & IOwnProps;

export default connector(LoadingAware);
