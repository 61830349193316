import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useHistory } from "react-router";
import RatingBar from "../../components/Common/RatingBar";
import UploadState from "./UploadState"
import { answeredImagePath } from "../../utils/imageService";
import { useTranslation } from "react-i18next";
import SatisfactionIcon from "../../components/Common/SatisfactionIcon";
import { fromNow } from "../../utils/timeUtils";
import {
  getColorForSatisfactionLevel,
  ReviewHeading,
  getShadowStyleForFeedback,
  getFontWeightForFeedback,
} from "../../utils/feedbacksUtils";
import ServiceImage from "../../components/Common/ServiceImage";
import StoreTypeImage from "../../components/Common/StoreTypeImage";
import { ReviewModel } from "../../models/common/fromGenerated";
import { getStoreTypeEnumFromString } from "../../models/common/storeTypeEnum";
import { getServiceEnumFromString } from "../../models/common/serviceEnum";

const FeedbackCard = ({ feedback } : Props) => {
  const history = useHistory();

  const onShowTabClick = (id : string) => () => {
    history.push("/feedbacks/" + id);
  };

  const { t, i18n } = useTranslation();
  return (
    <Card
      style={{
        boxShadow: getShadowStyleForFeedback(feedback),
      }}
    >
      <CardBody>
        <a href="#" onClick={onShowTabClick(feedback.id!)}>
          <Row style={{ marginBottom: "10px" }}>
            <Col lg="3">
              {/* <img
                src={feedback.profilePicture}
                style={{
                  borderRadius: "50%",
                  width: "100%",
                  maxWidth: "75px",
                }}
              /> */}
              <SatisfactionIcon level={feedback.satisfactionLevel!} size={50} />
              <StoreTypeImage
                type={getStoreTypeEnumFromString(feedback.reviewFor!)}
                style={{ position: "absolute", right: "20px", top: "35px" }}
              ></StoreTypeImage>
            </Col>
            <Col lg="9">
              <ReviewHeading isBold={feedback.unread!} className="text-truncate">
                {feedback.customerMessage}
              </ReviewHeading>
              <p>
                {fromNow(new Date(feedback.created!).getTime(), i18n.language)}
              </p>
            </Col>
          </Row>
          <Row style={{ alignItems: "center" }}>
            <Col lg="5" md="12">
              {/* <img src={feedback.rating} /> */}
              <RatingBar
                color={getColorForSatisfactionLevel(feedback.satisfactionLevel!)}
                rating={feedback.satisfactionValue! * 0.01}
                containerStyle={{
                  fontWeight: getFontWeightForFeedback(feedback),
                }}
              ></RatingBar>
            </Col>
            <Col lg="4" md="3"
                 style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    {Object.keys(feedback.reviewServicesSyncStatusMap!).map((key, i) => (
                        <UploadState
                            shop={key}
                            status={feedback.reviewServicesSyncStatusMap![key]}
                        />
                    ))}
              <div style={{minWidth: "14px"}}>
                {feedback.hasFeedback && <img src={answeredImagePath}/>}
              </div>
            </Col>
            <Col lg="3" md="6" style={{ textAlign: "end" }}>
              <ServiceImage
                serviceName={getServiceEnumFromString(feedback.reviewServiceName!)}
              ></ServiceImage>
            </Col>
          </Row>
        </a>
      </CardBody>
    </Card>
  );
};

FeedbackCard.propTypes = {
  feedback: PropTypes.object,
};

interface Props  {
  feedback: ReviewModel
}

export default FeedbackCard;
