import React, { useEffect } from "react";
import PropTypes, { any } from "prop-types";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

import { connect, ConnectedProps } from "react-redux";
//i18n
import { WithTranslation, withTranslation } from "react-i18next";
import { RootState } from "../../store";
import { RouteComponentProps, WithRouterProps } from "react-router";

const Navbar = (props : Props) => {

  return !props.isNavbarEnabled ? null : (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.menuOpen}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    <i className="bx bx-home-circle" />
                    {/* <span className="badge rounded-pill bg-info float-end">
                      04
                    </span> */}
                    <span>{props.t("Přehledy")}</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/feedbacks">
                    <i className="bx bx-chat" />
                    <span>{props.t("Recenze")}</span>
                  </Link>
                </li>

                {/* <li className="nav-item">
                    <Link className="nav-link" to="/chat">
                      <i className="bx bx-chat" />
                      <span>{props.t("Chat")}</span>
                    </Link>
                  </li> */}

                <li className="nav-item">
                  <Link className="nav-link" to="/integration">
                    <i className="bx bx-link-alt" />
                    <span>{props.t("Integrace")}</span>
                  </Link>
                </li>

                {/* <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setState({ isDashboard: !state.isDashboard })
                      }}
                      to="/dashboard"
                    >
                      <i className="bx bx-home-circle me" />
                      {props.t("Dashboard")} {props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: state.isDashboard,
                      })}
                    >
                      <Link to="/dashboard" className="dropdown-item">
                        {props.t("Default")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t("Saas")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t("Crypto")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t("Blog")}
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setState({ uiState: !state.uiState })
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-tone me" />
                      {props.t("UI Elements")}{" "}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname(
                        "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                        { show: state.uiState }
                      )}
                    >
                      <Row>
                        <Col lg={4}>
                          <div>
                            <Link to="#" className="dropdown-item">
                              {props.t("Alerts")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Buttons")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Cards")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Carousel")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Dropdowns")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Grid")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Images")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Lightbox")}
                            </Link>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div>
                            <Link to="#" className="dropdown-item">
                              {props.t("Modals")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Range Slider")}
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item"
                            >
                              {props.t("Session Timeout")}
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item"
                            >
                              {props.t("Progress Bars")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Sweet-Alert")}
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item"
                            >
                              {props.t("Tabs & Accordions")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Typography")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Video")}
                            </Link>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div>
                            <Link to="#" className="dropdown-item">
                              {props.t("General")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Colors")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Rating")}
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item"
                            >
                              {props.t("Notifications")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Breadcrumb")}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {props.t("Drawer")}
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setState({ appState: !state.appState })
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-customize me" />
                      {props.t("Apps")} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: state.appState,
                      })}
                    >
                      <Link to="#" className="dropdown-item">
                        {props.t("Calendar")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t("Chat")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t("File Manager")}
                      </Link>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({
                              emailState: !state.emailState,
                            })
                          }}
                        >
                          {props.t("Email")} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.emailState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("Inbox")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Read Email")}
                          </Link>
                          <div className="dropdown">
                            <Link
                              className="dropdown-item dropdown-toggle arrow-none"
                              to="/#"
                              onClick={e => {
                                e.preventDefault()
                                setState({
                                  emailState: !state.emailState,
                                })
                              }}
                            >
                              <span key="t-email-templates">Templates</span>{" "}
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: state.emailState,
                              })}
                            >
                              <Link
                                to="#"
                                className="dropdown-item"
                              >
                                {props.t("Basic Action")}
                              </Link>
                              <Link
                                to="#"
                                className="dropdown-item"
                              >
                                {props.t("Alert Email")}
                              </Link>
                              <Link
                                to="#"
                                className="dropdown-item"
                              >
                                {props.t("Billing Email")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({
                              ecommerceState: !state.ecommerceState,
                            })
                          }}
                        >
                          {props.t(" Ecommerce")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.ecommerceState,
                          })}
                        >
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Products")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Product Details")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Orders")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Customers")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Cart")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Checkout")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Shops")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Add Product")}
                          </Link>
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({ isCrypto: !state.isCrypto })
                          }}
                        >
                          {props.t("Crypto")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.isCrypto,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("Wallet")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Buy/Sell")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Exchange")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Lending")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Orders")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("KYC Application")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("ICO Landing")}
                          </Link>
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({
                              projectState: !state.projectState,
                            })
                          }}
                        >
                          {props.t("Projects")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.projectState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("Projects Grid")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Projects List")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Project Overview")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Create New")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({ taskState: !state.taskState })
                          }}
                        >
                          {props.t("Tasks")} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.taskState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("Task List")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Kanban Board")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Create Task")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({
                              contactState: !state.contactState,
                            })
                          }}
                        >
                          {props.t("Contacts")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.contactState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("User Grid")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("User List")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Profile")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({
                              blogState: !state.blogState,
                            })
                          }}
                        >
                          {props.t("Blog")} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.blogState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("Blog List")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Blog Grid")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Blog Details")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setState({
                          componentState: !state.componentState,
                        })
                      }}
                    >
                      <i className="bx bx-collection me" />
                      {props.t("Components")}{" "}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: state.componentState,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({ formState: !state.formState })
                          }}
                        >
                          {props.t("Forms")} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.formState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("Form Elements")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Form Layouts")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Form Validation")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Form Advanced")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Form Editors")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Form File Upload")}{" "}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Form Xeditable")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Form Repeater")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Form Wizard")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Form Mask")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Transfer List")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({
                              tableState: !state.tableState,
                            })
                          }}
                        >
                          {props.t("Tables")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.tableState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("Basic Tables")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Data Tables")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Responsive Table")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Editable Table")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Drag & Drop Table")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({
                              chartState: !state.chartState,
                            })
                          }}
                        >
                          {props.t("Charts")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.chartState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {" "}
                            {props.t("Apex charts")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {" "}
                            {props.t("Chartjs Chart")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {" "}
                            {props.t("E Chart")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {" "}
                            {props.t("Sparkline Chart")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Knob Chart")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Re Chart")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({ iconState: !state.iconState })
                          }}
                        >
                          {props.t("Icons")} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.iconState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("Boxicons")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Material Design")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Dripicons")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Font awesome")}{" "}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({ mapState: !state.mapState })
                          }}
                        >
                          {props.t("Maps")} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.mapState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("Google Maps")}{" "}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Vector Maps")}{" "}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Leaflet Maps")}{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setState({ extraState: !state.extraState })
                      }}
                    >
                      <i className="bx bx-file me" />
                      {props.t("Extra pages")}{" "}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: state.extraState,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({
                              invoiceState: !state.invoiceState,
                            })
                          }}
                        >
                          {props.t("Invoices")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.invoiceState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("Invoice List")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Invoice Detail")}
                          </Link>
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setState({ authState: !state.authState })
                          }}
                        >
                          {props.t("Authentication")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.authState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("Login")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Login 2")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Register")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Register 2")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Recover Password")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Recover Password 2")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Lock Screen")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Lock Screen 2")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Confirm Mail")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Confirm Mail 2")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Email verification")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Email verification 2")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Two step verification")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Two step verification 2")}
                          </Link>
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          className="dropdown-item dropdown-toggle arrow-none"
                          to="/#"
                          onClick={e => {
                            e.preventDefault()
                            setState({
                              utilityState: !state.utilityState,
                            })
                          }}
                        >
                          {props.t("Utility")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: state.utilityState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t("Starter Page")}
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Maintenance")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Coming Soon")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Timeline")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("FAQs")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Pricing")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Error 404")}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t("Error 500")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li> */}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

// Navbar.propTypes = {
//   location: PropTypes.object,
//   menuOpen: PropTypes.any,
//   t: PropTypes.any,
//   isNavbarEnabled: PropTypes.bool,
// };

interface IOwnProps {
  location: any,
  menuOpen: boolean,
  isNavbarEnabled: boolean
}

const mapStateToProps = (state : RootState) => ({
  isNavbarEnabled: state.Eshop.eshopsList.selectedEshop != null,
});

const connector = connect(
  mapStateToProps,
  {}
)

type Props = ConnectedProps<typeof connector> & IOwnProps & WithTranslation & RouteComponentProps;

export default connector((withRouter(withTranslation()(Navbar))));
