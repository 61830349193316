import EAction from "./actionTypes";
import { combineReducers } from "redux";
import {
  GetPortalConfigurationFailAction,
  GetPortalConfigurationAction,
  GetPortalConfigurationSuccessAction,
} from "./actions";

import { ConfigurationModel } from "../../models/common/fromGenerated";

const initialstate = {
  data: null as ConfigurationModel | null,
};

type Action =
  | GetPortalConfigurationFailAction
  | GetPortalConfigurationSuccessAction
  | GetPortalConfigurationAction;

export default (state = initialstate, action: Action) => {
  switch (action.type) {
    case EAction.GET_PORTAL_CONFIGURATION_SUCCESS:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
