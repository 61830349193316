import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { SkeletonTheme } from "react-loading-skeleton";
import { Provider } from "react-redux";

import store from "./store";
import "react-loading-skeleton/dist/skeleton.css";
const app = (
  <Provider store={store}>
    <SkeletonTheme baseColor="#D7D9E4" highlightColor="#263270">
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SkeletonTheme>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
