import { NotValidItemModel } from "../models/common/fromGenerated";
import { IValidationError } from "../models/common/saga";

interface IValidationFieldError {
  value: any,
  errorMessage : string
}

const getValidationObjectForError = (err : IValidationError | null, fieldname: string) : IValidationFieldError =>
  ((err && err.items) || []).reduce(
    (acc : IValidationFieldError, cur : NotValidItemModel) =>
      cur.key == fieldname
        ? { value: err!.requestData[fieldname], errorMessage: cur.message } as IValidationFieldError
        : acc,
    {} as IValidationFieldError
  );

const getServerValidationObj = (fieldname : string, serverError : IValidationError | null, value : any) => {
  const validationObj = getValidationObjectForError(serverError, fieldname);
  if (validationObj == null || Object.keys(validationObj).length == 0) return true;
  return value == validationObj.value ? validationObj.errorMessage : true;
};

export const customServerValidation =
  (serverError : IValidationError | null, fieldname: string) => (value: any/*, ctx, input, cb*/) =>
    getServerValidationObj(fieldname, serverError, value);

export const getCustomServerValidation = (fieldname : string, serverError : IValidationError | null) => {
  return { serverError: customServerValidation(serverError, fieldname) };
};
