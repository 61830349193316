const minute = 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const month = day * 30;
const year = day * 365;

export const fromNow = (date : number, locale : string) : string => {

  const seconds = Math.round((new Date().getTime() - date) / 1000);
  
  const rtf = new Intl.RelativeTimeFormat(locale || "en", {
    localeMatcher: "best fit", // other values: "lookup"
    numeric: "always", // other values: "auto"
    style: "long", // other values: "short" or "narrow"
  });

  let value;
  if (seconds < minute) {
    value = seconds;
    return rtf.format(-value, "second");
  }

  if (seconds < hour) {
    value = Math.floor(seconds / minute);
    return rtf.format(-value, "minute");
  }

  if (seconds < day) {
    value = Math.floor(seconds / hour);
    return rtf.format(-value, "hour");
  }

  if (seconds < week) {
    value = Math.floor(seconds / day);
    return rtf.format(-value, "day");
  }

  if (seconds < month) {
    value = Math.floor(seconds / week);
    return rtf.format(-value, "week");
  }

  if (seconds < year) {
    value = Math.floor(seconds / month);
    return rtf.format(-value, "month");
  }

  value = Math.floor(seconds / year);
  return rtf.format(-value, "year");
};
