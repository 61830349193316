import React from "react";
import PropTypes from "prop-types";

import heureka_done from "../../assets/images/state/heureka_done.png"
import heureka_dont_sync from "../../assets/images/state/heureka_dont_sync.png"
import heureka_will_be from "../../assets/images/state/heureka_will_be.png"

import eshop_done from "../../assets/images/state/eshop_done.png"
import eshop_dont_sync from "../../assets/images/state/eshop_dont_sync.png"
import eshop_will_be from "../../assets/images/state/eshop_will_be.png"

import zbozi_done from "../../assets/images/state/zbozicz_done.png"
import zbozi_dont_sync from "../../assets/images/state/zbozicz_dont_sync.png"
import zbozi_will_be from "../../assets/images/state/zbozicz_will_be.png"

const UploadState = (props: { shop: any; status: any; }) =>
{
    let shop = props.shop;
    let status = props.status;
    let image;
    let shopName;
    let shopState;

    switch(shop) {
        case 'HEUREKA':
            shopName = 'Heureka';
            switch(status) {
                case 'WILL_BE':
                    image = heureka_will_be
                    break;
                case 'DONT_SYNC':
                    image = heureka_dont_sync
                    break;
                case 'DONE':
                    image = heureka_done
                    break;
            }
            break;
        case 'ESHOP':
            shopName = 'E-shop';
            switch(status) {
                case 'WILL_BE':
                    image = eshop_will_be
                    break;
                case 'DONT_SYNC':
                    image = eshop_dont_sync
                    break;
                case 'DONE':
                    image = eshop_done
                    break;
            }
            break;
        case 'ZBOZICZ':
            shopName = 'Zboží.cz';
            switch(status) {
                case 'WILL_BE':
                    image = zbozi_will_be
                    break;
                case 'DONT_SYNC':
                    image = zbozi_dont_sync
                    break;
                case 'DONE':
                    image = zbozi_done
                    break;
            }
            break;
    }


    switch(status) {
        case 'WILL_BE':
            shopState = 'bude synchronizován'
            break;
        case 'DONT_SYNC':
            shopState = 'nebude synchronizován'
            break;
        case 'DONE':
            shopState = 'byl synchronizován'
            break;
    }

    return(
        <>
            <img src={image}
                alt={"Logo " + shopName}
                title={shopName + " - " + shopState}
            />
        </>
    )
}

UploadState.propTypes = {
    shop: PropTypes.string,
    status: PropTypes.string
};

export default UploadState;