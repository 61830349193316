import {
  takeEvery,
  put,
  call,
  select,
  fork,
  takeLatest,
} from "redux-saga/effects";

import { delay } from "@redux-saga/core/effects";

// import { parseError } from "../../utils/restUtils";

import { parseError } from "../../utils/errorUtils";

import Actions from "./actionTypes";
import {
  getFeedbacksOverviewFail,
  getFeedbacksOverviewSuccess,
  getFeedbackByIdFail,
  getFeedbackByIdSuccess,
  getFeedbacksLatesSuccess,
  getFeedbacksLatestFail,
  sendFeedbackResponseFail,
  sendFeedbackResponseSuccess,
  getFeedbacksLatest,
  getFeedbacksOverview,
  changeFeedbacksOverviewPaging,
  changeFeedbacksOverviewFilter,
  setReadFeedback,
  setReadFeedbackFail,
  setReadFeedbackSuccess,
  GetFeedbackByIdAction,
  SendFeedbackResponseAction,
  SetReadFeedbackAction,
  GetFeedbacksOverviewAction,
  GetFeedbacksLatestAction,
} from "./actions";

import {
  getReviewById,
  postGetReviewsOverview,
  putReviewAnswer,
  postSetReadFeedback,
} from "../../helpers/api";
import {
  MessageModel,
  PageModel,
  PageResultModelReviewModel,
  ReviewFilterModel,
  ReviewModel,
  ReviewOverviewRequestModel,
} from "../../models/common/fromGenerated";
import { RootState } from "..";
import { IParsedRequestResult, processRequest } from "../../utils/requestUtils";

function* onFeedbacksOverview(action: GetFeedbacksOverviewAction) {
  const paging: PageModel = yield select(
    (state: RootState) => state.Feedbacks.overview.page
  );
  let filter: ReviewFilterModel = yield select(
    (state: RootState) => state.Feedbacks.overview.filter
  );

  filter.eshopId = yield select( (state: RootState) => state.Eshop.eshopsList.selectedEshop);

  yield processRequest(action.type, postGetReviewsOverview, {
    filter: filter,
    page: paging,
    sort: { asc: true, column: "" },
  } as ReviewOverviewRequestModel);
}

function* onFeedbacksLatest(action: GetFeedbacksLatestAction) {
  const paging: PageModel = yield select(
    (state: RootState) => state.Feedbacks.latest.page
  );

  const eshopId: string = yield select( (state: RootState) => state.Eshop.eshopsList.selectedEshop);

  yield processRequest(action.type, postGetReviewsOverview, {
    page: paging,
    sort: { asc: true, column: "string" },
    filter: { forProducts: true, forShops: true, eshopId:  eshopId},
  } as ReviewOverviewRequestModel);
}

function* onChangeFeedbacksOverviewPaging() {
  yield put(getFeedbacksOverview());
}

function* onChangeFeedbacksOverviewFilter() {
  yield put(getFeedbacksOverview());
}

function* onChangeFeedbacksLatestPaging() {
  yield put(getFeedbacksLatest());
}

function* onFeedbackById(action: GetFeedbackByIdAction) {
  const {hasError} : IParsedRequestResult = yield processRequest(
    action.type,
    getReviewById,
    action.payload
  );
  if (!hasError) yield put(setReadFeedback(action.payload));
}

function* onSendFeedbackResponse(action: SendFeedbackResponseAction) {
  yield processRequest(action.type, putReviewAnswer, action.payload);
}

function* onSyncOverviewWithLatest() {
  const paging: PageModel = yield select(
    (state: RootState) => state.Feedbacks.latest.page
  );
  yield put(changeFeedbacksOverviewPaging(paging));
  yield put(
    changeFeedbacksOverviewFilter({ forProducts: true, forShops: true })
  );
}

function* onSetReadFeedback(action: SetReadFeedbackAction) {
 yield processRequest(action.type, postSetReadFeedback, action.payload)
}

function* feedbacksSaga() {
  yield takeLatest(Actions.GET_FEEDBACKS_OVERVIEW, onFeedbacksOverview);
  yield takeLatest(Actions.GET_FEEDBACKS_LATEST, onFeedbacksLatest);
  yield takeEvery(
    Actions.CHANGE_FEEDBACKS_OVERVIEW_PAGING,
    onChangeFeedbacksOverviewPaging
  );
  yield takeEvery(
    Actions.CHANGE_FEEDBACKS_LATEST_PAGING,
    onChangeFeedbacksLatestPaging
  );
  yield takeEvery(
    Actions.CHANGE_FEEDBACKS_OVERVIEW_FILTER,
    onChangeFeedbacksOverviewFilter
  );
  yield takeEvery(
    Actions.SYNC_FILTERS_PAGING_FROM_LATEST_TO_OVERVIEW,
    onSyncOverviewWithLatest
  );
  yield takeEvery(Actions.SEND_FEEDBACK_RESPONSE, onSendFeedbackResponse);
  yield takeLatest(Actions.GET_FEEDBACK_BY_ID, onFeedbackById);
  yield takeEvery(Actions.SET_READ_FEEDBACK_BY_ID, onSetReadFeedback);
}

export default feedbacksSaga;
