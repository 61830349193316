import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Container } from "reactstrap";
import FeedbacksTab from "./FeedbacksTab";
import { connect } from "react-redux";
import SettingsRedirect from "../../components/Common/SettingsRedirect";
import { RootState } from "../../store";

const FeedbacksPage = () => {
  return (
    <React.Fragment>
      <SettingsRedirect />
      <div className="page-content">
        <MetaTags>
          <title>Recenze | TrustBite</title>
        </MetaTags>
        <Container>
          <Row>
            <Col lg="12">
              <FeedbacksTab />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

export default connect(mapStateToProps, {})(FeedbacksPage);
