import React, { Component, useEffect, useState, useRef } from "react";

import { recaptcha } from "../../config";

import ReCAPTCHA from "react-google-recaptcha";

export default class Recaptcha extends Component<IRecaptchaProps> {
  
  recaptchaRef : any

  constructor(props : IRecaptchaProps) {
    super(props);
    this.recaptchaRef = React.createRef();
  }

  handleRecaptchaChange = (token  : string) => {
    this.props.onChange(token);
    if (token == null) this.reset();
  };

  reset = () => {
    this.recaptchaRef.current.reset();
  };

  render() {
    return (
      <ReCAPTCHA
        ref={this.recaptchaRef}
        sitekey={recaptcha.API_KEY}
        onChange={this.handleRecaptchaChange}
      />
    );
  }
}

interface IRecaptchaProps {
  onChange: (token: string) => void
}
