import ServiceEnum from "../models/common/serviceEnum";
import StoreTypeEnum from "../models/common/storeTypeEnum";


const serviceImages = {
  [ServiceEnum.HEUREKA]: "/feedbacks/logo-heureka.png",
  [ServiceEnum.ZBOZICZ]: "/feedbacks/logo-zbozi.png",
  [ServiceEnum.ESHOP]: "",
};

const storeTypeImages = {
  [StoreTypeEnum.SHOP]: "/feedbacks/rest-icon.png",
  [StoreTypeEnum.PRODUCT]: "/feedbacks/cloth-icon.png",
};

const serviceImagesThumbs = {
  [ServiceEnum.HEUREKA]: "/integration/icons/heureka-thumb.png",
  [ServiceEnum.ZBOZICZ]: "/integration/icons/zbozi-thumb.png",
  [ServiceEnum.ESHOP]: "/integration/icons/eshop-thumb.png",
};

export const answeredImagePath = "/feedbacks/comment.png";

export const getImagePathForServiceType = (type: ServiceEnum) => {
  return serviceImages[type];
};

export const getThumbImagePathForServiceType = (type: ServiceEnum) => {
  return serviceImagesThumbs[type];
};

export const getImagePathForStoreType = (type: StoreTypeEnum) => {
  return storeTypeImages[type];
};
