import log from "loglevel";
import { API_URL } from "../helpers/url_helper";
import * as authStorage from "../helpers/authStorage";
import store from "../store";
import { LogModel } from "../models/common/fromGenerated";
import authHeader from "../helpers/AuthType/auth-token-header";
const remote = require("loglevel-plugin-remote");

function json(log: any): LogModel {
  const auth = authStorage.getAuth();
  // console.log(log);
  let resultLog = {
    level: log.level.label,
    message: log.message,
    time: log.timeStamp,
    attributes: {
      stacktrace: log.stacktrace,
      store: JSON.stringify(store.getState()),
      url: window.location.href,
      authKey: auth ? auth.authKey : undefined,
    },
  } as LogModel;

  return resultLog;
}

export const setupRemoteLogging = () => {
  if (authStorage.getAuth() != null) {
    remote.apply(log, {
      format: /*remote.json*/ json,
      url: `${API_URL}/v1/diagnostics/push-logs`,
      stacktrace: {
        levels: ["error"],
        depth: 6,
        excess: 0,
      },
      headers: authHeader(),
    });
  }
};

setupRemoteLogging();

const logger = log;

export default logger;
