import React, { useEffect, useState } from "react";

import { Row, Col, Button, Container } from "reactstrap";

import classes from "../IntegrationPage/integration.module.css";

import PropTypes from "prop-types";
import { connect, ConnectedProps } from "react-redux";
import { getFeedbackById, sendFeedbackResponse } from "../../store/feedbacks/actions";
import { useLocation, useParams } from "react-router";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
import LoadingButton from "../../components/Common/LoadingButton";
import { useTranslation } from "react-i18next";
import LoadingResultAlert from "../../components/Common/LoadingResultAlert";
import RatingBar from "../../components/Common/RatingBar";
import { getColorForSatisfactionLevel } from "../../utils/feedbacksUtils";
import LoadingAware from "../../components/Common/LoadingAware";
import FeedbackActions from "../../store/feedbacks/actionTypes";
import Skeleton from "react-loading-skeleton";

import styled from "styled-components";
import SatisfactionIcon from "../../components/Common/SatisfactionIcon";
import { fromNow } from "../../utils/timeUtils";
import { getSatisfactionTextFromLevel } from "../../utils/feedbacksUtils";
import ServiceImage from "../../components/Common/ServiceImage";
import StoreTypeImage from "../../components/Common/StoreTypeImage";
import ServicesToggleGroup from "./ServicesToggleGroup";
import { RootState } from "../../store";
import { getStoreTypeEnumFromString } from "../../models/common/storeTypeEnum";
import { getServiceEnumFromString } from "../../models/common/serviceEnum";

const ProfileImg = styled.img`
  border-radius: 50%;
  width: 100%;
  max-width: 75px;
`;

const CommentWrapper = styled.div`
  background-color: #f5f6f8;
  padding: 1.5rem;
  border-radius: 0.25rem;
  color: #000000;
`;

const FeedbackWrapper = styled.div`
  background-color: #f5f7f4;
  padding: 1.5rem;
  border-radius: 0.25rem;
  color: #000000;
  margin-top: 15px;
  margin-left: 10%;
`;

const Placeholder = () => (
  <React.Fragment>
    <Row className={"mb-3"}>
      <Col>
        <Skeleton height={90}></Skeleton>
      </Col>
      <Col></Col>
    </Row>
    <Skeleton height={70} count={1} className={"mb-4"}></Skeleton>
    <div className="d-flex flex-column align-items-end">
      <div className={classes.width_90}>
        <Skeleton height={100} count={1}></Skeleton>
        <Row className={"mt-3 mb-5"}>
          <Col></Col>
          <Col></Col>
          <Col>
            <Skeleton height={40} count={1}></Skeleton>
          </Col>
        </Row>
      </div>
    </div>
  </React.Fragment>
);

const FeedbackPanel = ({ getFeedbackById, feedback, sendFeedbackResponse } : Props) => {
  const { feedbackId } = useParams<{feedbackId: string}>();
  useEffect(() => {
    if (feedbackId != null) {
      getFeedbackById(feedbackId);
    }
  }, [feedbackId]);

  const { t, i18n } = useTranslation();

  const [selectedServices, setSelectedServices] = useState<string[]>([]);

  const handleResponseSubmit = (_: any, values : any) => {
    sendFeedbackResponse({
      id: undefined,
      message: values.response,
      reviewId: feedbackId,
      syncToReviewServices: selectedServices,
    });
  };

  const handleServicesChanged = (servicesIds : string[]) => {
    setSelectedServices(servicesIds);
  };

  return (
    <LoadingAware
      actiontypes={[FeedbackActions.GET_FEEDBACK_BY_ID]}
      placeholder={<Placeholder />}
    >
      {feedback && (
        <React.Fragment>
          <Row>
            <Col lg="2">
              {/* <ProfileImg src={feedback.profilePicture} /> */}
              <SatisfactionIcon level={feedback.satisfactionLevel!} size={85} />
              <StoreTypeImage
                type={getStoreTypeEnumFromString(feedback.reviewFor!)}
                style={{ position: "absolute", right: 0, top: 45 }}
              />
            </Col>
            <Col lg="4">
              <h5>
                {getSatisfactionTextFromLevel(t, feedback.satisfactionLevel!)}
              </h5>
              <p className="mb-1">
                {fromNow(new Date(feedback.created!).getTime(), i18n.language)}
              </p>
              <a href="#">{feedback.productName}</a>
              {/* <img src={feedback.rating} style={{ marginTop: "0.5rem" }} /> */}
              <RatingBar
                color={getColorForSatisfactionLevel(feedback.satisfactionLevel!)}
                rating={feedback.satisfactionValue! * 0.01}
              ></RatingBar>
            </Col>
            <Col lg="3">{/* <p>Zde budou taggy</p> */}</Col>
            <Col lg="3">
              <ServiceImage
                serviceName={getServiceEnumFromString(feedback.reviewServiceName!)}
              ></ServiceImage>
            </Col>
          </Row>
          <Row className={"mb-4"}>
            <Col md="12">
              <hr />
              <CommentWrapper>
                <p>{feedback.customerMessage}</p>
              </CommentWrapper>
            </Col>
            <Col md={12}>
              {feedback.hasFeedback && (
                <FeedbackWrapper>
                  <p>{feedback.feedbackMessage}</p>
                </FeedbackWrapper>
              )}
            </Col>
          </Row>
          {!feedback.hasFeedback && (
            <React.Fragment>
              {" "}
              <Row className={"mb-2"}>
                <Col md="12">
                  <AvForm onValidSubmit={handleResponseSubmit}>
                    <div className="d-flex flex-column  align-items-end">
                      <div className={classes.width_90}>
                        <AvField
                          name="response"
                          className={`form-control`}
                          placeholder={t("Feedbacks.response.placeholder")}
                          type="textarea"
                          required
                          style={{
                            marginBottom: "2rem",
                            padding: "1rem",
                            minHeight: "100px",
                            borderRadius: "0.25rem",
                          }}
                          errorMessage={t("Common.required")}
                        />
                      </div>
                      <div>
                        <LoadingButton
                          actiontype={FeedbackActions.SEND_FEEDBACK_RESPONSE}
                          className="btn btn-primary text-right"
                          type="submit"
                          title={t("Feedbacks.response.submit")}
                        ></LoadingButton>
                      </div>
                    </div>
                    <div className="mt-2">
                      <LoadingResultAlert
                        actiontype={FeedbackActions.SEND_FEEDBACK_RESPONSE}
                      ></LoadingResultAlert>
                    </div>
                  </AvForm>
                </Col>
              </Row>
              <Row>
                <Col md="12" style={{ textAlign: "right" }}>

                </Col>
              </Row>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </LoadingAware>
  );
};

const connector =  connect(
  (state : RootState, ownprops) => ({
    feedback: state.Feedbacks.feedback.data,
    /*state.Feedbacks.data.filter(f => f.id === ownprops.id)[0]*/
  }),
  { getFeedbackById, sendFeedbackResponse }
)

type ReduxProps = ConnectedProps<typeof connector> 
type Props = ReduxProps;

export default connector(FeedbackPanel);
