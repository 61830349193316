enum EAction {
  CONNECT_ESHOP = "CONNECT_ESHOP",
  CONNECT_ESHOP_SUCCESS = "CONNECT_ESHOP_SUCCESS",
  CONNECT_ESHOP_FAIL = "CONNECT_ESHOP_FAIL",

  CONNECT_ESHOP_VISIBLE = "CONNECT_ESHOP_VISIBLE",

  GET_ALL_ESHOPS = "GET_ALL_ESHOPS",
  GET_ALL_ESHOPS_SUCCESS = "GET_ALL_ESHOPS_SUCCESS",
  GET_ALL_ESHOPS_FAIL = "GET_ALL_ESHOPS_FAIL",

  SET_SELECTED_ESHOP = "SET_SELECTED_ESHOP",

  GET_ESHOPS_OVERVIEW = "GET_ESHOPS_OVERVIEW",
  GET_ESHOPS_OVERVIEW_SUCCESS = "GET_ESHOPS_OVERVIEW_SUCCESS",
  GET_ESHOPS_OVERVIEW_FAIL = "GET_ESHOPS_OVERVIEW_FAIL",

  GET_ESHOP_BY_ID = "GET_ESHOP_BY_ID",
  GET_ESHOP_BY_ID_SUCCESS = "GET_ESHOP_BY_ID_SUCCESS",
  GET_ESHOP_BY_ID_FAIL = "GET_ESHOP_BY_ID_FAIL",

  DELETE_ESHOP_BY_ID = "DELETE_ESHOP_BY_ID",
  DELETE_ESHOP_BY_ID_SUCCESS = "DELETE_ESHOP_BY_ID_SUCCESS",
  DELETE_ESHOP_BY_ID_FAIL = "DELETE_ESHOP_BY_ID_FAIL",

  EDIT_ESHOP_BY_ID = "EDIT_ESHOP_BY_ID",
  EDIT_ESHOP_BY_ID_SUCCESS = "EDIT_ESHOP_BY_ID_SUCCESS",
  EDIT_ESHOP_BY_ID_FAIL = "EDIT_ESHOP_BY_ID_FAIL",

  CHANGE_ESHOPS_OVERVIEW_PAGING = "CHANGE_ESHOPS_OVERVIEW_PAGING",
  CONNECT_ESHOP_VALIDATION_FAIL = "CONNECT_ESHOP_VALIDATION_FAIL",
  EDIT_ESHOP_BY_ID_VALIDATION_FAIL = "EDIT_ESHOP_BY_ID_VALIDATION_FAIL",
  CONNECT_ESHOP_CLEAR_ERROR = "CONNECT_ESHOP_CLEAR_ERROR"
}

export default EAction;
