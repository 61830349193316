import { connect, ConnectedProps } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getAllEshops, setSelectedEshop } from "../../store/eshop/actions";
import { Badge, Button } from "reactstrap";
import Select, {
  components,
  GroupBase,
  Props,
  SingleValue,
} from "react-select";
import { useHistory } from "react-router";
import { RootState } from "../../store";
// const CustomOption = props => {
//   const { innerProps, isDisabled, children, ...rest } = props;
//   return !isDisabled ? <div {...innerProps}>{children}</div> : null;
// };

// const CustomOption = props => {
//   const { innerProps, innerRef } = props;
//   const { children, ...rest } = props;

//   return (
//     <components.Option {...rest}>
//       {/* <div ref={innerRef} {...innerProps}>
//         {props.data.label} <span>{props.data.text}</span>
//       </div> */}
//       {props.children} - <span style={{ color: "red" }}>{props.data.text}</span>
//     </components.Option>
//   );
// };

// CustomOption.propTypes = {
//   innerProps: PropTypes.object,
//   isDisabled: PropTypes.bool,
//   children: PropTypes.object,
//   innerRef: PropTypes.any,
//   data: PropTypes.object,
// };
const formatOptionLabel = ({ value, label, url } : IOption) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    }}
  >
    <div className="text-truncate" style={{ maxWidth: "100%" }}>
      {label}
    </div>
    <div
      className="text-truncate"
      style={{
        fontSize: 11,
        fontWeight: 250,
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "100%",
      }}
    >
      {url}
    </div>
  </div>
);


const EShopDropDown = ({
  getAllEshops,
  eshops,
  setSelectedEshop,
  selectedEshop,
}: SelectProps) => {
  useEffect(() => {
    getAllEshops();
  }, []);

  const handleChange = (val: SingleValue<IOption>) => {
    if (val != null) setSelectedEshop(val.value);
  };

  const { t } = useTranslation();

  const history = useHistory();
  const options = useMemo(
    () =>
      eshops.map(
        e =>
          ({
            value: e.id,
            label: `${e.name}`,
            url: e.url,
            text: "text",
          } as IOption)
      ),
    [eshops]
  );

  return (
    <div style={{ width: 250 }}>
      {options.length > 0 ? (
        <CustomSelect
          placeholder={t("EshopSelect.placeholder")}
          noOptionsMessage={() => t("EshopSelect.noEshops")}
          value={options.filter(o => o.value === selectedEshop)}
          onChange={handleChange}
          options={options}
          formatOptionLabel={formatOptionLabel}
          // components={{ Option: CustomOption }}
        />
      ) : (
        <Button onClick={() => history.push("/settings/eshop")}>
          {t("EshopSelect.connect")}
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  eshops: state.Eshop.eshopsList.data,
  selectedEshop: state.Eshop.eshopsList.selectedEshop,
});

const connector = connect(mapStateToProps, { getAllEshops, setSelectedEshop });

type SelectProps = ConnectedProps<typeof connector>;

export default connector(EShopDropDown);

interface IOption {
  value: string; //id
  label: string;
  url: string;
  text: string;
}

function CustomSelect<
  IOPtion,
  IsMulti extends boolean = false,
  Group extends GroupBase<IOPtion> = GroupBase<IOPtion>
>(props: Props<IOPtion, IsMulti, Group>) {
  return <Select {...props} />;
}
