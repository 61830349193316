import { IReduxAction } from "../../models/common/saga";
import {
  AddFailedAction,
  AddLoadingAction,
  AddSuccesfullAction,
  ClearResultsAction,
  RemoveLoadingAction,
} from "./actions";

import Actions from "./actionTypes";

const initialState: ILoadingState = {
  current: [], 
  failed: [], 
  successfull: [],
};

interface ILoadingState {
  current: string[];
  failed: IReduxAction<string, any>[];
  successfull: IReduxAction<string, any>[];
}

type Action =
  | AddFailedAction
  | AddLoadingAction
  | AddSuccesfullAction
  | ClearResultsAction
  | RemoveLoadingAction;

export default (state = initialState, action: Action): ILoadingState => {
  switch (action.type) {
    case Actions.ADD_LOADING:
      return { ...state, current: [...state.current, action.payload] };
    case Actions.REMOVE_LOADING:
      return {
        ...state,
        current: state.current.filter(cur => cur !== action.payload),
      };
    case Actions.ADD_FAILED:
      return { ...state, failed: [...state.failed, action.payload] };
    case Actions.ADD_SUCCESFULL:
      return { ...state, successfull: [...state.successfull, action.payload] };
    case Actions.CLEAR_RESULTS:
      return {
        ...state,
        failed: state.failed.filter(failed => failed.type !== action.payload),
        successfull: state.successfull.filter(
          successfull => successfull.type !== action.payload
        ),
      };
    default:
      return state;
  }
};
