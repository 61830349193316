import { IReduxAction, reduxAction } from "../../models/common/saga";
import Actions from "./actionTypes";

const getActionName = (actionType: string) =>
  actionType.replace("_FAIL", "").replace("_SUCCESS", "").replace("_REQUEST", "");

export const addLoading = (action: IReduxAction<string, any>) =>
  reduxAction(Actions.ADD_LOADING, getActionName(action.type));

export const removeLoading = (action: IReduxAction<string, any>) =>
  reduxAction(Actions.REMOVE_LOADING, getActionName(action.type));

export const addFailed = (payload: IReduxAction<string, any>) =>
  reduxAction(Actions.ADD_FAILED, {
    ...payload,
    type: getActionName(payload.type),
  });

export const addSuccesfull = (payload: IReduxAction<string, any>) =>
  reduxAction(Actions.ADD_SUCCESFULL, {
    ...payload,
    type: getActionName(payload.type),
  });

export const clearResults = (payload: string) =>
  reduxAction(Actions.CLEAR_RESULTS, payload);

export type AddLoadingAction = ReturnType<typeof addLoading>;
export type RemoveLoadingAction = ReturnType<typeof removeLoading>;
export type AddFailedAction = ReturnType<typeof addFailed>;
export type AddSuccesfullAction = ReturnType<typeof addSuccesfull>;
export type ClearResultsAction = ReturnType<typeof clearResults>;
