import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect } from "react-router";
import { RootState } from "../../store";

const SettingsRedirect = ({ wantRedirect } : Props) => {
  return wantRedirect ? <Redirect to="/settings/eshop"></Redirect> : null;
};

interface ISettingsRedirectOwnProps {
  wantRedirect: boolean
}

const mapStateToProps = (state : RootState) => {
  const wantRedirect =
    state.Eshop.eshopsList.selectedEshop == null &&
    state.Eshop.eshopsList.loaded;
  return { wantRedirect };
};

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> & ISettingsRedirectOwnProps; 

export default connector(SettingsRedirect);
