export const WEB_APP_BASEURL =
  window.location.protocol + "//" + window.location.host;

let apiUrl = window.location.protocol + "//" + window.location.host + "/api";

if (window.location.host === "localhost:3000") {
  //apiUrl = "http://app03.ee.adalo.cz:15897/api";
  apiUrl = "http://localhost:15897/api";
}

export const API_URL = apiUrl;