import React from "react";
import { connect, ConnectedProps } from "react-redux";
import PropTypes from "prop-types";
import { Spinner, Button, ButtonProps } from "reactstrap";
import { RootState } from "../../store";

const LoadingButton = ({ loading, isLoading, title, ...otherProps } : Props) => {
  return (
    <Button {...otherProps} disabled={isLoading || otherProps.disabled}>
      {isLoading ? (
        <React.Fragment>
          {" "}
          <Spinner size="sm" color="primary" /> {title}{" "}
        </React.Fragment>
      ) : (
        title
      )}
    </Button>
  );
};

const mapStateToProps = (state : RootState, ownProps : IOwnProps) => {
  const isLoading = state.Loading.current.includes(ownProps.actiontype);
  return { isLoading, loading: state.Loading };
};

const mapDispatchToProps = {};

// LoadingButton.propTypes = {
//   isLoading: PropTypes.bool,
//   title: PropTypes.string,
//   actiontype: PropTypes.string,
//   loading: PropTypes.any,
// };

const connector =  connect(mapStateToProps, mapDispatchToProps);

interface IOwnProps extends ButtonProps {
  title: string,
  actiontype: string,
}

type Props = ConnectedProps<typeof connector> & IOwnProps

export default connector(LoadingButton);
