import React from "react";
import { useTranslation } from "react-i18next";

import GitInfo from "react-git-info/macro";

const version = GitInfo();

export default function CopyrightAdalo() {
  const { t } = useTranslation();

  return (
    <p>
      <a href="http://www.adalo.cz"> Adalo Solutions s.r.o.</a> ©{" "} 
      {new Date().getFullYear()} | {t("Copyright.allrightsreserved")} | {t("Common.version")} {version.major}.{version.minor}.{version.buildNumber} 
    </p>
  );
}
