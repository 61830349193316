import {
  takeEvery,
  put,
  call,
  takeLatest,
  CallEffect,
} from "redux-saga/effects";

// Login Redux States
import EAction from "./actionTypes";
import { LoginUserAction, LogoutUserAction } from "./actions";
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  loginFail,
} from "./actions";
import * as authStorage from "../../../helpers/authStorage";

import { authByEmail, logout } from "../../../helpers/api";
import { setupRemoteLogging } from "../../../utils/logger";

import {
  AuthInfoModel,
} from "../../../generated-sources/openapi/api";
import { parseError } from "../../../utils/errorUtils";

function* loginUser({
  payload: { user, history, rememberLogin },
}: LoginUserAction) {
  try {
    const response: AuthInfoModel = yield call(authByEmail, {
      login: user.login,
      password: user.password,
    });

    authStorage.setAuth(response, {
      wantpersist: rememberLogin,
    });

    try {
      // doresit, lze volat jen jednou
      setupRemoteLogging();
    } catch {}

    yield put(loginSuccess(response));
    history.push("/dashboard");
  } catch (error) {
    console.log(error);
    const parsedError = parseError(error);
    yield put(loginFail(parsedError));
  }
}

function* logoutUser({ payload: { history } }: LogoutUserAction) {
  try {
    yield call(logout);
    localStorage.removeItem("authUser");
    yield put(logoutUserSuccess());
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(EAction.LOGIN_USER, loginUser);
  yield takeEvery(EAction.LOGOUT_USER, logoutUser);
}

export default authSaga;
