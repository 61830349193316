import Actions from "./actionTypes";

import { combineReducers } from "redux";
import {
  EditAccountAction,
  EditAccountSuccessAction,
  EditAccountFailAction,
  EditPasswordAction,
  EditPasswordSuccessAction,
  EditPasswordFailAction,
  GetAccountAction,
  GetAccountFailAction,
  GetAccountSuccessAction,
  ResetPasswordAction,
  ResetPasswordSuccessAction,
  ResetPasswordFailAction
} from "./actions";
import {IValidationError} from "../../models/common/saga";
import {ErrorValidateModel} from "../../generated-sources/openapi";

const initialAccountState: IAccountState = {
  data: null,
  error: null,
};

interface IAccountState {
  data: any;
  error: Error | ErrorValidateModel | null;
}

type AccountAction =
    | EditAccountAction
    | EditAccountSuccessAction
    | EditAccountFailAction
    | GetAccountAction
    | GetAccountFailAction
    | GetAccountSuccessAction;

const accountReducer = (
  state = initialAccountState,
  action: AccountAction
): IAccountState => {
  switch (action.type) {

    case Actions.GET_ACCOUNT_SUCCESS:
      return { ...state, data: action.payload.account };

    case Actions.GET_ACCOUNT_FAIL:
      return { ...state, error: action.payload };

    case Actions.EDIT_ACCOUNT_FAIL:
      return { ...state, data: action.payload };

    default:
      return state;
  }
};

const initialPasswordState: IPasswordState = {
  data: null,
  error: null,
};

interface IPasswordState {
  data: any;
  error: IValidationError | null;
}

type PasswordAction =
    | EditPasswordAction
    | EditPasswordFailAction
    | EditPasswordSuccessAction
    | ResetPasswordAction
    | ResetPasswordSuccessAction
    | ResetPasswordFailAction

const passwordReducer = (
  state = initialPasswordState,
  action: PasswordAction
): IPasswordState => {
  switch (action.type) {
    case Actions.EDIT_PASSWORD:
      return { ...state };

    case Actions.EDIT_PASSWORD_SUCCESS:
      return { ...state, data: action.payload};

    case Actions.EDIT_PASSWORD_FAIL:
      return { ...state, error: action.payload};

    case Actions.RESET_PASSWORD:
      return { ...state };

    case Actions.RESET_PASSWORD_SUCCESS:
      return { ...state, data: action.payload};

    case Actions.RESET_PASSWORD_FAIL:
      return { ...state, error: action.payload};

    default:
      return state;
  }
};

export default combineReducers({
  account: accountReducer,
  password: passwordReducer,
});
