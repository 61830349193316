import EAction from "./actionTypes";
import ELoginAction from "../login/actionTypes";
import {
  LoginSuccessAction,
  LogoutUserAction,
} from "../login/actions";
import { ApiErrorAction } from "./actions";
interface IReduxState {
  error: Error | null;
  info: Object | null;
  loading: boolean;
}

const initialState = {
  error: null,
  info: null,
  loading: false,
} as IReduxState;

type IAction =
  | LoginSuccessAction
  | LogoutUserAction
  | ApiErrorAction;

const authInfo = (state = initialState, action: IAction) => {
  switch (action.type) {
    case ELoginAction.LOGIN_SUCCESS:
      state = {
        ...state,
        info: action.payload,
        loading: false,
      };
      break;
    case ELoginAction.LOGOUT_USER:
      state = { ...state, info: null };
      break;

    case EAction.API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default authInfo;
