import { takeEvery, put, call, select, fork } from "redux-saga/effects";

import EAction from "./actionTypes";
import {
  GetPortalConfigurationAction,
} from "./actions";

import { getPortalConfiguration } from "../../helpers/api";
import { processRequestZeroArgs } from "../../utils/requestUtils";

function* onPortalConfiguration(action: GetPortalConfigurationAction) {
    yield processRequestZeroArgs(action.type, getPortalConfiguration)
}

function* configurationSaga() {
  yield takeEvery(EAction.GET_PORTAL_CONFIGURATION, onPortalConfiguration);
}

export default configurationSaga;
