import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout";
import HorizontalLayout from "./components/HorizontalLayout";
import NonAuthLayout from "./components/NonAuthLayout";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { ErrorBoundary } from "react-error-boundary";
import { Alert } from "reactstrap";
import logger from "./utils/logger";
// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// Import RouteChangeTracker for Google Analytics to track changes in URL - THROWES ERROR
// import RouteChangeTracker from ".router/RouteChangeTracker";

// Import Google Analytics
import ReactGA from "react-ga";
const TRACKING_ID = "G-SNSMKKCJG4"; // YOUR_OWN_TRACKING_ID

// Import ReactPixel
import ReactPixel from "react-facebook-pixel";
import { BsChevronDoubleRight } from "react-icons/bs";

// Google Analytics initializing
ReactGA.initialize(TRACKING_ID);

// Pixel setting - got it here https://www.npmjs.com/package/react-facebook-pixel

// const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init("395244538702020", options);

ReactPixel.pageView(); // For tracking page view
// ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// ReactPixel.trackSingle("PixelId", event, data); // For tracking default events.
// ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
// ReactPixel.trackSingleCustom("PixelId", event, data); // For tracking custom events.



// Activating fake firebase
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

function ErrorFallback({ error, resetErrorBoundary }) {
  const { t } = useTranslation();

  return (
    <div role="alert">
      <Alert color="info">
        {t("Common.somethingwrong")}
        <p>{error.message}</p>
      </Alert>
      {/* <button onClick={resetErrorBoundary}>Try again</button> */}
    </div>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func,
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }

  componentDidMount() {
    const ReactPixel = require("react-facebook-pixel");
    ReactPixel.default.init("395244538702020");
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  handleError = (error, info) => {
    logger.error("REACT ERROR:" + error + ":" + info.componentStack)
  };

  render() {
    const Layout = this.getLayout();

    return (
      // <GoogleReCaptchaProvider useRecaptchaNet={false} reCaptchaKey="6Ld7AcsZAAAAANyjdRJ7bLgKG7SITi9MEO_x5vd1">
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={this.handleError}
      >
        <React.Fragment>
          {/* <RouteChangeTracker /> */}
          <Router /*basename={"/"}*/>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact={route.exact}
                />
              ))}
            </Switch>
          </Router>
        </React.Fragment>
      </ErrorBoundary>
      // </GoogleReCaptchaProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

App.propTypes = {
  layout: PropTypes.object,
};

export default connect(mapStateToProps, null)(App);
