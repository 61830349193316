import styled from "styled-components";
import SatisfactionEnum from "../models/common/satisfactionEnum";
import { TFunction } from "i18next";
import { number } from "prop-types";

export const satisfationTexts = {
  [SatisfactionEnum.VeryDissatisfied]: "very_dissatisfied",
  [SatisfactionEnum.Dissatisfied]: "dissatisfied",
  [SatisfactionEnum.Satisfied]: "satisfied",
  [SatisfactionEnum.VerySatisfied]: "very_satisfied",
};

export const satisfactionColors = {
  [SatisfactionEnum.VeryDissatisfied]: "#F7685B",
  [SatisfactionEnum.Dissatisfied]: "#FFBA44",
  [SatisfactionEnum.Satisfied]: "#2AD57B",
  [SatisfactionEnum.VerySatisfied]: "#2AD57B",
};

export const getSatisfactionTextFromLevel = (t : TFunction, level: SatisfactionEnum) => {
  return t("Feedbacks.satisfaction." + satisfationTexts[level]);
};

export const getColorForSatisfactionLevel = (level : SatisfactionEnum) => {
  return satisfactionColors[level];
};

export const light = 100;
export const bold = 500;

type H5Props = {
  isBold: boolean;
};

export const ReviewHeading = styled.h5<H5Props>`
  font-weight: ${props => (props.isBold ? `${bold}` : `${light}`)};
`;

export const getShadowStyle = (darkCoef : number) =>
  `0px 4px 4px rgba(0, 0, 0, ${0.1 * darkCoef})`;

export const getFontWeightForFeedback = (feedback : any) =>
  feedback.unread ? bold : light;

export const getBoxShadowDarkCoefForFeedback = (feedback : any)  => {
  return feedback.unread ? 3 : 1;
};

export const getShadowStyleForFeedback = (feedback : any)  =>
  getShadowStyle(getBoxShadowDarkCoefForFeedback(feedback));
