import {
  EshopModel,
  PageResultModelEshopModel,
} from "../../models/common/fromGenerated";
import {
  IValidationError,
  reduxAction,
  reduxActionBasic,
} from "../../models/common/saga";
import EAction from "./actionTypes";

export const connectEshop = (data: EshopModel, history: any) =>
  reduxAction(EAction.CONNECT_ESHOP, { data, history });

export const connectEshopSuccess = (data: EshopModel, message: string) =>
  reduxAction(EAction.CONNECT_ESHOP_SUCCESS, { data, message });

export const connectEshopFail = (error: Error) =>
  reduxAction(EAction.CONNECT_ESHOP_FAIL, error);

export const clearConnectEshopError = () =>
  reduxActionBasic(EAction.CONNECT_ESHOP_CLEAR_ERROR);
  
export const connectEshopValidationFailed = (error: IValidationError) =>
  reduxAction(EAction.CONNECT_ESHOP_VALIDATION_FAIL, error);

export const getAllEshops = () => reduxActionBasic(EAction.GET_ALL_ESHOPS);

export const getAllEshopsFail = (error: Error) =>
  reduxAction(EAction.GET_ALL_ESHOPS_FAIL, error);

export const getAllEshopsSuccess = (data: EshopModel[]) =>
  reduxAction(EAction.GET_ALL_ESHOPS_SUCCESS, data);

export const setSelectedEshop = (eshopId: string) =>
  reduxAction(EAction.SET_SELECTED_ESHOP, eshopId);

export const getEshopsOverview = () =>
  reduxActionBasic(EAction.GET_ESHOPS_OVERVIEW);

export const getEshopsOverviewSuccess = (payload: PageResultModelEshopModel) =>
  reduxAction(EAction.GET_ESHOPS_OVERVIEW_SUCCESS, payload);

export const getEshopOverviewFail = (error: Error) =>
  reduxAction(EAction.GET_ESHOPS_OVERVIEW_FAIL, error);

export const getEshopById = (id: string) =>
  reduxAction(EAction.GET_ESHOP_BY_ID, id);

export const getEshopByIdFail = (error: Error) =>
  reduxAction(EAction.GET_ESHOP_BY_ID_FAIL, error);

export const getEshopByIdSuccess = (payload: EshopModel) =>
  reduxAction(EAction.GET_ESHOP_BY_ID_SUCCESS, payload);

export const deleteEshopById = (id: string) =>
  reduxAction(EAction.DELETE_ESHOP_BY_ID, id);

export const deleteEshopByIdFail = (error: Error) =>
  reduxAction(EAction.DELETE_ESHOP_BY_ID_FAIL, error);

export const deleteEshopByIdSuccess = (payload: any) =>
  reduxAction(EAction.DELETE_ESHOP_BY_ID_SUCCESS, payload);

export const changeEshopsOverviewPaging = (payload: any) =>
  reduxAction(EAction.CHANGE_ESHOPS_OVERVIEW_PAGING, payload);

export const editEshopById = (id: string, data: EshopModel, history: any) =>
  reduxAction(EAction.EDIT_ESHOP_BY_ID, { id, data, history });

export const editEshopByIdSuccess = (data: any, message: string) =>
  reduxAction(EAction.EDIT_ESHOP_BY_ID_SUCCESS, { data, message });

export const editEshopByIdFail = (error: Error) =>
  reduxAction(EAction.EDIT_ESHOP_BY_ID_FAIL, error);

export const editEshopByIdValidationFail = (error: IValidationError) =>
  reduxAction(EAction.EDIT_ESHOP_BY_ID_VALIDATION_FAIL, error);

export type ConnectEshopAction = ReturnType<typeof connectEshop>;
export type ConnectEshopSuccessAction = ReturnType<typeof connectEshopSuccess>;
export type ConnectEshopFailAction = ReturnType<typeof connectEshopFail>;
export type ConnectEshopValidationFailAction = ReturnType<
  typeof connectEshopValidationFailed
>;
export type GetAllEshopsAction = ReturnType<typeof getAllEshops>;
export type GetAllEshopsFailAction = ReturnType<typeof getAllEshopsFail>;
export type GetAllEshopsSuccessAction = ReturnType<typeof getAllEshopsSuccess>;
export type SetSelectedEshopAction = ReturnType<typeof setSelectedEshop>;
export type GetEshopsOverviewAction = ReturnType<typeof getEshopsOverview>;
export type GetEshopsOverviewSuccessAction = ReturnType<
  typeof getEshopsOverviewSuccess
>;
export type GetEshopOverviewFailAction = ReturnType<
  typeof getEshopOverviewFail
>;
export type GetEshopByIdAction = ReturnType<typeof getEshopById>;
export type GetEshopByIdFailAction = ReturnType<typeof getEshopByIdFail>;
export type GetEshopByIdSuccessAction = ReturnType<typeof getEshopByIdSuccess>;
export type DeleteEshopByIdAction = ReturnType<typeof deleteEshopById>;
export type DeleteEshopByIdFailAction = ReturnType<typeof deleteEshopByIdFail>;
export type DeleteEshopByIdSuccessAction = ReturnType<
  typeof deleteEshopByIdSuccess
>;
export type ChangeEshopsOverviewPagingAction = ReturnType<
  typeof changeEshopsOverviewPaging
>;
export type EditEshopByIdAction = ReturnType<typeof editEshopById>;
export type EditEshopByIdSuccessAction = ReturnType<
  typeof editEshopByIdSuccess
>;
export type EditEshopByIdFailAction = ReturnType<typeof editEshopByIdFail>;
export type EditEshopByIdValidationFailAction = ReturnType<
  typeof editEshopByIdValidationFail
>;
export type ClearConnectEshopError = ReturnType<typeof clearConnectEshopError>;
