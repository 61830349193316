enum EAction {
  LOGIN_USER = "LOGIN_USER",
  LOGIN_SUCCESS = "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAIL = "LOGIN_USER_FAIL",
  LOGOUT_USER = "LOGOUT_USER",
  LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS",
  API_ERROR = "LOGIN_API_ERROR",
  SOCIAL_LOGIN = "SOCIAL_LOGIN",
}
export default EAction;
