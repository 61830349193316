import React, { Component } from "react";

import classnames from "classnames";

import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import Feedback from "./Feedback";
import FeedbacksFilter from "./FeedbacksFilter";
interface IState {
  customActiveTab : string
}

class FeedbacksTab extends Component<any, IState> {
  constructor(props : any) {
    super(props);
    this.state = {
      customActiveTab: "1",
    };
    this.toggleCustom = this.toggleCustom.bind(this);
  }

  toggleCustom(tab : string) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col md="5">
            <FeedbacksFilter />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <TabContent
              activeTab={this.state.customActiveTab}
              className="text-muted"
            >
              <TabPane tabId="1">
                <Feedback />
                {/* <FeedbackDetail /> */}
              </TabPane>
              {/* <TabPane tabId="2">
                <Feedback />
              </TabPane>
              <TabPane tabId="3">
                <Feedback />
              </TabPane> */}
            </TabContent>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default FeedbacksTab;
