import actionTypes from "./actionTypes";
import { combineReducers } from "redux";
import {
  PageModel,
  ReviewFilterModel,
  ReviewModel,
} from "../../models/common/fromGenerated";
import {
  ChangeFeedbacksLatestPagingAction,
  ChangeFeedbacksOverviewFilterAction,
  ChangeFeedbacksOverviewPagingAction,
  GetFeedbackByIdAction,
  GetFeedbackByIdSuccessAction,
  GetFeedbacksLatesSuccessAction,
  GetFeedbacksOverviewSuccessAction,
  SetReadFeedbackAction,
} from "./actions";

// const initialState = {
//   data: [],
//   error: "",
// };

// interface IFeedbacksState {

// }

// type FeedbacksAction = GetFeedbacksSuccessAction | GetFeedbacksFailAction

// const feedbacks = (state = initialState, { type, payload } : FeedbacksAction) => {
//   switch (type) {
//     case actionTypes.GET_FEEDBACKS_SUCCESS:
//       return { ...state, data: payload };

//     case actionTypes.GET_FEEDBACKS_FAIL:
//       return { ...state, error: payload };
//     default:
//       return state;
//   }
// };

const overviewInitialState: IOverviewState = {
  data: [],
  page: {
    page: 0,
    recordPerPage: 5,
  },
  filter: {
    forProducts: true,
    forShops: true,
  },
  total: 100,
};

interface IOverviewState {
  data: ReviewModel[];
  page: PageModel | null;
  filter: ReviewFilterModel;
  total: number;
}

type OverviewAction =
  | GetFeedbacksOverviewSuccessAction
  | ChangeFeedbacksOverviewPagingAction
  | ChangeFeedbacksOverviewFilterAction
  | SetReadFeedbackAction;

const overview = (
  state: IOverviewState = overviewInitialState,
  action: OverviewAction
): IOverviewState => {
  switch (action.type) {
    case actionTypes.GET_FEEDBACKS_OVERVIEW_SUCCESS:
      return {
        ...state,
        data: action.payload.items || [],
        total: action.payload.totalItems || 0,
        page: action.payload.page || null,
      };
    case actionTypes.CHANGE_FEEDBACKS_OVERVIEW_PAGING:
      return {
        ...state,
        page: {
          ...state.page,
          ...action.payload,
        },
      };
    case actionTypes.CHANGE_FEEDBACKS_OVERVIEW_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case actionTypes.SET_READ_FEEDBACK_BY_ID:
      return {
        ...state,
        data: state.data.map(item =>
          item.id === action.payload ? { ...item, unread: false } : item
        ),
      };
    default:
      return state;
  }
};

const latestInitialState: LatestState = {
  data: [],
  page: {
    page: 0,
    recordPerPage: 5,
  },
  total: 100,
};

type LatestState = Omit<IOverviewState, "filter">;

type LatestAction =
  | GetFeedbacksLatesSuccessAction
  | ChangeFeedbacksLatestPagingAction;

const latest = (
  state = latestInitialState,
  action: LatestAction
): LatestState => {
  switch (action.type) {
    case actionTypes.GET_FEEDBACKS_LATEST_SUCCESS:
      return {
        ...state,
        data: action.payload.items || [],
        total: action.payload.totalItems || 0,
        page: action.payload.page || { page: 0, recordPerPage: 5 },
      };
    case actionTypes.CHANGE_FEEDBACKS_LATEST_PAGING:
      return {
        ...state,
        page: {
          ...state.page,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

const initialFeedbackState: IFeedbackState = {
  data: null,
};

interface IFeedbackState {
  data: ReviewModel | null;
}

type FeedbackAction = GetFeedbackByIdSuccessAction | GetFeedbackByIdAction;

const feedback = (
  state = initialFeedbackState,
  action: FeedbackAction
): IFeedbackState => {
  switch (action.type) {
    case actionTypes.GET_FEEDBACK_BY_ID_SUCCESS:
      return { ...state, data: action.payload };
    case actionTypes.GET_FEEDBACK_BY_ID: {
      return { ...state, data: null };
    }
    default:
      return state;
  }
};

export default combineReducers({
  feedback,
  // feedbacks,
  overview,
  latest,
});
