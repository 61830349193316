import React from "react";
import { Card, CardBody, Col, Container, Row, Alert } from "reactstrap";
import {Link, RouteComponentProps, useParams, withRouter} from "react-router-dom";
// Redux
import { connect, ConnectedProps } from "react-redux";

//action
import { resetPassword } from "../../store/account/actions";

// import images
import LoadingButton from "../../components/Common/LoadingButton";
import Actions from "../../store/account/actionTypes";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store";
import { getCustomServerValidation } from "../../helpers/validation_helper";
const { AvField, AvForm } = require("availity-reactstrap-validation");
import CopyrightAdalo from "../../components/Common/CopyrightAdalo";

type Props = PropsFromRedux & RouteComponentProps;

function ChangePasswordPage(props: Props) {
    // handleValidSubmit
    const handleValidSubmit = (event: Event, values: any) => {
        props.resetPassword(values, props.history);
    };

    const { t } = useTranslation();


    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="bx bx-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">
                                                    {t("ChangePassword.passwordRecovery")}
                                                </h5>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={handleValidSubmit}
                                        >
                                            {props.error?.items?.[0].key === "secret" &&
                                                <Alert color="danger">{props.error?.items?.[0].message}</Alert>
                                            }
                                            {/* <LoadingResultAlert actiontype={Actions.RESET_PASSWORD} /> */}
                                            <div className="mb-3">
                                                <AvField
                                                    name="newPwd"
                                                    label={t("ChangePassword.newPassword")}
                                                    className="form-control"
                                                    placeholder={t("ChangePassword.password")}
                                                    type="password"
                                                    required
                                                    errorMessage={t("Common.required")}
                                                    validate={getCustomServerValidation("newPwd", props.error)}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    name="checkNewPwd"
                                                    label={t("ChangePassword.repeatNewPassword")}
                                                    className="form-control"
                                                    placeholder={t("ChangePassword.password")}
                                                    type="password"
                                                    required
                                                    errorMessage={t("Common.required")}
                                                    validate={
                                                    {match:{value:'newPwd', errorMessage: t("Account.passNotSame")}} ||
                                                        getCustomServerValidation("checkNewPwd", props.error)
                                                    }
                                                />
                                                <AvField
                                                    disabled
                                                    hidden
                                                    name="secret"
                                                    value={window.location.pathname.split("/", )[2]}
                                                    required
                                                />
                                            </div>
                                            <div className="text-end">
                                                <LoadingButton
                                                    title={t("ChangePassword.save")}
                                                    className="btn btn-primary w-md"
                                                    type="submit"
                                                    actiontype={Actions.RESET_PASSWORD}
                                                ></LoadingButton>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    {t("ForgetPassword.gobackto")}{" "}
                                    <Link to="../login" className="fw-medium text-primary">
                                        {t("ForgetPassword.login")}
                                    </Link>
                                </p>
                                <CopyrightAdalo />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state: RootState) => {
    const { data, error } = state.EditAccount.password;
    return { data, error };
};

const connector = connect(mapStateToProps, { resetPassword });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(ChangePasswordPage));
