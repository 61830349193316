import { ReviewModelSatisfactionLevelEnum } from "../../models/common/fromGenerated";


// enum SatisfactionEnum {
//   VERY_DISSATISFIED = "VERY_DISSATISFIED",
//   DISSATISFIED = "DISSATISFIED",
//   SATISFIED = "SATISFIED",
//   VERY_SATISFIED = "VERY_SATISFIED",
// }



export default ReviewModelSatisfactionLevelEnum;
