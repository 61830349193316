import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import * as authStorage from "../../../helpers/authStorage";

//i18n
import { withTranslation } from "react-i18next";

// users
import user1 from "../../../assets/images/users/avatarDefault.png";

import { connect } from "react-redux";
import { AnyMxRecord } from "dns";
import { RootState } from "../../../store";

const getAuthInfo = () => {
  const data = authStorage.getAuth();
  return data;
};

interface IState {
  menu: boolean,
  name: string,
}

class ProfileMenu extends Component<any, IState> {
  constructor(props : any) {
    super(props);
    this.state = {
      menu: false,
      name: "Admin",
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    const userData = getAuthInfo();
    if (userData) {
      this.setState({ name: userData.email! });
    }
  }

  componentDidUpdate(prevProps : any) {
    // if (prevProps.success !== this.props.success) {
    //   const userData = getUserName();
    //   if (userData) {
    //     this.setState({ name: userData.username });
    //   }
    // }
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />{" "}
            <span className="d-none d-xl-inline-block ms-1">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end-profile">
            {/* <DropdownItem tag="a" href="/profile">
              <i className="bx bx-user font-size-16 align-middle ms-1" />
              {this.props.t("Profile")}
            </DropdownItem>
            <DropdownItem tag="a" href="#">
              <span className="badge bg-success float-end">11</span>
              <i className="bx bx-wrench font-size-17 align-middle me-1" />
              {this.props.t("Settings")}
            </DropdownItem>
            <div className="dropdown-divider" /> */}
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{this.props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state : RootState) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
);
