import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import SimpleAreaChart from "./SimpleAreaChart";
import DoughnutChart from "./DoughnutChart";
import LatestFeedbacks from "./LatestFeedbacks";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import SettingsRedirect from "../../components/Common/SettingsRedirect";
import { RootState } from "../../store";

const Dashboard = () => {
  return (
    <React.Fragment>
      <SettingsRedirect />
      <div className="page-content">
        <MetaTags>
          <title>Přehledy | TrustBite</title>
        </MetaTags>
        <Container>
          <h4>Přehledy</h4>
          <Row>
            <Col lg="7" md="6">
              <Card>
                <CardBody>
                  <h5>Nejnovější recenze</h5>
                  <LatestFeedbacks />
                </CardBody>
              </Card>
            </Col>
            <Col lg="5" md="6">
                {/*
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h5>Počet recenzí</h5>
                      <SimpleAreaChart />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h5>Zdroje recenzí</h5>
                      <DoughnutChart />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {};

const mapStateToProps = (state : RootState) => {
  return {};
};

export default connect(mapStateToProps, {})(Dashboard);
