import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";

import classnames from "classnames";

import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import { changeFeedbacksOverviewFilter } from "../../store/feedbacks/actions";
import { RootState } from "../../store";
import { ReviewFilterModel } from "../../models/common/fromGenerated";

const parseFilter = (filter : ReviewFilterModel) => {
  return {
    isEshopActive: filter.forShops && !filter.forProducts,
    isProductActive: !filter.forShops && filter.forProducts,
    isAll: filter.forShops && filter.forProducts,
  };
};

const useOverviewFilter = (filter: ReviewFilterModel) => {
  const [state, setstate] = useState(parseFilter(filter));
  useEffect(() => {
    setstate(parseFilter(filter));
  }, [filter]);
  return state;
};

const FeedbacksFilter = ({ filter, changeFilter } : Props) => {
  const { t } = useTranslation();

  const { isEshopActive, isProductActive, isAll } = useOverviewFilter(filter);

  const changeFilterClick = (forProducts: boolean, forShops : boolean) => () => {
    changeFilter({ forProducts: forProducts, forShops: forShops });
  };

  return (
    <Card style={{ marginBottom: "0px" }}>
      <CardBody>
        <Nav
          tabs
          className="nav-tabs-custom nav-justified"
          style={{ backgroundColor: "#ffffff" }}
        >
          <NavItem style={{ color: "#ffffff" }}>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: isAll,
              })}
              onClick={changeFilterClick(true, true)}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">
                {t("Feedbacks.filter.all")}
              </span>
            </NavLink>
          </NavItem>
          <NavItem style={{ color: "#ffffff" }}>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: isProductActive,
              })}
              onClick={changeFilterClick(true, false)}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-user"></i>
              </span>
              <span className="d-none d-sm-block">
                {t("Feedbacks.filter.products")}
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: isEshopActive,
              })}
              onClick={changeFilterClick(false, true)}
            >
              <span className="d-none d-sm-block">
                {t("Feedbacks.filter.shops")}
              </span>
            </NavLink>
          </NavItem>
        </Nav>
      </CardBody>
    </Card>
  );
};


const connector =  connect(
  (state : RootState, ownprops) => ({
    filter: state.Feedbacks.overview.filter,
  }),
  { changeFilter: changeFeedbacksOverviewFilter }
)
type ReduxProps = ConnectedProps<typeof connector>
type Props = ReduxProps;

export default connector(FeedbacksFilter);
