/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthInfoModel
 */
export interface AuthInfoModel {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AuthInfoModel
     */
    'attributes'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AuthInfoModel
     */
    'authKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthInfoModel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthInfoModel
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthInfoModel
     */
    'lastname'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthInfoModel
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AuthInfoModel
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface ChangePasswordRequestModel
 */
export interface ChangePasswordRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequestModel
     */
    'checkNewPwd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequestModel
     */
    'newPwd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequestModel
     */
    'oldPwd'?: string;
}
/**
 * 
 * @export
 * @interface ConfigurationModel
 */
export interface ConfigurationModel {
    /**
     * 
     * @type {Array<HeurekaType>}
     * @memberof ConfigurationModel
     */
    'heurekaTypes'?: Array<HeurekaType>;
    /**
     * 
     * @type {Array<ReviewService>}
     * @memberof ConfigurationModel
     */
    'reviewServices'?: Array<ReviewService>;
}
/**
 * 
 * @export
 * @interface CredentialModel
 */
export interface CredentialModel {
    /**
     * 
     * @type {string}
     * @memberof CredentialModel
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialModel
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface EmailRegistrationModel
 */
export interface EmailRegistrationModel {
    /**
     * 
     * @type {string}
     * @memberof EmailRegistrationModel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailRegistrationModel
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailRegistrationModel
     */
    'recaptchaToken'?: string;
}
/**
 * 
 * @export
 * @interface ErrorModel
 */
export interface ErrorModel {
    /**
     * 
     * @type {string}
     * @memberof ErrorModel
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorModel
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorModel
     */
    'status'?: number;
}
/**
 * 
 * @export
 * @interface ErrorValidateModel
 */
export interface ErrorValidateModel {
    /**
     * 
     * @type {string}
     * @memberof ErrorValidateModel
     */
    'error'?: string;
    /**
     * 
     * @type {Array<NotValidItemModel>}
     * @memberof ErrorValidateModel
     */
    'items'?: Array<NotValidItemModel>;
    /**
     * 
     * @type {string}
     * @memberof ErrorValidateModel
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorValidateModel
     */
    'status'?: number;
}
/**
 * 
 * @export
 * @interface EshopModel
 */
export interface EshopModel {
    /**
     * 
     * @type {string}
     * @memberof EshopModel
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EshopModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EshopModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EshopModel
     */
    'secretKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof EshopModel
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EshopModel
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface EshopOverviewRequestModel
 */
export interface EshopOverviewRequestModel {
    /**
     * 
     * @type {PageModel}
     * @memberof EshopOverviewRequestModel
     */
    'page'?: PageModel;
    /**
     * 
     * @type {SortModel}
     * @memberof EshopOverviewRequestModel
     */
    'sort'?: SortModel;
}
/**
 * 
 * @export
 * @interface EventsListRequestModel
 */
export interface EventsListRequestModel {
    /**
     * 
     * @type {PageModel}
     * @memberof EventsListRequestModel
     */
    'page'?: PageModel;
    /**
     * 
     * @type {SortModel}
     * @memberof EventsListRequestModel
     */
    'sort'?: SortModel;
}
/**
 * 
 * @export
 * @interface HeurekaType
 */
export interface HeurekaType {
    /**
     * 
     * @type {string}
     * @memberof HeurekaType
     */
    'internalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof HeurekaType
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface IntegrationHeurekaSettingModel
 */
export interface IntegrationHeurekaSettingModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationHeurekaSettingModel
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationHeurekaSettingModel
     */
    'eshopId'?: string;
    /**
     * 
     * @type {Array<TransferThresholdForServiceModel>}
     * @memberof IntegrationHeurekaSettingModel
     */
    'transferThresholds'?: Array<TransferThresholdForServiceModel>;
    /**
     * 
     * @type {string}
     * @memberof IntegrationHeurekaSettingModel
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface IntegrationZboziczSettingModel
 */
export interface IntegrationZboziczSettingModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationZboziczSettingModel
     */
    'apiKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationZboziczSettingModel
     */
    'eshopId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationZboziczSettingModel
     */
    'shopId'?: string;
    /**
     * 
     * @type {Array<TransferThresholdForServiceModel>}
     * @memberof IntegrationZboziczSettingModel
     */
    'transferThresholds'?: Array<TransferThresholdForServiceModel>;
}
/**
 * 
 * @export
 * @interface LogModel
 */
export interface LogModel {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LogModel
     */
    'attributes'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof LogModel
     */
    'level'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogModel
     */
    'logType'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogModel
     */
    'longMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogModel
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogModel
     */
    'time'?: string;
}
/**
 * 
 * @export
 * @interface MessageModel
 */
export interface MessageModel {
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface NotValidItemModel
 */
export interface NotValidItemModel {
    /**
     * 
     * @type {string}
     * @memberof NotValidItemModel
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotValidItemModel
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface PageModel
 */
export interface PageModel {
    /**
     * 
     * @type {number}
     * @memberof PageModel
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageModel
     */
    'recordPerPage'?: number;
}
/**
 * 
 * @export
 * @interface PageResultModelEshopModel
 */
export interface PageResultModelEshopModel {
    /**
     * 
     * @type {Array<EshopModel>}
     * @memberof PageResultModelEshopModel
     */
    'items'?: Array<EshopModel>;
    /**
     * 
     * @type {PageModel}
     * @memberof PageResultModelEshopModel
     */
    'page'?: PageModel;
    /**
     * 
     * @type {number}
     * @memberof PageResultModelEshopModel
     */
    'totalItems'?: number;
}
/**
 * 
 * @export
 * @interface PageResultModelLogModel
 */
export interface PageResultModelLogModel {
    /**
     * 
     * @type {Array<LogModel>}
     * @memberof PageResultModelLogModel
     */
    'items'?: Array<LogModel>;
    /**
     * 
     * @type {PageModel}
     * @memberof PageResultModelLogModel
     */
    'page'?: PageModel;
    /**
     * 
     * @type {number}
     * @memberof PageResultModelLogModel
     */
    'totalItems'?: number;
}
/**
 * 
 * @export
 * @interface PageResultModelReviewModel
 */
export interface PageResultModelReviewModel {
    /**
     * 
     * @type {Array<ReviewModel>}
     * @memberof PageResultModelReviewModel
     */
    'items'?: Array<ReviewModel>;
    /**
     * 
     * @type {PageModel}
     * @memberof PageResultModelReviewModel
     */
    'page'?: PageModel;
    /**
     * 
     * @type {number}
     * @memberof PageResultModelReviewModel
     */
    'totalItems'?: number;
}
/**
 * 
 * @export
 * @interface PushLogsRequestModel
 */
export interface PushLogsRequestModel {
    /**
     * 
     * @type {Array<LogModel>}
     * @memberof PushLogsRequestModel
     */
    'logs'?: Array<LogModel>;
}
/**
 * 
 * @export
 * @interface ResetPasswordByEmailRequestModel
 */
export interface ResetPasswordByEmailRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordByEmailRequestModel
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordRequestModel
 */
export interface ResetPasswordRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequestModel
     */
    'checkNewPwd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequestModel
     */
    'newPwd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequestModel
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface ReviewAnswerModel
 */
export interface ReviewAnswerModel {
    /**
     * 
     * @type {string}
     * @memberof ReviewAnswerModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewAnswerModel
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewAnswerModel
     */
    'reviewId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReviewAnswerModel
     */
    'syncToReviewServices'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ReviewFilterModel
 */
export interface ReviewFilterModel {
    /**
     * 
     * @type {string}
     * @memberof ReviewFilterModel
     */
    'eshopId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReviewFilterModel
     */
    'forProducts'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReviewFilterModel
     */
    'forShops'?: boolean;
}
/**
 * 
 * @export
 * @interface ReviewModel
 */
export interface ReviewModel {
    /**
     * 
     * @type {string}
     * @memberof ReviewModel
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewModel
     */
    'customerMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewModel
     */
    'feedbackMessage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReviewModel
     */
    'hasFeedback'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReviewModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewModel
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewModel
     */
    'reviewFor'?: ReviewModelReviewForEnum;
    /**
     * 
     * @type {string}
     * @memberof ReviewModel
     */
    'reviewServiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewModel
     */
    'reviewServiceName'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ReviewModel
     */
    'reviewServicesSyncStatusMap'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ReviewModel
     */
    'satisfactionLevel'?: ReviewModelSatisfactionLevelEnum;
    /**
     * 
     * @type {number}
     * @memberof ReviewModel
     */
    'satisfactionValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReviewModel
     */
    'unread'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ReviewModelReviewForEnum {
    Product = 'PRODUCT',
    Shop = 'SHOP'
}
/**
    * @export
    * @enum {string}
    */
export enum ReviewModelSatisfactionLevelEnum {
    VeryDissatisfied = 'VERY_DISSATISFIED',
    Dissatisfied = 'DISSATISFIED',
    Satisfied = 'SATISFIED',
    VerySatisfied = 'VERY_SATISFIED'
}

/**
 * 
 * @export
 * @interface ReviewOverviewRequestModel
 */
export interface ReviewOverviewRequestModel {
    /**
     * 
     * @type {ReviewFilterModel}
     * @memberof ReviewOverviewRequestModel
     */
    'filter'?: ReviewFilterModel;
    /**
     * 
     * @type {PageModel}
     * @memberof ReviewOverviewRequestModel
     */
    'page'?: PageModel;
    /**
     * 
     * @type {SortModel}
     * @memberof ReviewOverviewRequestModel
     */
    'sort'?: SortModel;
}
/**
 * 
 * @export
 * @interface ReviewService
 */
export interface ReviewService {
    /**
     * 
     * @type {string}
     * @memberof ReviewService
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewService
     */
    'internalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewService
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface SortModel
 */
export interface SortModel {
    /**
     * 
     * @type {boolean}
     * @memberof SortModel
     */
    'asc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SortModel
     */
    'column'?: string;
}
/**
 * 
 * @export
 * @interface TransferThresholdForServiceModel
 */
export interface TransferThresholdForServiceModel {
    /**
     * 
     * @type {boolean}
     * @memberof TransferThresholdForServiceModel
     */
    'automaticTransfer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransferThresholdForServiceModel
     */
    'serviceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferThresholdForServiceModel
     */
    'threshold'?: number;
}
/**
 * 
 * @export
 * @interface UserAccountModel
 */
export interface UserAccountModel {
    /**
     * 
     * @type {string}
     * @memberof UserAccountModel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountModel
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountModel
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountModel
     */
    'surname'?: string;
}
/**
 * 
 * @export
 * @interface UserRequirementsModel
 */
export interface UserRequirementsModel {
    /**
     * 
     * @type {string}
     * @memberof UserRequirementsModel
     */
    'body'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserRequirementsModel
     */
    'data'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof UserRequirementsModel
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequirementsModel
     */
    'type'?: UserRequirementsModelTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserRequirementsModelTypeEnum {
    Unknown = 'UNKNOWN',
    ResetPassword = 'RESET_PASSWORD'
}


/**
 * AccountControllerApi - axios parameter creator
 * @export
 */
export const AccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary accountInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInfoUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/myaccount/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changePassword
         * @param {ChangePasswordRequestModel} changePasswordRequestModel changePasswordRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST: async (changePasswordRequestModel: ChangePasswordRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordRequestModel' is not null or undefined
            assertParamExists('changePasswordUsingPOST', 'changePasswordRequestModel', changePasswordRequestModel)
            const localVarPath = `/api/v1/myaccount/change-pwd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary requestResetPassword
         * @param {ResetPasswordByEmailRequestModel} resetPasswordRequestModel resetPasswordRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPasswordUsingPOST: async (resetPasswordRequestModel: ResetPasswordByEmailRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequestModel' is not null or undefined
            assertParamExists('requestResetPasswordUsingPOST', 'resetPasswordRequestModel', resetPasswordRequestModel)
            const localVarPath = `/api/v1/account/request-reset-pwd-by-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resetPassword
         * @param {ResetPasswordRequestModel} resetPasswordRequestModel resetPasswordRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST: async (resetPasswordRequestModel: ResetPasswordRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequestModel' is not null or undefined
            assertParamExists('resetPasswordUsingPOST', 'resetPasswordRequestModel', resetPasswordRequestModel)
            const localVarPath = `/api/v1/account/reset-pwd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAccountInfo
         * @param {UserAccountModel} userAccountModel userAccountModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountInfoUsingPOST: async (userAccountModel: UserAccountModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAccountModel' is not null or undefined
            assertParamExists('updateAccountInfoUsingPOST', 'userAccountModel', userAccountModel)
            const localVarPath = `/api/v1/myaccount/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccountModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary accountInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountInfoUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountInfoUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary changePassword
         * @param {ChangePasswordRequestModel} changePasswordRequestModel changePasswordRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePasswordUsingPOST(changePasswordRequestModel: ChangePasswordRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePasswordUsingPOST(changePasswordRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary requestResetPassword
         * @param {ResetPasswordByEmailRequestModel} resetPasswordRequestModel resetPasswordRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestResetPasswordUsingPOST(resetPasswordRequestModel: ResetPasswordByEmailRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestResetPasswordUsingPOST(resetPasswordRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary resetPassword
         * @param {ResetPasswordRequestModel} resetPasswordRequestModel resetPasswordRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordUsingPOST(resetPasswordRequestModel: ResetPasswordRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordUsingPOST(resetPasswordRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateAccountInfo
         * @param {UserAccountModel} userAccountModel userAccountModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountInfoUsingPOST(userAccountModel: UserAccountModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountInfoUsingPOST(userAccountModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary accountInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInfoUsingGET(options?: any): AxiosPromise<UserAccountModel> {
            return localVarFp.accountInfoUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary changePassword
         * @param {ChangePasswordRequestModel} changePasswordRequestModel changePasswordRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST(changePasswordRequestModel: ChangePasswordRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.changePasswordUsingPOST(changePasswordRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary requestResetPassword
         * @param {ResetPasswordByEmailRequestModel} resetPasswordRequestModel resetPasswordRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPasswordUsingPOST(resetPasswordRequestModel: ResetPasswordByEmailRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.requestResetPasswordUsingPOST(resetPasswordRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary resetPassword
         * @param {ResetPasswordRequestModel} resetPasswordRequestModel resetPasswordRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST(resetPasswordRequestModel: ResetPasswordRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.resetPasswordUsingPOST(resetPasswordRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateAccountInfo
         * @param {UserAccountModel} userAccountModel userAccountModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountInfoUsingPOST(userAccountModel: UserAccountModel, options?: any): AxiosPromise<void> {
            return localVarFp.updateAccountInfoUsingPOST(userAccountModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * 
     * @summary accountInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public accountInfoUsingGET(options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).accountInfoUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary changePassword
     * @param {ChangePasswordRequestModel} changePasswordRequestModel changePasswordRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public changePasswordUsingPOST(changePasswordRequestModel: ChangePasswordRequestModel, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).changePasswordUsingPOST(changePasswordRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary requestResetPassword
     * @param {ResetPasswordByEmailRequestModel} resetPasswordRequestModel resetPasswordRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public requestResetPasswordUsingPOST(resetPasswordRequestModel: ResetPasswordByEmailRequestModel, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).requestResetPasswordUsingPOST(resetPasswordRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary resetPassword
     * @param {ResetPasswordRequestModel} resetPasswordRequestModel resetPasswordRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public resetPasswordUsingPOST(resetPasswordRequestModel: ResetPasswordRequestModel, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).resetPasswordUsingPOST(resetPasswordRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateAccountInfo
     * @param {UserAccountModel} userAccountModel userAccountModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public updateAccountInfoUsingPOST(userAccountModel: UserAccountModel, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).updateAccountInfoUsingPOST(userAccountModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiagnosticsControllerApi - axios parameter creator
 * @export
 */
export const DiagnosticsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary pushLogs
         * @param {PushLogsRequestModel} pushLogsRequestModel pushLogsRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushLogsUsingPOST: async (pushLogsRequestModel: PushLogsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pushLogsRequestModel' is not null or undefined
            assertParamExists('pushLogsUsingPOST', 'pushLogsRequestModel', pushLogsRequestModel)
            const localVarPath = `/api/v1/diagnostics/push-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pushLogsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiagnosticsControllerApi - functional programming interface
 * @export
 */
export const DiagnosticsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiagnosticsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary pushLogs
         * @param {PushLogsRequestModel} pushLogsRequestModel pushLogsRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pushLogsUsingPOST(pushLogsRequestModel: PushLogsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pushLogsUsingPOST(pushLogsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiagnosticsControllerApi - factory interface
 * @export
 */
export const DiagnosticsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiagnosticsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary pushLogs
         * @param {PushLogsRequestModel} pushLogsRequestModel pushLogsRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushLogsUsingPOST(pushLogsRequestModel: PushLogsRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.pushLogsUsingPOST(pushLogsRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiagnosticsControllerApi - object-oriented interface
 * @export
 * @class DiagnosticsControllerApi
 * @extends {BaseAPI}
 */
export class DiagnosticsControllerApi extends BaseAPI {
    /**
     * 
     * @summary pushLogs
     * @param {PushLogsRequestModel} pushLogsRequestModel pushLogsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticsControllerApi
     */
    public pushLogsUsingPOST(pushLogsRequestModel: PushLogsRequestModel, options?: AxiosRequestConfig) {
        return DiagnosticsControllerApiFp(this.configuration).pushLogsUsingPOST(pushLogsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EshopControllerApi - axios parameter creator
 * @export
 */
export const EshopControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createEshop
         * @param {EshopModel} eshopModel eshopModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEshopUsingPOST: async (eshopModel: EshopModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eshopModel' is not null or undefined
            assertParamExists('createEshopUsingPOST', 'eshopModel', eshopModel)
            const localVarPath = `/api/v1/eshops/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eshopModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary eshopsOverview
         * @param {EshopOverviewRequestModel} eshopOverviewRequestModel eshopOverviewRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopsOverviewUsingPOST: async (eshopOverviewRequestModel: EshopOverviewRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eshopOverviewRequestModel' is not null or undefined
            assertParamExists('eshopsOverviewUsingPOST', 'eshopOverviewRequestModel', eshopOverviewRequestModel)
            const localVarPath = `/api/v1/eshops/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eshopOverviewRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getByIdUsingGET', 'id', id)
            const localVarPath = `/api/v1/eshops/by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getHeurekaSetting
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeurekaSettingUsingGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getHeurekaSettingUsingGET', 'id', id)
            const localVarPath = `/api/v1/eshop/{id}/heureka`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getZboziczSetting
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZboziczSettingUsingGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getZboziczSettingUsingGET', 'id', id)
            const localVarPath = `/api/v1/eshop/{id}/zbozicz`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/eshops/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modifyEshop
         * @param {string} id id
         * @param {EshopModel} eshopModel eshopModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyEshopUsingPOST: async (id: string, eshopModel: EshopModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modifyEshopUsingPOST', 'id', id)
            // verify required parameter 'eshopModel' is not null or undefined
            assertParamExists('modifyEshopUsingPOST', 'eshopModel', eshopModel)
            const localVarPath = `/api/v1/eshops/modify/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eshopModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary removeById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeByIdUsingDELETE: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeByIdUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/eshops/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setHeurekaSetting
         * @param {string} id id
         * @param {IntegrationHeurekaSettingModel} integrationHeurekaSettingModel integrationHeurekaSettingModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHeurekaSettingUsingPOST: async (id: string, integrationHeurekaSettingModel: IntegrationHeurekaSettingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setHeurekaSettingUsingPOST', 'id', id)
            // verify required parameter 'integrationHeurekaSettingModel' is not null or undefined
            assertParamExists('setHeurekaSettingUsingPOST', 'integrationHeurekaSettingModel', integrationHeurekaSettingModel)
            const localVarPath = `/api/v1/eshop/{id}/heureka`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationHeurekaSettingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setZboziczSetting
         * @param {string} id id
         * @param {IntegrationZboziczSettingModel} integrationZboziczSettingModel integrationZboziczSettingModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setZboziczSettingUsingPOST: async (id: string, integrationZboziczSettingModel: IntegrationZboziczSettingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setZboziczSettingUsingPOST', 'id', id)
            // verify required parameter 'integrationZboziczSettingModel' is not null or undefined
            assertParamExists('setZboziczSettingUsingPOST', 'integrationZboziczSettingModel', integrationZboziczSettingModel)
            const localVarPath = `/api/v1/eshop/{id}/zbozicz`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationZboziczSettingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EshopControllerApi - functional programming interface
 * @export
 */
export const EshopControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EshopControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createEshop
         * @param {EshopModel} eshopModel eshopModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEshopUsingPOST(eshopModel: EshopModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EshopModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEshopUsingPOST(eshopModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary eshopsOverview
         * @param {EshopOverviewRequestModel} eshopOverviewRequestModel eshopOverviewRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopsOverviewUsingPOST(eshopOverviewRequestModel: EshopOverviewRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResultModelEshopModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopsOverviewUsingPOST(eshopOverviewRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdUsingGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EshopModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getHeurekaSetting
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHeurekaSettingUsingGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationHeurekaSettingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHeurekaSettingUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getZboziczSetting
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZboziczSettingUsingGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationZboziczSettingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZboziczSettingUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary listAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EshopModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary modifyEshop
         * @param {string} id id
         * @param {EshopModel} eshopModel eshopModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyEshopUsingPOST(id: string, eshopModel: EshopModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EshopModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyEshopUsingPOST(id, eshopModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary removeById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeByIdUsingDELETE(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeByIdUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary setHeurekaSetting
         * @param {string} id id
         * @param {IntegrationHeurekaSettingModel} integrationHeurekaSettingModel integrationHeurekaSettingModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setHeurekaSettingUsingPOST(id: string, integrationHeurekaSettingModel: IntegrationHeurekaSettingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setHeurekaSettingUsingPOST(id, integrationHeurekaSettingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary setZboziczSetting
         * @param {string} id id
         * @param {IntegrationZboziczSettingModel} integrationZboziczSettingModel integrationZboziczSettingModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setZboziczSettingUsingPOST(id: string, integrationZboziczSettingModel: IntegrationZboziczSettingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setZboziczSettingUsingPOST(id, integrationZboziczSettingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EshopControllerApi - factory interface
 * @export
 */
export const EshopControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EshopControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary createEshop
         * @param {EshopModel} eshopModel eshopModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEshopUsingPOST(eshopModel: EshopModel, options?: any): AxiosPromise<EshopModel> {
            return localVarFp.createEshopUsingPOST(eshopModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary eshopsOverview
         * @param {EshopOverviewRequestModel} eshopOverviewRequestModel eshopOverviewRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopsOverviewUsingPOST(eshopOverviewRequestModel: EshopOverviewRequestModel, options?: any): AxiosPromise<PageResultModelEshopModel> {
            return localVarFp.eshopsOverviewUsingPOST(eshopOverviewRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET(id: string, options?: any): AxiosPromise<EshopModel> {
            return localVarFp.getByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getHeurekaSetting
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeurekaSettingUsingGET(id: string, options?: any): AxiosPromise<IntegrationHeurekaSettingModel> {
            return localVarFp.getHeurekaSettingUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getZboziczSetting
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZboziczSettingUsingGET(id: string, options?: any): AxiosPromise<IntegrationZboziczSettingModel> {
            return localVarFp.getZboziczSettingUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary listAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllUsingGET(options?: any): AxiosPromise<Array<EshopModel>> {
            return localVarFp.listAllUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary modifyEshop
         * @param {string} id id
         * @param {EshopModel} eshopModel eshopModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyEshopUsingPOST(id: string, eshopModel: EshopModel, options?: any): AxiosPromise<EshopModel> {
            return localVarFp.modifyEshopUsingPOST(id, eshopModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary removeById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeByIdUsingDELETE(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeByIdUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary setHeurekaSetting
         * @param {string} id id
         * @param {IntegrationHeurekaSettingModel} integrationHeurekaSettingModel integrationHeurekaSettingModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHeurekaSettingUsingPOST(id: string, integrationHeurekaSettingModel: IntegrationHeurekaSettingModel, options?: any): AxiosPromise<void> {
            return localVarFp.setHeurekaSettingUsingPOST(id, integrationHeurekaSettingModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary setZboziczSetting
         * @param {string} id id
         * @param {IntegrationZboziczSettingModel} integrationZboziczSettingModel integrationZboziczSettingModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setZboziczSettingUsingPOST(id: string, integrationZboziczSettingModel: IntegrationZboziczSettingModel, options?: any): AxiosPromise<void> {
            return localVarFp.setZboziczSettingUsingPOST(id, integrationZboziczSettingModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EshopControllerApi - object-oriented interface
 * @export
 * @class EshopControllerApi
 * @extends {BaseAPI}
 */
export class EshopControllerApi extends BaseAPI {
    /**
     * 
     * @summary createEshop
     * @param {EshopModel} eshopModel eshopModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopControllerApi
     */
    public createEshopUsingPOST(eshopModel: EshopModel, options?: AxiosRequestConfig) {
        return EshopControllerApiFp(this.configuration).createEshopUsingPOST(eshopModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary eshopsOverview
     * @param {EshopOverviewRequestModel} eshopOverviewRequestModel eshopOverviewRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopControllerApi
     */
    public eshopsOverviewUsingPOST(eshopOverviewRequestModel: EshopOverviewRequestModel, options?: AxiosRequestConfig) {
        return EshopControllerApiFp(this.configuration).eshopsOverviewUsingPOST(eshopOverviewRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getById
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopControllerApi
     */
    public getByIdUsingGET(id: string, options?: AxiosRequestConfig) {
        return EshopControllerApiFp(this.configuration).getByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getHeurekaSetting
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopControllerApi
     */
    public getHeurekaSettingUsingGET(id: string, options?: AxiosRequestConfig) {
        return EshopControllerApiFp(this.configuration).getHeurekaSettingUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getZboziczSetting
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopControllerApi
     */
    public getZboziczSettingUsingGET(id: string, options?: AxiosRequestConfig) {
        return EshopControllerApiFp(this.configuration).getZboziczSettingUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary listAll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopControllerApi
     */
    public listAllUsingGET(options?: AxiosRequestConfig) {
        return EshopControllerApiFp(this.configuration).listAllUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary modifyEshop
     * @param {string} id id
     * @param {EshopModel} eshopModel eshopModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopControllerApi
     */
    public modifyEshopUsingPOST(id: string, eshopModel: EshopModel, options?: AxiosRequestConfig) {
        return EshopControllerApiFp(this.configuration).modifyEshopUsingPOST(id, eshopModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary removeById
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopControllerApi
     */
    public removeByIdUsingDELETE(id: string, options?: AxiosRequestConfig) {
        return EshopControllerApiFp(this.configuration).removeByIdUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary setHeurekaSetting
     * @param {string} id id
     * @param {IntegrationHeurekaSettingModel} integrationHeurekaSettingModel integrationHeurekaSettingModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopControllerApi
     */
    public setHeurekaSettingUsingPOST(id: string, integrationHeurekaSettingModel: IntegrationHeurekaSettingModel, options?: AxiosRequestConfig) {
        return EshopControllerApiFp(this.configuration).setHeurekaSettingUsingPOST(id, integrationHeurekaSettingModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary setZboziczSetting
     * @param {string} id id
     * @param {IntegrationZboziczSettingModel} integrationZboziczSettingModel integrationZboziczSettingModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopControllerApi
     */
    public setZboziczSettingUsingPOST(id: string, integrationZboziczSettingModel: IntegrationZboziczSettingModel, options?: AxiosRequestConfig) {
        return EshopControllerApiFp(this.configuration).setZboziczSettingUsingPOST(id, integrationZboziczSettingModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrationControllerApi - axios parameter creator
 * @export
 */
export const IntegrationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createEshop
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEshopUsingGET: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('createEshopUsingGET', 'code', code)
            const localVarPath = `/api/v1/integration/shoptet/oauthtoken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationControllerApi - functional programming interface
 * @export
 */
export const IntegrationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createEshop
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEshopUsingGET(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEshopUsingGET(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationControllerApi - factory interface
 * @export
 */
export const IntegrationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary createEshop
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEshopUsingGET(code: string, options?: any): AxiosPromise<void> {
            return localVarFp.createEshopUsingGET(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationControllerApi - object-oriented interface
 * @export
 * @class IntegrationControllerApi
 * @extends {BaseAPI}
 */
export class IntegrationControllerApi extends BaseAPI {
    /**
     * 
     * @summary createEshop
     * @param {string} code code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationControllerApi
     */
    public createEshopUsingGET(code: string, options?: AxiosRequestConfig) {
        return IntegrationControllerApiFp(this.configuration).createEshopUsingGET(code, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MainControllerApi - axios parameter creator
 * @export
 */
export const MainControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addUserRequirements
         * @param {UserRequirementsModel} userRequirements userRequirements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserRequirementsUsingPOST: async (userRequirements: UserRequirementsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequirements' is not null or undefined
            assertParamExists('addUserRequirementsUsingPOST', 'userRequirements', userRequirements)
            const localVarPath = `/api/v1/add-user-requirements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequirements, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addUserRequirementsWithoutLogin
         * @param {UserRequirementsModel} userRequirements userRequirements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserRequirementsWithoutLoginUsingPOST: async (userRequirements: UserRequirementsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequirements' is not null or undefined
            assertParamExists('addUserRequirementsWithoutLoginUsingPOST', 'userRequirements', userRequirements)
            const localVarPath = `/api/v1/without-login/add-user-requirements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequirements, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary authByEmail
         * @param {CredentialModel} credential credential
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authByEmailUsingPOST: async (credential: CredentialModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'credential' is not null or undefined
            assertParamExists('authByEmailUsingPOST', 'credential', credential)
            const localVarPath = `/api/v1/auth/by-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary authInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authInfoUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary eventsList
         * @param {EventsListRequestModel} eventsListRequestModel eventsListRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsListUsingPOST: async (eventsListRequestModel: EventsListRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventsListRequestModel' is not null or undefined
            assertParamExists('eventsListUsingPOST', 'eventsListRequestModel', eventsListRequestModel)
            const localVarPath = `/api/v1/events/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventsListRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPortalConfiguration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalConfigurationUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/portal-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MainControllerApi - functional programming interface
 * @export
 */
export const MainControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MainControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary addUserRequirements
         * @param {UserRequirementsModel} userRequirements userRequirements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserRequirementsUsingPOST(userRequirements: UserRequirementsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserRequirementsUsingPOST(userRequirements, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary addUserRequirementsWithoutLogin
         * @param {UserRequirementsModel} userRequirements userRequirements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserRequirementsWithoutLoginUsingPOST(userRequirements: UserRequirementsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserRequirementsWithoutLoginUsingPOST(userRequirements, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary authByEmail
         * @param {CredentialModel} credential credential
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authByEmailUsingPOST(credential: CredentialModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authByEmailUsingPOST(credential, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary authInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authInfoUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authInfoUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary eventsList
         * @param {EventsListRequestModel} eventsListRequestModel eventsListRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventsListUsingPOST(eventsListRequestModel: EventsListRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResultModelLogModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventsListUsingPOST(eventsListRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getPortalConfiguration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortalConfigurationUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortalConfigurationUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MainControllerApi - factory interface
 * @export
 */
export const MainControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MainControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary addUserRequirements
         * @param {UserRequirementsModel} userRequirements userRequirements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserRequirementsUsingPOST(userRequirements: UserRequirementsModel, options?: any): AxiosPromise<MessageModel> {
            return localVarFp.addUserRequirementsUsingPOST(userRequirements, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary addUserRequirementsWithoutLogin
         * @param {UserRequirementsModel} userRequirements userRequirements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserRequirementsWithoutLoginUsingPOST(userRequirements: UserRequirementsModel, options?: any): AxiosPromise<MessageModel> {
            return localVarFp.addUserRequirementsWithoutLoginUsingPOST(userRequirements, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary authByEmail
         * @param {CredentialModel} credential credential
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authByEmailUsingPOST(credential: CredentialModel, options?: any): AxiosPromise<AuthInfoModel> {
            return localVarFp.authByEmailUsingPOST(credential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary authInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authInfoUsingGET(options?: any): AxiosPromise<AuthInfoModel> {
            return localVarFp.authInfoUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary eventsList
         * @param {EventsListRequestModel} eventsListRequestModel eventsListRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsListUsingPOST(eventsListRequestModel: EventsListRequestModel, options?: any): AxiosPromise<PageResultModelLogModel> {
            return localVarFp.eventsListUsingPOST(eventsListRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPortalConfiguration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalConfigurationUsingGET(options?: any): AxiosPromise<ConfigurationModel> {
            return localVarFp.getPortalConfigurationUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUsingGET(options?: any): AxiosPromise<void> {
            return localVarFp.logoutUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MainControllerApi - object-oriented interface
 * @export
 * @class MainControllerApi
 * @extends {BaseAPI}
 */
export class MainControllerApi extends BaseAPI {
    /**
     * 
     * @summary addUserRequirements
     * @param {UserRequirementsModel} userRequirements userRequirements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainControllerApi
     */
    public addUserRequirementsUsingPOST(userRequirements: UserRequirementsModel, options?: AxiosRequestConfig) {
        return MainControllerApiFp(this.configuration).addUserRequirementsUsingPOST(userRequirements, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary addUserRequirementsWithoutLogin
     * @param {UserRequirementsModel} userRequirements userRequirements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainControllerApi
     */
    public addUserRequirementsWithoutLoginUsingPOST(userRequirements: UserRequirementsModel, options?: AxiosRequestConfig) {
        return MainControllerApiFp(this.configuration).addUserRequirementsWithoutLoginUsingPOST(userRequirements, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary authByEmail
     * @param {CredentialModel} credential credential
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainControllerApi
     */
    public authByEmailUsingPOST(credential: CredentialModel, options?: AxiosRequestConfig) {
        return MainControllerApiFp(this.configuration).authByEmailUsingPOST(credential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary authInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainControllerApi
     */
    public authInfoUsingGET(options?: AxiosRequestConfig) {
        return MainControllerApiFp(this.configuration).authInfoUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary eventsList
     * @param {EventsListRequestModel} eventsListRequestModel eventsListRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainControllerApi
     */
    public eventsListUsingPOST(eventsListRequestModel: EventsListRequestModel, options?: AxiosRequestConfig) {
        return MainControllerApiFp(this.configuration).eventsListUsingPOST(eventsListRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPortalConfiguration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainControllerApi
     */
    public getPortalConfigurationUsingGET(options?: AxiosRequestConfig) {
        return MainControllerApiFp(this.configuration).getPortalConfigurationUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainControllerApi
     */
    public logoutUsingGET(options?: AxiosRequestConfig) {
        return MainControllerApiFp(this.configuration).logoutUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegistrationControllerApi - axios parameter creator
 * @export
 */
export const RegistrationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary registrationByEmail
         * @param {EmailRegistrationModel} userModel userModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationByEmailUsingPOST: async (userModel: EmailRegistrationModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userModel' is not null or undefined
            assertParamExists('registrationByEmailUsingPOST', 'userModel', userModel)
            const localVarPath = `/api/v1/registration/by-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistrationControllerApi - functional programming interface
 * @export
 */
export const RegistrationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary registrationByEmail
         * @param {EmailRegistrationModel} userModel userModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registrationByEmailUsingPOST(userModel: EmailRegistrationModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registrationByEmailUsingPOST(userModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistrationControllerApi - factory interface
 * @export
 */
export const RegistrationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistrationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary registrationByEmail
         * @param {EmailRegistrationModel} userModel userModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationByEmailUsingPOST(userModel: EmailRegistrationModel, options?: any): AxiosPromise<MessageModel> {
            return localVarFp.registrationByEmailUsingPOST(userModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrationControllerApi - object-oriented interface
 * @export
 * @class RegistrationControllerApi
 * @extends {BaseAPI}
 */
export class RegistrationControllerApi extends BaseAPI {
    /**
     * 
     * @summary registrationByEmail
     * @param {EmailRegistrationModel} userModel userModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationControllerApi
     */
    public registrationByEmailUsingPOST(userModel: EmailRegistrationModel, options?: AxiosRequestConfig) {
        return RegistrationControllerApiFp(this.configuration).registrationByEmailUsingPOST(userModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReviewControllerApi - axios parameter creator
 * @export
 */
export const ReviewControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary reviewById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewByIdUsingGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewByIdUsingGET', 'id', id)
            const localVarPath = `/api/v1/reviews/by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reviewsOverview
         * @param {ReviewOverviewRequestModel} reviewOverviewRequestModel reviewOverviewRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewsOverviewUsingPOST: async (reviewOverviewRequestModel: ReviewOverviewRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewOverviewRequestModel' is not null or undefined
            assertParamExists('reviewsOverviewUsingPOST', 'reviewOverviewRequestModel', reviewOverviewRequestModel)
            const localVarPath = `/api/v1/reviews/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewOverviewRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sendAnswer
         * @param {ReviewAnswerModel} answerModel answerModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAnswerUsingPUT: async (answerModel: ReviewAnswerModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'answerModel' is not null or undefined
            assertParamExists('sendAnswerUsingPUT', 'answerModel', answerModel)
            const localVarPath = `/api/v1/reviews/answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answerModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setRead
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReadUsingPOST: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setReadUsingPOST', 'id', id)
            const localVarPath = `/api/v1/reviews/set-read/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setUnread
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUnreadUsingPOST: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setUnreadUsingPOST', 'id', id)
            const localVarPath = `/api/v1/reviews/set-unread/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-AuthKey", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewControllerApi - functional programming interface
 * @export
 */
export const ReviewControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary reviewById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewByIdUsingGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary reviewsOverview
         * @param {ReviewOverviewRequestModel} reviewOverviewRequestModel reviewOverviewRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewsOverviewUsingPOST(reviewOverviewRequestModel: ReviewOverviewRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResultModelReviewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewsOverviewUsingPOST(reviewOverviewRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sendAnswer
         * @param {ReviewAnswerModel} answerModel answerModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAnswerUsingPUT(answerModel: ReviewAnswerModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendAnswerUsingPUT(answerModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary setRead
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setReadUsingPOST(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setReadUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary setUnread
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUnreadUsingPOST(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUnreadUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReviewControllerApi - factory interface
 * @export
 */
export const ReviewControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary reviewById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewByIdUsingGET(id: string, options?: any): AxiosPromise<ReviewModel> {
            return localVarFp.reviewByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reviewsOverview
         * @param {ReviewOverviewRequestModel} reviewOverviewRequestModel reviewOverviewRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewsOverviewUsingPOST(reviewOverviewRequestModel: ReviewOverviewRequestModel, options?: any): AxiosPromise<PageResultModelReviewModel> {
            return localVarFp.reviewsOverviewUsingPOST(reviewOverviewRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sendAnswer
         * @param {ReviewAnswerModel} answerModel answerModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAnswerUsingPUT(answerModel: ReviewAnswerModel, options?: any): AxiosPromise<MessageModel> {
            return localVarFp.sendAnswerUsingPUT(answerModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary setRead
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReadUsingPOST(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.setReadUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary setUnread
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUnreadUsingPOST(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.setUnreadUsingPOST(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewControllerApi - object-oriented interface
 * @export
 * @class ReviewControllerApi
 * @extends {BaseAPI}
 */
export class ReviewControllerApi extends BaseAPI {
    /**
     * 
     * @summary reviewById
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public reviewByIdUsingGET(id: string, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).reviewByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reviewsOverview
     * @param {ReviewOverviewRequestModel} reviewOverviewRequestModel reviewOverviewRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public reviewsOverviewUsingPOST(reviewOverviewRequestModel: ReviewOverviewRequestModel, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).reviewsOverviewUsingPOST(reviewOverviewRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sendAnswer
     * @param {ReviewAnswerModel} answerModel answerModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public sendAnswerUsingPUT(answerModel: ReviewAnswerModel, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).sendAnswerUsingPUT(answerModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary setRead
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public setReadUsingPOST(id: string, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).setReadUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary setUnread
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public setUnreadUsingPOST(id: string, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).setUnreadUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }
}


