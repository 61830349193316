import { pullAllBy } from "lodash";
import { call, fork, take, takeEvery, put, select } from "redux-saga/effects";
import { delay } from "@redux-saga/core/effects";
import AccountActions from "../account/actionTypes";
import EshopActions from "../eshop/actionTypes";
import ForgetPasswordActions from "../auth/forgetpwd/actionTypes";
import LoginActions from "../auth/login/actionTypes";
import RegisterActions from "../auth/register/actionTypes";
import {
  addFailed,
  addLoading,
  addSuccesfull,
  clearResults,
  removeLoading,
} from "./actions";
import IntegrationActions from "../../store/integration/actionTypes";
import FeedbacksActions from "../../store/feedbacks/actionTypes";

import ConfigurationActions from "../configuration/actionTypes";
import logger from "../../utils/logger";
import { IReduxAction } from "../../models/common/saga";
import { RootState } from "..";

// old way
const observedActions: string[] = [
  AccountActions.EDIT_ACCOUNT,
  AccountActions.EDIT_PASSWORD,
  AccountActions.GET_ACCOUNT,
  AccountActions.RESET_PASSWORD,
  LoginActions.LOGIN_USER,
  RegisterActions.REGISTER_USER_BY_EMAIL,
  ForgetPasswordActions.FORGET_PASSWORD,
  EshopActions.CONNECT_ESHOP,
  EshopActions.DELETE_ESHOP_BY_ID,
  EshopActions.EDIT_ESHOP_BY_ID,
  EshopActions.GET_ESHOPS_OVERVIEW,
  // IntegrationActions.SET_HEUREKA_SETTINGS,
  // IntegrationActions.GET_HEUREKA_SETTINGS,
  // IntegrationActions.GET_ZBOZI_SETTINGS,
  // IntegrationActions.SET_ZBOZI_SETTINGS,
  // ConfigurationActions.GET_PORTAL_CONFIGURATION,
  // FeedbacksActions.SEND_FEEDBACK_RESPONSE,
  // FeedbacksActions.GET_FEEDBACKS_OVERVIEW,
  // FeedbacksActions.GET_FEEDBACKS_LATEST,
  // FeedbacksActions.GET_FEEDBACK_BY_ID,
];

export function* takexSaga(pattern: RegExp) {
  let action: IReduxAction<string, any>;
  while (true) {
    action = yield take("*");
    // console.log("takex", action.type);
    if (pattern.test(action.type)) {
      //   console.log("takex:match", action.type);
      break;
    }
    // console.log("takex:ignore", action.type);
  }
  return action;
}

export function takex(pattern: RegExp) {
  return call(takexSaga, pattern);
}

export function* handleServerFailedActions() {
  while (true) {
    const action: IReduxAction<string, any> = yield takex(/.*_FAIL$/);

    const current: string[] = yield select(
      (state: RootState) => state.Loading.current
    );

    if (current.includes(action.type.replace("_FAIL", ""))) {
    // if (observedActions.includes(action.type.replace("_FAIL", ""))) {
      // console.log("handle", action.type);
      yield put(removeLoading(action));
      yield put(addFailed(action));
    }
  }
}

export function* handleServerSuccessActions() {
  while (true) {
    const action: IReduxAction<string, any> = yield takex(/.*_SUCCESS$/);
    // console.log("handle", action.type);

    const current: string[] = yield select(
      (state: RootState) => state.Loading.current
    );

    if (current.includes(action.type.replace("_SUCCESS", ""))) {
      yield put(removeLoading(action));
      yield put(addSuccesfull(action));
    }
  }
}

// for logger
export function* handleAllServerFailedActions() {
  while (true) {
    const action: IReduxAction<string, any> = yield takex(/.*_FAIL$/);
    logger.error("REDUX ACTION ERROR: " + JSON.stringify(action));
  }
}

function* handleActionStart(action: IReduxAction<string, any>) {
  yield put(addLoading(action));
  yield put(clearResults(action.type));
}

// new way
function* handleActionStartNew() {
  while (true) {
    const action: IReduxAction<string, any> = yield takex(/.*_REQUEST$/);
    yield put(addLoading(action));
    yield put(clearResults(action.type));
  }
}

export default function* handleServerActionsSaga() {
  yield fork(handleServerFailedActions);
  yield fork(handleServerSuccessActions);
  yield fork(handleAllServerFailedActions);
  yield fork(handleActionStartNew);
  yield takeEvery(observedActions, handleActionStart);
}
