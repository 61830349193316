import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect, RouteProps } from "react-router-dom";
import * as authStorage from "../helpers/authStorage";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}: Props) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !authStorage.getAuth()) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);
interface IOwnProps {
  isAuthProtected: boolean;
  component: React.Component;
  location: any;
  layout: any;
}
type Props = RouteProps & IOwnProps;


export default AppRoute;
