enum ServiceEnum {
  ZBOZICZ = "ZBOZICZ",
  HEUREKA = "HEUREKA",
  ESHOP = "ESHOP",
}

export const getServiceEnumFromString = (input: string) : ServiceEnum => {
  const typedColorString = input as keyof typeof ServiceEnum;
  return ServiceEnum[typedColorString];
}

export default ServiceEnum;
