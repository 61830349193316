import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import { connect, ConnectedProps } from "react-redux";
import FeedbackPanel from "./FeedbackPanel";
import { useHistory, useParams } from "react-router";
import FeedbacksPaging from "./FeedbacksPaging";
import FeedbackCard from "./FeedbackCard";

import styled from "styled-components";
import LoadingAware from "../../components/Common/LoadingAware";
import Skeleton from "react-loading-skeleton";
import FeedbackActions from "../../store/feedbacks/actionTypes";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";

const PagingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const PanelCardWrapper = styled.div`
  position: relative;
  bottom: 1.25rem;
`;

const Placeholder = () => (
  <React.Fragment>
    <Skeleton count={5} height={130} className={"mb-4"}></Skeleton>
  </React.Fragment>
);

const Feedback = ({ feedbacks }: Props) => {
  const { feedbackId } = useParams<{feedbackId: string}>();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (feedbackId != null) return;
    if (feedbacks == null || feedbacks.length == 0) return;
    history.push("/feedbacks/" + feedbacks[0].id);
  }, [feedbacks, feedbackId]);

  return (
    <React.Fragment>
      <Row>
        <Col md="5">
          <Card>
            <CardBody>
              <LoadingAware
                actiontypes={[FeedbackActions.GET_FEEDBACKS_OVERVIEW]}
                placeholder={<Placeholder />}
              >
                {(feedbacks != null && feedbacks.length > 0)
                    ? (<React.Fragment>{feedbacks.map(feedback => (<FeedbackCard key={feedback.id} feedback={feedback} />))}<PagingContainer><FeedbacksPaging /></PagingContainer></React.Fragment>)
                    : (<React.Fragment>{t("Feedbacks.no_review_avaliable")}</React.Fragment>)
                }
              </LoadingAware>
            </CardBody>
          </Card>
        </Col>
        <Col md="7">
          {(feedbacks != null && feedbacks.length > 0) && (
          <PanelCardWrapper>
            <Card>
              <CardBody>
                <FeedbackPanel />
              </CardBody>
            </Card>
          </PanelCardWrapper>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

const connector = connect(
  (state : RootState) => ({
    feedbacks: state.Feedbacks.overview.data,
  }),
  {}
)

type ReduxProps = ConnectedProps<typeof connector>
type Props = ReduxProps;
export default connector(Feedback);
