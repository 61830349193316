import * as authStorage from "../authStorage";

export default function authHeader() {
  const auth = authStorage.getAuth();
  if (!auth) return {};
  try {
    return { "X-AuthKey": auth.authKey };
  } catch {
    return {};
  }
}
