import React, { Component } from "react";

import GoogleLoginComponent from "../../components/Google/GoogleLogin";

import classes from "./integration.module.css";

const GooMojeFirmaTab = () => {
    return (
      <React.Fragment>
        <div className={classes.elements__on_sides}>
          <h5 style={{ marginBottom: "30px" }}>
            Připojte váš firemní profil na Google
          </h5>
          <img src="./integration/logos/g-moje-firma-logo-big.png" />
        </div>
        <div className={classes.center}>
          <GoogleLoginComponent />
        </div>
      </React.Fragment>
    );
  }
export default GooMojeFirmaTab;
