import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import RegisterAccount from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Feedbacks from "./feedbacks/reducer";
import EditAccount from "./account/reducer";
import AuthInfo from "./auth/authInfo/reducer";
import Loading from "./loading/reducer";
import Eshop from "./eshop/reducer";
import Events from "./events/reducer"
import Integration from "./integration/reducer";
import Configuration from "./configuration/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  AuthInfo,
  Login,
  RegisterAccount,
  ForgetPassword,
  Feedbacks,
  EditAccount,
  Loading,
  Eshop,
  Events,
  Integration,
  Configuration,
});

export default rootReducer;
