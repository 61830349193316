import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getFeedbacksOverview,
  changeFeedbacksOverviewPaging,
} from "../../store/feedbacks/actions";

import Paging from "../../components/Common/Paging";
import useAdaloTable from "../../customHooks/useAdaloTable";

import TablePaging from "../../components/Common/TablePaging";
import { RootState } from "../../store";

export default connect(
  (state : RootState) => ({
    // feedbacks: state.Feedbacks.overview.data,
    paging: state.Feedbacks.overview.page || {},
    totalItems: state.Feedbacks.overview.total,
  }),
  {
    refreshData: getFeedbacksOverview,
    changePaging: changeFeedbacksOverviewPaging,
  }
)(TablePaging);
