import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

interface IProps {
  onGotoFirst: () => void;
  onGotoPrev: () => void;
  onGotoLast: () => void;
  onGotoNext: () => void;
  page: number;
  maxPage: number;
}

const Paging = ({
  onGotoFirst,
  onGotoPrev,
  onGotoLast,
  onGotoNext,
  page,
  maxPage,
}: IProps) => {
  return maxPage > 0 ? (
    <Pagination aria-label="Page navigation">
      <PaginationItem>
        <PaginationLink first onClick={onGotoFirst} />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink previous onClick={onGotoPrev} />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink>
          {page} z {maxPage}
        </PaginationLink>
      </PaginationItem>
      <PaginationItem>
        <PaginationLink next onClick={onGotoNext} />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink last onClick={onGotoLast} />
      </PaginationItem>
    </Pagination>
  ) : null;
};

export default Paging;
