import { MessageModel, PageModel, PageResultModelReviewModel, ReviewModel, ReviewFilterModel, ReviewAnswerModel } from "../../models/common/fromGenerated";
import { reduxAction, reduxActionBasic } from "../../models/common/saga";
import { ApiError } from "../../utils/errorUtils";
import actionTypes from "./actionTypes";



export const getFeedbacksOverview = () =>
  reduxActionBasic(actionTypes.GET_FEEDBACKS_OVERVIEW);

export const getFeedbacksOverviewSuccess = (payload : PageResultModelReviewModel) =>
  reduxAction(actionTypes.GET_FEEDBACKS_OVERVIEW_SUCCESS, payload);

export const getFeedbacksOverviewFail = (error: ApiError) =>
  reduxAction(actionTypes.GET_FEEDBACKS_OVERVIEW_FAIL, error);

export const changeFeedbacksOverviewPaging = (payload : PageModel) =>
  reduxAction(actionTypes.CHANGE_FEEDBACKS_OVERVIEW_PAGING, payload);

export const getFeedbacksLatest = () =>
  reduxActionBasic(actionTypes.GET_FEEDBACKS_LATEST);

export const getFeedbacksLatesSuccess = (payload : PageResultModelReviewModel) =>
  reduxAction(actionTypes.GET_FEEDBACKS_LATEST_SUCCESS, payload);

export const getFeedbacksLatestFail = (error : ApiError) =>
  reduxAction(actionTypes.GET_FEEDBACKS_LATEST_FAIL, error);

export const changeFeedbacksLatestPaging = (payload : PageModel) =>
  reduxAction(actionTypes.CHANGE_FEEDBACKS_LATEST_PAGING, payload);

export const getFeedbackById = (id: string) =>
  reduxAction(actionTypes.GET_FEEDBACK_BY_ID, id);

export const getFeedbackByIdFail = (error : ApiError) =>
  reduxAction(actionTypes.GET_FEEDBACK_BY_ID_FAIL, error);

export const getFeedbackByIdSuccess = (payload : ReviewModel) =>
  reduxAction(actionTypes.GET_FEEDBACK_BY_ID_SUCCESS, payload);

export const changeFeedbacksOverviewFilter = (payload : ReviewFilterModel)  =>
  reduxAction(actionTypes.CHANGE_FEEDBACKS_OVERVIEW_FILTER, payload);

export const sendFeedbackResponse = (payload : ReviewAnswerModel) =>
  reduxAction(actionTypes.SEND_FEEDBACK_RESPONSE, payload);

export const sendFeedbackResponseSuccess = (payload : MessageModel) =>
  reduxAction(actionTypes.SEND_FEEDBACK_RESPONSE_SUCCESS, payload);

export const sendFeedbackResponseFail = (error: ApiError) =>
  reduxAction(actionTypes.SEND_FEEDBACK_RESPONSE_FAIL, error);

export const syncOverviewWithLatest = () =>
  reduxActionBasic(actionTypes.SYNC_FILTERS_PAGING_FROM_LATEST_TO_OVERVIEW);

export const setReadFeedback = (payload: string) =>
  reduxAction(actionTypes.SET_READ_FEEDBACK_BY_ID, payload);

export const setReadFeedbackSuccess = () =>
  reduxActionBasic(actionTypes.SET_READ_FEEDBACK_BY_ID_SUCCESS);

export const setReadFeedbackFail = (payload : ApiError) =>
  reduxAction(actionTypes.SET_READ_FEEDBACK_BY_ID_FAIL, payload);

  

export type GetFeedbacksOverviewAction = ReturnType<typeof getFeedbacksOverview>
export type GetFeedbacksOverviewSuccessAction = ReturnType<typeof getFeedbacksOverviewSuccess>
export type GetFeedbacksOverviewFailAction = ReturnType<typeof getFeedbacksOverviewFail>
export type ChangeFeedbacksOverviewPagingAction = ReturnType<typeof changeFeedbacksOverviewPaging>
export type GetFeedbacksLatestAction = ReturnType<typeof getFeedbacksLatest>
export type GetFeedbacksLatesSuccessAction = ReturnType<typeof getFeedbacksLatesSuccess>
export type GetFeedbacksLatestFailAction = ReturnType<typeof getFeedbacksLatestFail>
export type ChangeFeedbacksLatestPagingAction = ReturnType<typeof changeFeedbacksLatestPaging>
export type GetFeedbackByIdAction = ReturnType<typeof getFeedbackById>
export type GetFeedbackByIdFailAction = ReturnType<typeof getFeedbackByIdFail>
export type GetFeedbackByIdSuccessAction = ReturnType<typeof getFeedbackByIdSuccess>
export type ChangeFeedbacksOverviewFilterAction = ReturnType<typeof changeFeedbacksOverviewFilter>
export type SendFeedbackResponseAction = ReturnType<typeof sendFeedbackResponse>
export type SendFeedbackResponseSuccessAction = ReturnType<typeof sendFeedbackResponseSuccess>
export type SendFeedbackResponseFailAction = ReturnType<typeof sendFeedbackResponseFail>
export type SyncOverviewWithLatestAction = ReturnType<typeof syncOverviewWithLatest>
export type SetReadFeedbackAction = ReturnType<typeof setReadFeedback>
export type SetReadFeedbackSuccessAction = ReturnType<typeof setReadFeedbackSuccess>
export type SetReadFeedbackFailAction = ReturnType<typeof setReadFeedbackFail>

