import React, { useState } from "react";
import { useTranslation } from "react-i18next";
//import EventsActions from "../../../store/events/actionTypes";
import LoadingResultAlert from "../../../components/Common/LoadingResultAlert";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import PropTypes from "prop-types";

import classes from "../../IntegrationPage/integration.module.css";
import ConnectEshop from "../ConnectTab/ConnectEshopTab";
import EventsTable from "./EventsTable";
import { useHistory, useRouteMatch } from "react-router";
import { getSystemErrorMap } from "util";
import { RootState } from "../../../store";


const EventsTab = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <React.Fragment>
      <h5 style={{ marginBottom: "30px" }}>{t("Events.title")}</h5>

      <EventsTable />

    </React.Fragment>
  );
};

export default connect((state: RootState) => ({}), {})(EventsTab);
