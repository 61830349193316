import React, { Component } from "react";

import FeedbackDashboard from "./FeedbackDashboard";

class LatestFeedbacks extends Component {
  render() {
    return (
      <React.Fragment>
        <FeedbackDashboard />
      </React.Fragment>
    );
  }
}

export default LatestFeedbacks;
