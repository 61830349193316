import React, { useState, useMemo, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  getEshopsOverview,
  deleteEshopById,
  changeEshopsOverviewPaging,
} from "../../../store/eshop/actions";
import { Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import PropTypes from "prop-types";
import { Column, TableCellProps, useTable, UseTableCellProps } from "react-table";
import { ActionCell, EshopTypeCell } from "./Cells";
import useAdaloTable from "../../../customHooks/useAdaloTable";
import LoadingResultAlert from "../../../components/Common/LoadingResultAlert";
import EshopActions from "../../../store/eshop/actionTypes";
import { useTranslation } from "react-i18next";
import Paging from "../../../components/Common/Paging";
import LoadingAware from "../../../components/Common/LoadingAware";
import Skeleton from "react-loading-skeleton";
import { RootState } from "../../../store";
import { EshopModel } from "../../../models/common/fromGenerated";

const TextCell = ({ value } : UseTableCellProps<ColumnData, string>) => {
  return (
    <p className="text-truncate" title={value}>
      {value}
    </p>
  );
};

const Placeholder = () => (
  <React.Fragment>
    <Skeleton count={1} height={35} className={"mt-4 mb-5"} />
    <Skeleton count={5} height={60} className={"mb-2"}></Skeleton>
  </React.Fragment>
);

type Props = ReduxProps;

export interface ColumnData {
  url: string;
  name: string;
  id: string;
  type: string;
  deleteEshopById: (id: string) => any
}

const EshopTable = ({
  eshops,
  getEshopsOverview,
  paging,
  changeEshopsOverviewPaging,
  totalItems,
  deleteEshopById,
}: Props) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t("EShop.table.name"),
        accessor: "name" as string,
        Cell: TextCell,
      },
      {
        Header: t("EShop.table.url"),
        accessor: "url" as string,
        Cell: TextCell,
      },
      {
        Header: t("EShop.table.type"),
        accessor: "type" as string,
        Cell: EshopTypeCell,
      },
      {
        Header: "Akce",
        Cell: ActionCell,
      },
    ] as Column<ColumnData>[],
    []
  );

  const data = useMemo(() => {
    return eshops.map(e => {
      const converted: ColumnData = {
        url: e.url || "",
        type: e.type || "",
        id: e.id || "",
        name: e.name || "",
        deleteEshopById: deleteEshopById
      };
      return converted;
    });
  }, [eshops]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const {
    handleGotoFirst,
    handleGotoLast,
    handleGotoPrev,
    handleGotoNext,
    maxPage,
  } = useAdaloTable({
    totalItems,
    paging,
    onRefreshData: getEshopsOverview,
    onChangePaging: changeEshopsOverviewPaging,
  });

  return (
    <React.Fragment>
      <LoadingAware
        actiontypes={[EshopActions.GET_ESHOPS_OVERVIEW]}
        placeholder={<Placeholder />}
      >
        {eshops.length > 0 && (
          <React.Fragment>
            <h5 className={"mb-3 mt-5"}>{t("EShop.table.title")}</h5>
            <LoadingResultAlert actiontype={EshopActions.DELETE_ESHOP_BY_ID} />
            <Table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <td
                            style={{ maxWidth: 0 }}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </React.Fragment>
        )}
      </LoadingAware>
      <div>
        <Paging
          onGotoFirst={handleGotoFirst}
          onGotoNext={handleGotoNext}
          onGotoPrev={handleGotoPrev}
          onGotoLast={handleGotoLast}
          page={(paging?.page || 0) + 1}
          maxPage={maxPage + 1}
        />
      </div>
    </React.Fragment>
  );
};

const connector = connect(
  (state: RootState) => ({
    eshops: state.Eshop.eshopsOverview.data,
    paging: state.Eshop.eshopsOverview.page || {},
    totalItems: state.Eshop.eshopsOverview.total || 0,
  }),
  {
    getEshopsOverview,
    deleteEshopById,
    changeEshopsOverviewPaging,
  }
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(EshopTable);
