import EAction from "./actionTypes";
import {
  RegisterUserFailedAction,
  RegisterUserSuccessfullAction,
  RegisterUserAction,
  RegisterUserValidationFailedAction,
} from "./actions";
import { MessageModel } from "../../../models/common/fromGenerated";
import { IValidationError } from "../../../models/common/saga";

interface IReduxState {
  registrationError: IValidationError | null;
  message: MessageModel | null;
  loading: boolean;
  data: any;
  error: Error | null;
}

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  data: null,
} as IReduxState;

type Action =
  | RegisterUserAction
  | RegisterUserFailedAction
  | RegisterUserSuccessfullAction
  | RegisterUserValidationFailedAction;

const account = (state = initialState, action: Action) => {
  switch (action.type) {
    case EAction.REGISTER_USER_BY_EMAIL:
      state = {
        ...state,
        data: null,
        loading: true,
        registrationError: null,
      };
      break;
    case EAction.REGISTER_USER_BY_EMAIL_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        data: action.payload,
        registrationError: null,
        error: null,
      };
      break;
    case EAction.REGISTER_USER_BY_EMAIL_FAILED:
      state = {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
      break;
    case EAction.REGISTER_USER_BY_EMAIL_VALIDATION_FAIL:
      state = {
        ...state,
        data: null,
        loading: false,
        registrationError: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default account;
