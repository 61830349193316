import React, { useEffect } from "react";
import { PageModel } from "../models/common/fromGenerated";

const useAdaloTable = ({
  totalItems,
  paging,
  onRefreshData,
  onChangePaging,
} : IArgs) => {
  const maxPage = Math.ceil((totalItems - paging.recordPerPage!)/ paging.recordPerPage!); // zacina od 0

  // console.log(totalItems, paging.recordPerPage, maxPage);

  useEffect(() => {
    onRefreshData();
  }, []);

  const handleGotoFirst = () => {
    onChangePaging({
      ...paging,
      page: 0,
    });
  };

  const handleGotoNext = () => {
    onChangePaging({
      ...paging,
      page: Math.min(maxPage, paging.page! + 1),
    });
  };

  const handleGotoPrev = () => {
    onChangePaging({
      ...paging,
      page: Math.max(0, paging.page! - 1),
    });
  };

  const handleGotoLast = () => {
    onChangePaging({
      ...paging,
      page: maxPage,
    });
  };
  return {
    handleGotoFirst,
    handleGotoLast,
    handleGotoPrev,
    handleGotoNext,
    maxPage
  };
};

interface IArgs {
  onRefreshData: () => void;
  onChangePaging: (page: PageModel) => void;
  paging: PageModel;
  totalItems: number;
}

export default useAdaloTable;
