import { AxiosError } from "axios";
import { ErrorModel, ErrorValidateModel } from "../../models/common/fromGenerated";

export interface IReduxAction<Type, PayloadType>
  extends ISimpleReduxAction<Type> {
  payload: PayloadType;
}

export interface ISimpleReduxAction<Type> {
  type: Type;
}

export function reduxAction<Type extends string, PayloadType>(
  type: Type,
  payload: PayloadType
): IReduxAction<Type, PayloadType> {
  return {
    type,
    payload,
  };
}

export function reduxActionBasic<Type extends string>(
  type: Type,
): ISimpleReduxAction<Type> {
  return {
    type
  };
}


export interface IValidationError extends ErrorValidateModel {
  requestData: any;
  isValidationError: boolean;
}

export interface IServerError extends ErrorModel {
  isServerError: boolean
}

export type ErrorFromApi = IServerError | IValidationError | Error

export interface IApiError extends Error {}

export function isAxiosError(error: any): error is AxiosError {
  if(error == null) return false
  return error.isAxiosError === true;
}

export function isValidationError(error: any): error is IValidationError {
  if(error == null) return false
  return error.isValidationError === true;
}

export function isServerError(error: any): error is IServerError {
  if(error == null) return false
  return error.isServerError === true;
}
