import { AxiosError } from "axios";
import {
  isAxiosError,
  IServerError,
  IValidationError,
} from "../models/common/saga";
import { ErrorModel, ErrorValidateModel } from "../models/common/fromGenerated";

export const convertAxiosErrorToValidationError = (
  error: AxiosError,
  requestData: any
) => {
  return {
    ...(error.response?.data as ErrorValidateModel),
    isValidationError: true,
    requestData,
  } as IValidationError;
};

export const convertAxiosErrorToServerError = (error: AxiosError) => {
  return {
    ...(error.response?.data as ErrorModel),
    isServerError: true,
  } as IServerError;
};

export interface IParseErrorOptions  {
  requestData: any
}

export type ApiError = Error | IValidationError | IServerError

export const parseError = (
  error: any,
  options?: IParseErrorOptions
): ApiError => {
  if (isAxiosError(error)) {
    if (error.response?.data?.items != null) {
      return convertAxiosErrorToValidationError(error, options?.requestData || {});
    }
    if (error.response?.data != null) {
      return convertAxiosErrorToServerError(error);
    }
    return new Error(error.message);
  } else return new Error(error.message);
};
