import React, { useEffect, useState } from "react";
import { AuthInfoModel } from "../models/common/fromGenerated";
import * as authStorage from "../helpers/authStorage";
const useAuth = () => {
  const [info, setInfo] = useState<AuthInfoModel | null>(null);

  useEffect(() => {
    setInfo(authStorage.getAuth());
  }, []);
 
  return {
    info
  };
};

export default useAuth;
