import { takeEvery, put, call } from "redux-saga/effects";
import EAction from "./actionTypes";
import {
  registerUserSuccessful,
  registerUserFailed,
  RegisterUserAction,
  registerUserValidationFailed,
} from "./actions";
import { registerByEmail } from "../../../helpers/api";
import { MessageModel } from "../../../generated-sources/openapi/api";
import { parseError } from "../../../utils/errorUtils";
import {
  isServerError,
  isValidationError,
} from "../../../models/common/saga";

function* registerUserByEmail({ payload: { user } }: RegisterUserAction) {
  try {
    const response: MessageModel = yield call(registerByEmail, user);
    yield put(registerUserSuccessful(response));
  } catch (error) {
    const parsedError = parseError(error, { requestData: user });
    if (isValidationError(parsedError)) {
      yield put(registerUserValidationFailed(parsedError));
      yield put(registerUserFailed(new Error(parsedError.message)));
    } else if (isServerError(parsedError)) {
      yield put(registerUserFailed(new Error(parsedError.message)));
    }
    yield put(registerUserFailed(error as Error));
  }
}

function* accountSaga() {
  yield takeEvery(EAction.REGISTER_USER_BY_EMAIL, registerUserByEmail);
}

export default accountSaga;
