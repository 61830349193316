import {takeEvery, put, call, fork} from "redux-saga/effects";

import Actions from "./actionTypes";
import {
  getAccountSuccess,
  getAccountFail,
  editAccountSuccess,
  editAccountFail,
  editPasswordFail,
  editPasswordSuccess,
  resetPasswordSuccess,
  resetPasswordFail,
  EditAccountAction,
  EditAccountFailAction,
  EditPasswordAction,
  ResetPasswordAction,
} from "./actions";

import { getAccount, postEditAccount, editPassword, resetPassword } from "../../helpers/api";
import {UserAccountModel, ChangePasswordRequestModel, ResetPasswordRequestModel} from "../../generated-sources/openapi";
import {isAxiosError, IValidationError} from "../../models/common/saga";
import {convertAxiosErrorToValidationError} from "../../utils/errorUtils";

function* onAccount() {
   try {
     const response: UserAccountModel = yield call(getAccount);
     yield put(getAccountSuccess(response));
   } catch (error) {
     yield put(getAccountFail(error as IValidationError));
   }
 }

function* onEditAccount(action: EditAccountAction) {
  try {
    const response: UserAccountModel = yield call(postEditAccount, action.payload.account);
    yield put(editAccountSuccess(response, 'Account.changesSaved'));
    yield fork(onAccount);
  } catch (error) {
    yield put(editAccountFail(error as IValidationError));
  }
}

function* onEditPassword(action: EditPasswordAction) {
  try {
    const response: ChangePasswordRequestModel = yield call(editPassword, action.payload.password);
    yield put(editPasswordSuccess(response, 'Account.changesSaved'));
  } catch (error) {
    if (isAxiosError(error)) {
      const validationError = convertAxiosErrorToValidationError(
          error,
          action.payload.password
      );
      yield put(editPasswordFail(validationError));
      } else {
      yield put(editPasswordFail(error as Error));
      }
  }
}

function* onResetPassword(action: ResetPasswordAction) {
  try {
    const response: ResetPasswordRequestModel = yield call(resetPassword, action.payload.password);
    yield put(resetPasswordSuccess(response, 'ChangePassword.newPasswordSaved'));
    action.payload.history.push("/login");
  } catch (error) {
    if (isAxiosError(error)) {
      const validationError = convertAxiosErrorToValidationError(
          error,
          action.payload.password
      );
      yield put(resetPasswordFail(validationError));
    } else {
      yield put(resetPasswordFail(error as Error));
    }
  }
}

function* accountSaga() {
  yield takeEvery(Actions.GET_ACCOUNT, onAccount);
  yield takeEvery(Actions.EDIT_ACCOUNT, onEditAccount);
  yield takeEvery(Actions.EDIT_PASSWORD, onEditPassword);
  yield takeEvery(Actions.RESET_PASSWORD, onResetPassword);
}

export default accountSaga;
