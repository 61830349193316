import React, { useState, useEffect } from "react";

import AccountTab from "./AccountTab";
import EventsTab from "./EventsTab";
import { EditEshopTab, ConnectEshopTab } from "./ConnectTab";
import classes from "../IntegrationPage/integration.module.css";
import { Row, Container, Col, Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import EshopTab from "./EshopTab";
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import MetaTags from "react-meta-tags";

enum ETabs {
  ESHOP = "eshop",
  ACCOUNT = "account",
  EVENTS = "events",
  CONNECTESHOP = "connect",
};


const SettingsPage = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const { path, url } = useRouteMatch();

  const handleClickTab = (tabType : ETabs) => () => {
    history.push(`${url}/${tabType}`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t("Settings.title")}</title>
        </MetaTags>
        <Container>
          <Row>
            <Col md="3">
              <a onClick={handleClickTab(ETabs.ACCOUNT)}>
                <div className={classes.service}>
                  <img src={`/integration/icons/shopping-cart.png`} />
                  <div>
                    <h5 className={classes.no__bottom_margin}>
                      {t("Settings.account")}
                    </h5>
                    <p>{t("Settings.accountSubtitle")}</p>
                  </div>
                </div>
              </a>
              {/* <a href="#">
                <div className={classes.service}>
                  <img src="./integration/icons/zbozi.png" />
                  <div>
                    <h5 className={classes.no__bottom_margin}>Uživatelé</h5>
                    <p>Nastavení uživatelských rolí</p>
                  </div>
                </div>
              </a> */}
              <a onClick={handleClickTab(ETabs.ESHOP)}>
                <div className={classes.service}>
                  <img src="/integration/icons/heureka.png" />
                  <div>
                    <h5 className={classes.no__bottom_margin}>
                      {t("Settings.eshop")}
                    </h5>
                    <p>{t("Settings.eshopSubtitle")}</p>
                  </div>
                </div>
              </a>
                {/*<a onClick={handleClickTab(ETabs.EVENTS)}>
                <div className={classes.service}>
                  <img src="/integration/icons/heureka.png" />
                  <div>
                    <h5 className={classes.no__bottom_margin}>
                      {t("Settings.events")}
                    </h5>
                    <p>{t("Settings.eventsSubtitle")}</p>
                  </div>
                </div>
              </a>*/}
            </Col>
            <Col md="9">
              <Card>
                <CardBody>
                  {/* {shownTab === Tabs.ACCOUNT && <AccountTab />}
                  {shownTab === Tabs.ESHOP && (
                    <EshopTab
                      onSelectTypeChange={handleSelectEshopTypeChange}
                    />
                  )}
                  {shownTab === Tabs.CONNECTESHOP && (
                    <ConnectEshopTab type={selectedEshopType} />
                  )} */}

                  <Switch>
                    <Route exact path={`${path}/account`}>
                      <AccountTab />
                    </Route>
                    <Route exact path={`${path}/eshop`}>
                      <EshopTab />
                    </Route>
                    <Route path={`${path}/connect/:eshopid`}>
                      <EditEshopTab />
                    </Route>
                    <Route exact path={`${path}/connect`}>
                      <ConnectEshopTab />
                    </Route>
                    <Route exact path={`${path}/events`}>
                      <EventsTab />
                    </Route>
                  </Switch>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default SettingsPage;
