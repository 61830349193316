import React from "react";
import Paging from "./Paging";
import useAdaloTable from "../../customHooks/useAdaloTable";
import { PageModel } from "../../models/common/fromGenerated";
import { IReduxAction, ISimpleReduxAction } from "../../models/common/saga";

const TablePaging = ({
  refreshData,
  changePaging,
  totalItems,
  paging,
}: IProps) => {
  const {
    handleGotoFirst,
    handleGotoLast,
    handleGotoPrev,
    handleGotoNext,
    maxPage,
  } = useAdaloTable({
    totalItems,
    paging,
    onRefreshData: refreshData,
    onChangePaging: changePaging,
  });

  return (
    <Paging
      onGotoFirst={handleGotoFirst}
      onGotoNext={handleGotoNext}
      onGotoPrev={handleGotoPrev}
      onGotoLast={handleGotoLast}
      page={paging.page! + 1}
      maxPage={maxPage + 1}
    />
  );
};

interface IProps {
  refreshData: () => ISimpleReduxAction<string>;
  changePaging: (page: PageModel) => IReduxAction<string, any>;
  paging: PageModel;
  totalItems: number;
}

export default TablePaging;
