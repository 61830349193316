import React, { Component } from "react"
import { connect, ConnectedProps } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { logoutUser } from "../../store/actions"

type Props = PropsFromRedux & RouteComponentProps;

class Logout extends Component<Props> {
  componentDidMount = () => {
    this.props.logoutUser(this.props.history)
  }

  render() {
    return <React.Fragment></React.Fragment>
  }
}

const connector = connect(null, { logoutUser });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(Logout))
