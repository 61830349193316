import React, { useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";

import { clearResults } from "../../store/loading/actions";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";

const TIME_TO_HIDE_ALERT = 10 * 1000;

const LoadingResultAlert = ({
  clearResults,
  success,
  error,
  actiontype,
  ...otherProps
}: Props) => {
  // console.log(success, error, actiontype);

  const timeoutRef = useRef<NodeJS.Timeout | null | undefined>();

  const {t, i18n} = useTranslation();

  useEffect(() => {
    clearResults(actiontype);
  }, []);

  useEffect(() => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    if (success || error)
      timeoutRef.current = setTimeout(
        () => clearResults(actiontype),
        TIME_TO_HIDE_ALERT
      );

    return () => {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [success, error]);

  return (
    <React.Fragment>
      {success && success.message && (
        <Alert color="success">{i18n.exists(success.message) ? t(success.message) : success.message}</Alert>
      )}
      {error && actiontype!="EDIT_PASSWORD" && <Alert color="danger">{error.message}</Alert>}
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState, ownProps: IOwnProps) => {
  const success = state.Loading.successfull.filter(
    s => s.type === ownProps.actiontype
  )[0];
  const error = state.Loading.failed.filter(
    s => s.type === ownProps.actiontype
  )[0];
  return { success: success && success.payload, error: error && error.payload };
};

const mapDispatchToProps = { clearResults };

interface IOwnProps {
  actiontype: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & IOwnProps;

export default connector(LoadingResultAlert);
