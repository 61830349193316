module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID:"",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  recaptcha: {
    API_KEY: "6Ld7AcsZAAAAANyjdRJ7bLgKG7SITi9MEO_x5vd1"
  }
}
