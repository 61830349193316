import EAction from "./actionTypes";
import {
  UserForgetPassword,
  UserForgetPasswordError,
  UserForgetPasswordSuccess,
} from "./actions";
import { MessageModel } from "../../../models/common/fromGenerated";

interface IReduxState {
  forgetSuccessMsg: MessageModel | null;
  forgetError: Error| null;
}

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
} as IReduxState

type Action =
  | UserForgetPassword
  | UserForgetPasswordError
  | UserForgetPasswordSuccess;

const forgetPassword = (state = initialState, action: Action) => {
  switch (action.type) {
    case EAction.FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
      };
      break;
    case EAction.FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload,
      };
      break;
    case EAction.FORGET_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetPassword;
