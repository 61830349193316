import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  connectEshop,
  clearConnectEshopError,
} from "../../../store/eshop/actions";
import { useTranslation } from "react-i18next";
import ConnectEshopComponent from "./ConnectEshopComponent";
import EshopActions from "../../../store/eshop/actionTypes";
import { RootState } from "../../../store";
import { EshopModel } from "../../../models/common/fromGenerated";

const ConnectEshop = ({
  connectEshop,
  error,
  clearConnectEshopError,
}: ReduxProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const handleValidSubmit = (event: Event, values: any) => {
    clearConnectEshopError();
    connectEshop(values, history);
  };


  useEffect(() => {
    clearConnectEshopError();
  }, []);

  const defaultValues : EshopModel = {
    type: (location.state as any).eshoptype,
  };

  return (
    <ConnectEshopComponent
      defaultValues={defaultValues}
      onValidSubmit={handleValidSubmit}
      error={error}
      t={t}
      actiontype={EshopActions.CONNECT_ESHOP}
      actionback={() => history.push("/settings/eshop")}
      confirmTitle={t("EShop.connect")}
      backTitle={t("EShop.back")}
    ></ConnectEshopComponent>
  );
};

const connector = connect(
  (state: RootState) => ({ error: state.Eshop.connectEshop.validationError }),
  {
    connectEshop,
    clearConnectEshopError,
  }
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ConnectEshop);
