import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router";
import {
  editEshopById,
  clearConnectEshopError,
  getEshopById,
} from "../../../store/eshop/actions";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ConnectEshopComponent from "./ConnectEshopComponent";
import EshopActions from "../../../store/eshop/actionTypes";
import { RootState } from "../../../store";

type Props = ReduxProps;

const EditEshop = ({
  editEshopById,
  validationError,
  eshop,
  clearConnectEshopError,
  getEshopById,
}: Props) => {
  const { t } = useTranslation();

  const { eshopid } = useParams<{ eshopid: string }>();

  console.log(eshopid);
  const history = useHistory();

  const handleValidSubmit = (event: Event, values: any) => {
    clearConnectEshopError();
    editEshopById(eshopid, { ...values }, history);
  };

  useEffect(() => {
    clearConnectEshopError();
    getEshopById(eshopid);
  }, [eshopid]);

  return eshop != null ? (
    <ConnectEshopComponent
      defaultValues={eshop}
      onValidSubmit={handleValidSubmit}
      error={validationError}
      t={t}
      actiontype={EshopActions.EDIT_ESHOP_BY_ID}
      actionback={() => history.push("/settings/eshop")}
      confirmTitle={t("EShop.edit.editbutton")}
      backTitle={t("EShop.back")}
    ></ConnectEshopComponent>
  ) : null;
};

type ReduxProps = ConnectedProps<typeof connector>;

const connector = connect(
  (state: RootState) => ({
    error: state.Eshop.editEshop.error,
    validationError: state.Eshop.editEshop.validationError,
    eshop: state.Eshop.editEshop.data,
  }),
  {
    editEshopById,
    clearConnectEshopError,
    getEshopById,
  }
);

export default connector(EditEshop);
