import * as api from "../generated-sources/openapi/api";
import { API_URL } from "./url_helper";
import {
  PushLogsRequestModel,
  UserRequirementsModel,
  UserAccountModel,
  ChangePasswordRequestModel,
  ResetPasswordRequestModel,
  CredentialModel,
  ErrorValidateModel,
  EshopModel,
  EmailRegistrationModel,
  AuthInfoModel,
  EshopOverviewRequestModel,
  IntegrationHeurekaSettingModel,
  IntegrationZboziczSettingModel,
  ReviewOverviewRequestModel,
  ReviewAnswerModel,
  EventsListRequestModel
} from "../models/common/fromGenerated";
import authHeader from "./AuthType/auth-token-header";
import { AxiosRequestConfig, AxiosResponse } from "axios";


const BASE_PATH = API_URL.replace("/api", "");

function getConfig() {
  return { headers: authHeader() };
}

interface ErrorWithValidation {}

const getData = <T>(func: Promise<AxiosResponse<T>>) => {
  return func.then(response => {
    // console.log(response);
    return response.data;
  });
};

export const mainControllerApi = new api.MainControllerApi(
  undefined,
  BASE_PATH
);

export const accountControllerApi = new api.AccountControllerApi(
    undefined,
    BASE_PATH
)

export const diagnosticControllerApi = new api.DiagnosticsControllerApi(
  undefined,
  BASE_PATH
);

export const registrationControllerApi = new api.RegistrationControllerApi(
  undefined,
  BASE_PATH
);

export const eshopControllerApi = new api.EshopControllerApi(
  undefined,
  BASE_PATH
);

export const reviewControllerApi = new api.ReviewControllerApi(
  undefined,
  BASE_PATH
);

export const pushLogs = (data: PushLogsRequestModel) => {
  return getData(diagnosticControllerApi.pushLogsUsingPOST(data, getConfig()));
};

export const authByEmail = (data: CredentialModel): Promise<AuthInfoModel> => {
  return getData(
    mainControllerApi.authByEmailUsingPOST({
      login: data.login,
      password: data.password,
    })
  );
};

export const registerByEmail = (data: EmailRegistrationModel) => {
  return getData(registrationControllerApi.registrationByEmailUsingPOST(data));
};

export const getPortalConfiguration = () => {
  return getData(mainControllerApi.getPortalConfigurationUsingGET(getConfig()));
};

export const postConnectEshop = (eshop: EshopModel) =>
  getData(eshopControllerApi.createEshopUsingPOST(eshop, getConfig()));

export const getEshopsOverview = (request: EshopOverviewRequestModel) =>
  getData(eshopControllerApi.eshopsOverviewUsingPOST(request, getConfig()));

export const getAllEshops = () =>
  getData(eshopControllerApi.listAllUsingGET(getConfig()));

export const getEshopById = (id: string) =>
  getData(eshopControllerApi.getByIdUsingGET(id, getConfig()));

export const deleteEshopById = (id: string) =>
  getData(eshopControllerApi.removeByIdUsingDELETE(id, getConfig()));

export const editEshopById = (id: string, eshop: EshopModel) =>
  getData(eshopControllerApi.modifyEshopUsingPOST(id, eshop, getConfig()));

export const getAccount = () =>
    getData(accountControllerApi.accountInfoUsingGET(getConfig()));

export const editAccount = (data: UserAccountModel) =>
  getData(accountControllerApi.updateAccountInfoUsingPOST(data, getConfig()));

export const editPassword = (data: any) =>
    getData(accountControllerApi.changePasswordUsingPOST(data, getConfig()));

export const forgetPassword = (data: any) =>
    getData(accountControllerApi.requestResetPasswordUsingPOST(data, getConfig()))

export const resetPassword = (data: any) =>
    getData(accountControllerApi.resetPasswordUsingPOST(data, getConfig()));

export const getHeurekaSettings = (id: string) =>
  getData(eshopControllerApi.getHeurekaSettingUsingGET(id, getConfig()));

export const setHeurekaSettings = (
  id: string,
  data: IntegrationHeurekaSettingModel
) =>
  getData(eshopControllerApi.setHeurekaSettingUsingPOST(id, data, getConfig()));

export const getZboziSettings = (id: string) =>
  getData(eshopControllerApi.getZboziczSettingUsingGET(id, getConfig()));

export const setZboziSettings = (
  id: string,
  data: IntegrationZboziczSettingModel
) =>
  getData(eshopControllerApi.setZboziczSettingUsingPOST(id, data, getConfig()));

export const getReviewOverview = (request: ReviewOverviewRequestModel) =>
  getData(reviewControllerApi.reviewsOverviewUsingPOST(request, getConfig()));

export const getReviewById = (id: string) =>
  getData(reviewControllerApi.reviewByIdUsingGET(id, getConfig()));

export const setReviewRead = (id: string) =>
  getData(reviewControllerApi.setReadUsingPOST(id, getConfig()));

export const setReviewUnread = (id: string) =>
  getData(reviewControllerApi.setUnreadUsingPOST(id, getConfig()));

export const sendReviewAnswer = (answer: ReviewAnswerModel) =>
  getData(reviewControllerApi.sendAnswerUsingPUT(answer, getConfig()));

export const getEventsOverview = (request: EventsListRequestModel) =>
  getData(mainControllerApi.eventsListUsingPOST(request, getConfig()))

export const logout = () =>
  getData(mainControllerApi.logoutUsingGET(getConfig()));
