import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Button, Label } from "reactstrap";

import classes from "../../IntegrationPage/integration.module.css";
import LoadingButton from "../../../components/Common/LoadingButton";
import { EShopTypes, EShopTypesLabels } from "../EshopTab/EshopTab";
import { getCustomServerValidation } from "../../../helpers/validation_helper";
import { IValidationError } from "../../../models/common/saga";
import { EshopModel } from "../../../models/common/fromGenerated";
const { AvForm, AvField, AvGroup } = require("availity-reactstrap-validation");

interface IProps {
  error: IValidationError | null;
  defaultValues: EshopModel;
  onValidSubmit: (event: Event, values: any) => any;
  t: any;
  actiontype: string;
  actionback: any;
  confirmTitle: string;
  backTitle: string;
}

const ConnectEshopComponent = ({
  error,
  defaultValues,
  onValidSubmit,
  t,
  actiontype,
  actionback,
  confirmTitle,
  backTitle
}: IProps) => {
  return (
    <React.Fragment>
      {/* <h4 style={{ marginBottom: "2.5rem" }}>{t("ConnectEshop.title")}</h4> */}
      <Row>
        <Col lg="10" className={classes.col__padding_bigger}>
          <AvForm
            model={defaultValues}
            className="form-horizontal"
            onValidSubmit={onValidSubmit}
          >
            <AvGroup>
              <h5 style={{ marginBottom: "2rem" }}>
                {t("ConnectEshop.title")}
              </h5>
              {defaultValues.type == "WOOCOMERCE" &&
                  <div className="mb-12" style={{marginBottom: "2rem"}}>
                      Před připojením si na WooCommerce nainstalujte plugin <a href="/plugins/woo-trustbite.zip"
                                                                               target="_blank">Woo-trustbite</a>.
                      Poté v administraci WooCommerce → Trustbite naleznete klíč pro připojení e-shopu.
                  </div>
              }
              <div className="mb-3">
                <AvField
                  name="type"
                  label={t("ConnectEshop.type")}
                  className="form-control"
                  type="select"
                  disabled
                  required
                  errorMessage={t("Common.required")}
                  validate={getCustomServerValidation("type", error)}
                >
                  {Object.values(EShopTypes).map(type => (
                    <option key={type} value={type}>
                      {EShopTypesLabels[type]}
                    </option>
                  ))}
                </AvField>
              </div>
              {

              }
              <div className="mb-3">
                <AvField
                  name="name"
                  label={t("ConnectEshop.name")}
                  className="form-control"
                  type="text"
                  maxLength={100}
                  required
                  validate={getCustomServerValidation("name", error)}
                  errorMessage={t("Common.required")}
                />
              </div>
              {defaultValues.type != "SHOPTET" &&
                  <div className="mb-3">
                    <AvField
                        name="url"
                        label={t("ConnectEshop.url")}
                        className="form-control"
                        type="text"
                        validate={{
                          pattern: {
                            value:
                                /^(https?:\/\/|.?)(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/\/=]*)$/,
                            errorMessage: t("ConnectEshop.invalidUrl"),
                          },
                          ...getCustomServerValidation("url", error),
                        }}
                        required
                        errorMessage={t("Common.required")}
                    />
                  </div>
              }
              <div className="mb-3">
                <AvField
                  name="secretKey"
                  label={t("ConnectEshop.secretKey")}
                  className="form-control"
                  type="text"
                  maxLength={256}
                  required
                  validate={getCustomServerValidation("secretKey", error)}
                  errorMessage={t("Common.required")}
                />
              </div>
              <div className="mb-3">
                <AvField
                  name="description"
                  label={t("ConnectEshop.description")}
                  className="form-control"
                  type="textarea"
                  validate={getCustomServerValidation("description", error)}
                />
              </div>
              <div className="d-flex justify-content-between">
                <Button
                    color="primary"
                    onClick={actionback}
                >
                  {backTitle}
                </Button>
                <LoadingButton
                  color="primary"
                  actiontype={actiontype} //{CONNECT_ESHOP}
                  title={confirmTitle} // {t("EShop.connect")}
                  type="submit"
                ></LoadingButton>
              </div>
            </AvGroup>
          </AvForm>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ConnectEshopComponent;
