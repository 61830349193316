// import { takeEvery, put, call, select, fork, takeLatest } from "redux-saga/effects";

// import Actions from "./actionTypes";
// import {
//   getHeurekaSettingsFail,
//   getHeurekaSettingsSuccess,
//   setHeurekaSettingsFail,
//   setHeurekaSettingsSuccess,
//   setZboziSettingsFail,
//   setZboziSettingsSuccess,
//   getZboziSettingsFail,
//   getZboziSettingsSuccess,
//   SetHeurekaSettings,
//   SetZboziSettings
// } from "./actions";

// import {
//   postHeurekaSetings as setHeurekaSettings,
//   getHeurekaSettings as getHeurekaSettings,
//   getZboziSettings,
//   postZboziSettings
// } from "../../helpers/api";
// import { RootState } from "..";
// import { IntegrationHeurekaSettingModel, IntegrationZboziczSettingModel } from "../../models/common/fromGenerated";
// import { isAxiosError } from "../../models/common/saga";
// import { parseError } from "../../utils/errorUtils";
// // const parseError = error =>
// //   error && error.response && error.response.data ? error.response.data : error;

// function* onHeurekaSettings() {
//   try {
//     const eshopId : string = yield select((state : RootState) => state.Eshop.eshopsList.selectedEshop);
//     const response : IntegrationHeurekaSettingModel = yield call(getHeurekaSettings, eshopId);
//     yield put(getHeurekaSettingsSuccess(response));
//   } catch (error) {
//     const parsedError = parseError(error);
//     yield put(getHeurekaSettingsFail(parsedError));
//   }
// }

// function* onSetHeurekaSettings({ payload } : SetHeurekaSettings) {
//   try {
//     const eshopId : string = yield select(state => state.Eshop.eshopsList.selectedEshop);
//     yield call(setHeurekaSettings, eshopId, {
//       ...payload,
//       eshopId,
//     });
//     yield put(setHeurekaSettingsSuccess("Uloženo"));
//   } catch (error) {
//     const parsedError = parseError(error);
//     yield put(setHeurekaSettingsFail(parsedError));
//   }
// }

// function* onZboziSettings() {
//   try {
//     const eshopId : string = yield select(state => state.Eshop.eshopsList.selectedEshop);
//     const response : IntegrationZboziczSettingModel = yield call(getZboziSettings, eshopId);
//     yield put(getZboziSettingsSuccess(response));
//   } catch (error) {
//     yield put(getZboziSettingsFail(parseError(error)));
//   }
// }

// function* onSetZboziSettings({ payload } : SetZboziSettings) {
//   try {
//     const eshopId : string = yield select(state => state.Eshop.eshopsList.selectedEshop);
//     yield call(postZboziSettings, eshopId, {
//       ...payload,
//       eshopId,
//     });
//     yield put(setZboziSettingsSuccess("Uloženo"));
//   } catch (error) {
//     yield put(setZboziSettingsFail(parseError(error)));
//   }
// }

// function* integrationSaga() {
//   yield takeLatest(Actions.GET_HEUREKA_SETTINGS, onHeurekaSettings);
//   yield takeEvery(Actions.SET_HEUREKA_SETTINGS, onSetHeurekaSettings);
//   yield takeLatest(Actions.GET_ZBOZI_SETTINGS, onZboziSettings);
//   yield takeEvery(Actions.SET_ZBOZI_SETTINGS, onSetZboziSettings);
// }

// export default integrationSaga;

import {
  takeEvery,
  put,
  call,
  select,
  fork,
  takeLatest,
} from "redux-saga/effects";

import Actions from "./actionTypes";
import {
  getHeurekaSettingsFail,
  getHeurekaSettingsSuccess,
  setHeurekaSettingsFail,
  setHeurekaSettingsSuccess,
  setZboziSettingsFail,
  setZboziSettingsSuccess,
  getZboziSettingsFail,
  getZboziSettingsSuccess,
  SetHeurekaSettings,
  SetZboziSettings,
  GetHeurekaSettings,
  GetZboziSettings,
  GetZboziSettingsSuccess,
  SetZboziSettingsSuccess,
  SetHeurekaSettingsSuccess,
} from "./actions";

import {
  getHeurekaSettings,
  postHeurekaSetings as setHeurekaSettings,
  getZboziSettings,
  postZboziSettings as setZboziSettings,
} from "../../helpers/api";
import { RootState } from "..";

import {
  processRequest,
  processRequest2,
} from "../../utils/requestUtils";

import {IntegrationHeurekaSettingModel, IntegrationZboziczSettingModel} from "../../generated-sources/openapi";
import {isAxiosError, IValidationError} from "../../models/common/saga";
import {convertAxiosErrorToValidationError} from "../../utils/errorUtils";

function* onHeurekaSettings(action: GetHeurekaSettings) {
  const eshopId: string = yield select(
    (state: RootState) => state.Eshop.eshopsList.selectedEshop
  );

  yield processRequest(action.type, getHeurekaSettings, eshopId);
}

function* onSetHeurekaSettings(action: SetHeurekaSettings) {
  const eshopId: string = yield select(
      state => state.Eshop.eshopsList.selectedEshop
  );

  yield processRequest2(
      action.type,
      setHeurekaSettings,
      eshopId,
      {
        ...action.payload,
        eshopId,
      },
      { parseResponse: _ => ({message:"Common.saved"} as SetHeurekaSettingsSuccess['payload']) }
  );

  try {
    const response: {id:string, data: IntegrationHeurekaSettingModel}  = yield call(setHeurekaSettings, eshopId, action.payload);
    yield put(setHeurekaSettingsSuccess(response, 'Account.changesSaved'));
  } catch (error) {
    if (isAxiosError(error)) {
      const validationError = convertAxiosErrorToValidationError(
          error,
          action.payload
      );
      yield put(setHeurekaSettingsFail(validationError));
    } else {
      yield put(setHeurekaSettingsFail(error as Error));
    }
  }

}

function* onZboziSettings(action: GetZboziSettings) {
  const eshopId: string = yield select(
    state => state.Eshop.eshopsList.selectedEshop
  );
  yield processRequest(action.type, getZboziSettings, eshopId);
}

function* onSetZboziSettings(action: SetZboziSettings) {
  const eshopId: string = yield select(
      state => state.Eshop.eshopsList.selectedEshop
  );

  yield processRequest2(
      action.type,
      setZboziSettings,
      eshopId,
      {...action.payload, eshopId},
      {
        parseResponse: _ => {
          return {message: "Common.saved"} as SetZboziSettingsSuccess['payload']
        }
      }
  );

  try {
    const response: { id: string, data: IntegrationZboziczSettingModel } = yield call(setZboziSettings, eshopId, action.payload);
    yield put(setZboziSettingsSuccess(response, 'Account.changesSaved'));
  } catch (error) {
    if (isAxiosError(error)) {
      const validationError = convertAxiosErrorToValidationError(
          error,
          action.payload
      );
      yield put(setZboziSettingsFail(validationError));
    } else {
      yield put(setZboziSettingsFail(error as Error));
    }
  }

}

function* integrationSaga() {
  yield takeLatest(Actions.GET_HEUREKA_SETTINGS, onHeurekaSettings);
  yield takeEvery(Actions.SET_HEUREKA_SETTINGS, onSetHeurekaSettings);
  yield takeLatest(Actions.GET_ZBOZI_SETTINGS, onZboziSettings);
  yield takeEvery(Actions.SET_ZBOZI_SETTINGS, onSetZboziSettings);
}

export default integrationSaga;
