import {IValidationError, reduxAction} from "../../models/common/saga";
import Actions from "./actionTypes";

export const editAccount = (account: any) =>
  reduxAction(Actions.EDIT_ACCOUNT, { account });

export const editAccountSuccess = (response: any, message: string) =>
  reduxAction(Actions.EDIT_ACCOUNT_SUCCESS, { response, message });

export const editAccountFail = (error: IValidationError) =>
  reduxAction(Actions.EDIT_ACCOUNT_FAIL, error);

export const getAccount = () =>
  reduxAction(Actions.GET_ACCOUNT, {});

export const getAccountSuccess = (account: any) =>
  reduxAction(Actions.GET_ACCOUNT_SUCCESS, { account });

export const getAccountFail = (error: IValidationError) =>
  reduxAction(Actions.GET_ACCOUNT_FAIL, error);

export const editPassword = (password: any) =>
  reduxAction(Actions.EDIT_PASSWORD, { password });

export const editPasswordSuccess = (response: any, message: string) =>
  reduxAction(Actions.EDIT_PASSWORD_SUCCESS, { response, message });

export const editPasswordFail = (error: any) =>
  reduxAction(Actions.EDIT_PASSWORD_FAIL, error);

export const resetPassword = (password: any, history: any,) =>
    reduxAction(Actions.RESET_PASSWORD, { password, history });

export const resetPasswordSuccess = (response: any, message: string) =>
    reduxAction(Actions.RESET_PASSWORD_SUCCESS, { response, message });

export const resetPasswordFail = (error: any) =>
    reduxAction(Actions.RESET_PASSWORD_FAIL, error);

export type EditAccountAction = ReturnType<typeof editAccount>;
export type EditAccountSuccessAction = ReturnType<typeof editAccountSuccess>;
export type EditAccountFailAction = ReturnType<typeof editAccountFail>;
export type GetAccountAction = ReturnType<typeof getAccount>;
export type GetAccountSuccessAction = ReturnType<typeof getAccountSuccess>;
export type GetAccountFailAction = ReturnType<typeof getAccountFail>;
export type EditPasswordAction = ReturnType<typeof editPassword>;
export type EditPasswordSuccessAction = ReturnType<typeof editPasswordSuccess>;
export type EditPasswordFailAction = ReturnType<typeof editPasswordFail>;
export type ResetPasswordAction = ReturnType<typeof resetPassword>;
export type ResetPasswordSuccessAction = ReturnType<typeof resetPasswordSuccess>;
export type ResetPasswordFailAction = ReturnType<typeof resetPasswordFail>;