import React, { Component } from "react"
import PropTypes from 'prop-types'
import { RouteComponentProps, withRouter } from "react-router-dom"

interface IOwnProps {
};
interface IState {
};

type Props = IOwnProps & RouteComponentProps

class NonAuthLayout extends Component<Props, IState> {
  constructor(props : Props) {
    super(props)
    this.state = {}
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}

export default withRouter(NonAuthLayout)
