import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ChangePwd from "../pages/Authentication/ChangePassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Feedbacks
import FeedbacksPage from "../pages/FeedbacksPage/index";

// Integration
import IntegrationPage from "../pages/IntegrationPage/index";

// Settings
import SettingsPage from "../pages/SettingsPage/index";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard, exact: true },

  //Feedbacks Page
  { path: "/feedbacks", component: FeedbacksPage, exact: true },
  { path: "/feedbacks/:feedbackId", component: FeedbacksPage },

  //Integration Page
  { path: "/integration", component: IntegrationPage, exact: true },

  //Settings Page
  { path: "/settings", component: SettingsPage, exact: false },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/change-password/:id", component: ChangePwd },
  { path: "/register", component: Register }
];

export { authProtectedRoutes, publicRoutes };
