import {
  takeEvery,
  put,
  call,
  select,
  fork,
  takeLatest,
} from "redux-saga/effects";
import {
  EshopModel,
  PageModel,
  PageResultModelEshopModel,
} from "../../models/common/fromGenerated";
import EshopActions from "./actionTypes";
import {
  connectEshopSuccess,
  connectEshopFail,
  getAllEshopsSuccess,
  getAllEshopsFail,
  getAllEshops,
  getEshopOverviewFail,
  getEshopsOverview as getEshopsOverviewAction,
  getEshopsOverviewSuccess,
  getEshopByIdSuccess,
  getEshopByIdFail,
  deleteEshopByIdSuccess,
  deleteEshopByIdFail,
  editEshopByIdSuccess,
  editEshopByIdFail,
  connectEshopValidationFailed,
  ConnectEshopAction,
  GetEshopByIdAction,
  DeleteEshopByIdAction,
  EditEshopByIdAction,
  ChangeEshopsOverviewPagingAction,
  editEshopByIdValidationFail,
} from "./actions";

import {
  postConnectEshop,
  getAllEshops as getAllEshopsCall,
  getEshopsOverview,
  getEshopById,
  deleteEshopById,
  postEditEshopById,
} from "../../helpers/api";
import { convertAxiosErrorToValidationError } from "../../utils/errorUtils";
import { isAxiosError } from "../../models/common/saga";
import { RootState } from "..";

function* onConnectEshop(action: ConnectEshopAction) {
  try {
    const response: EshopModel = yield call(
      postConnectEshop,
      action.payload.data
    );
    yield put(connectEshopSuccess(response, "EShop.table.connected"));
    console.log(action.payload);
    action.payload.history.push("/settings/eshop");
    yield fork(onGetAllEshops);
  } catch (error) {
    console.log(error);
    if (isAxiosError(error)) {
      const validationError = convertAxiosErrorToValidationError(
        error,
        action.payload.data
      );
      
      yield put(connectEshopValidationFailed(validationError));
      yield put(connectEshopFail(new Error(validationError.message)));
    } else {
      yield put(connectEshopFail(error as Error));
    }
  }
}

function* onGetAllEshops() {
  try {
    const response: EshopModel[] = yield call(getAllEshopsCall);
    yield put(getAllEshopsSuccess(response));
  } catch (error) {
    yield put(getAllEshopsFail(error as Error));
  }
}

function* onEshopsOverview() {
  try {
    const paging: PageModel = yield select(
      (state: RootState) => state.Eshop.eshopsOverview.page
    );

    //console.log(paging);
    const response: PageResultModelEshopModel = yield call(getEshopsOverview, {
      page: paging,
      sort: { asc: true, column: "string" },
    });

    // const response = {
    //   items: [
    //     {
    //       description: "string",
    //       id: "string",
    //       name: "string" + new Date(),
    //       type: "string",
    //       url: "string",
    //     },
    //   ],
    //   page: {
    //     ...paging,
    //   },
    //   totalItems: 100,
    // };

    yield put(getEshopsOverviewSuccess(response));
  } catch (error) {
    yield put(getEshopOverviewFail(error as Error));
  }
}

function* onEshopById({ payload }: GetEshopByIdAction) {
  try {
    const response: EshopModel = yield call(
      getEshopById,
      payload //"a292ab38-c046-45ef-9a2d-5cb7a2ffa605"
    );
    yield put(getEshopByIdSuccess(response));
  } catch (error) {
    yield put(getEshopByIdFail(error as Error));
  }
}

function* onDeleteEshopById({ payload }: DeleteEshopByIdAction) {
  try {
    yield call(deleteEshopById, payload);
    yield put(deleteEshopByIdSuccess("EShop.table.eshopdeleted"));
    yield fork(onEshopsOverview);
    yield fork(onGetAllEshops);
  } catch (error) {
    yield put(deleteEshopByIdFail(error as Error));
  }
}

function* onEditEshopById({ payload }: EditEshopByIdAction) {
  try {
    const response: EshopModel = yield call(
      postEditEshopById,
      payload.id,
      payload.data
    );
    yield put(editEshopByIdSuccess(response, "EShop.table.edited"));
    payload.history.push("/settings/eshop");
    yield fork(onGetAllEshops);
  } catch (error) {
    if (isAxiosError(error)) {
      const validationError = convertAxiosErrorToValidationError(
        error,
        payload.data
      );
      yield put(editEshopByIdValidationFail(validationError));
      yield put(editEshopByIdFail(new Error(validationError.message)));
    } else {
      yield put(editEshopByIdFail(error as Error));
    }
  }
}

function* onChangeEshopsOverviewPaging({
  payload,
}: ChangeEshopsOverviewPagingAction) {
  yield put(getEshopsOverviewAction());
}

function* connectEshopSaga() {
  yield takeEvery(EshopActions.CONNECT_ESHOP, onConnectEshop);
  yield takeLatest(EshopActions.GET_ALL_ESHOPS, onGetAllEshops);
  yield takeEvery(EshopActions.GET_ESHOPS_OVERVIEW, onEshopsOverview);
  yield takeEvery(EshopActions.GET_ESHOP_BY_ID, onEshopById);
  yield takeEvery(EshopActions.DELETE_ESHOP_BY_ID, onDeleteEshopById);
  yield takeEvery(
    EshopActions.CHANGE_ESHOPS_OVERVIEW_PAGING,
    onChangeEshopsOverviewPaging
  );
  yield takeEvery(EshopActions.EDIT_ESHOP_BY_ID, onEditEshopById);
}

export default connectEshopSaga;
