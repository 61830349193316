import { put, call } from "redux-saga/effects";
import { ISimpleReduxAction, reduxAction, reduxActionBasic } from "../models/common/saga";
import { parseError as parsError } from "./errorUtils";

export type ParseErrorHandler = (error: any) => any;
export type ParseSuccessHandler<T> = (data: T) => any;

export interface IParsedRequestResult {
  hasError: boolean;
  error?: any;
  result?: any;
}

interface IProcessOptions<ResponseType> {
  parseError?: ParseErrorHandler;
  parseResponse?: ParseSuccessHandler<ResponseType>;
}

export function* processRequest<ArgType1, ResponseType>(
  statusKey: string,
  request: (arg1: ArgType1) => Promise<ResponseType>,
  arg1: ArgType1,
  options?: IProcessOptions<ResponseType>
) {
  const res: IParsedRequestResult = yield _processRequest(
    statusKey,
    request,
    [arg1],
    options
  );
  return res;
}

export function* processRequestZeroArgs<ResponseType>(
  statusKey: string,
  request: () => Promise<ResponseType>,
  options?: IProcessOptions<ResponseType>
) {
  const res: IParsedRequestResult = yield _processRequest(
    statusKey,
    request,
    [],
    options
  );
  return res;
}

export function* processRequest2<ArgType1, ArgType2, ResponseType>(
  statusKey: string,
  request: (arg1: ArgType1, arg2: ArgType2) => Promise<ResponseType>,
  arg1: ArgType1,
  arg2: ArgType2,
  options?: IProcessOptions<ResponseType>
) {
  const res: IParsedRequestResult = yield _processRequest(
    statusKey,
    request,
    [arg1, arg2],
    options
  );
  return res;
}

export function* processRequest3<
  ArgType1,
  ArgType2,
  ArgType3,
  ResponseType
>(
  statusKey: string,
  request: (
    arg1: ArgType1,
    arg2: ArgType2,
    arg3: ArgType3
  ) => Promise<ResponseType>,
  arg1: ArgType1,
  arg2: ArgType2,
  arg3: ArgType3,
  options?: IProcessOptions<ResponseType>
) {
  const res: IParsedRequestResult = yield _processRequest(
    statusKey,
    request,
    [arg1, arg2, arg3],
    options
  );
  return res;
}

function* _processRequest<ResponseType>(
  statusKey: string,
  request: (...args: any[]) => Promise<ResponseType>,
  args: any[],
  options?: IProcessOptions<ResponseType>
) {
  try {
    yield put(reduxActionBasic(statusKey + "_REQUEST"));
    const response: ResponseType = yield call(request, ...args);
    let _parseResponse = options?.parseResponse;
    if (_parseResponse == null) {
      _parseResponse = data => data;
    }
    const parsedResponse = _parseResponse(response);
    yield put(reduxAction(statusKey + "_SUCCESS", parsedResponse));
    return { hasError: false, result: parsedResponse } as IParsedRequestResult;
  } catch (error) {
    const _parseError = options?.parseError || parsError;
    const parsedError = _parseError(error);
    yield put(reduxAction(statusKey + "_FAIL", parsedError));
    return { hasError: true, error: parsedError };
  }
}
