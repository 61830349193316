import {
  EmailRegistrationModel,
  MessageModel,
} from "../../../models/common/fromGenerated";
import {
  IReduxAction,
  IValidationError,
  reduxAction,
} from "../../../models/common/saga";
import EAction from "./actionTypes";

export const registerUser = (user: EmailRegistrationModel) =>
  reduxAction(EAction.REGISTER_USER_BY_EMAIL, { user });

export const registerUserSuccessful = (message: MessageModel) =>
  reduxAction(EAction.REGISTER_USER_BY_EMAIL_SUCCESSFUL, message);

export const registerUserFailed = (error: Error) =>
  reduxAction(EAction.REGISTER_USER_BY_EMAIL_FAILED, error);

export const registerUserValidationFailed = (error: IValidationError) =>
  reduxAction(EAction.REGISTER_USER_BY_EMAIL_VALIDATION_FAIL, error);

export type RegisterUserAction = ReturnType<typeof registerUser>;
export type RegisterUserSuccessfullAction = ReturnType<
  typeof registerUserSuccessful
>;
export type RegisterUserFailedAction = ReturnType<typeof registerUserFailed>;
export type RegisterUserValidationFailedAction = ReturnType<
  typeof registerUserValidationFailed
>;
