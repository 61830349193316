import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n";

import {
  changeLayout,
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayoutWidth,
} from "../../store/actions";

import { getPortalConfiguration } from "../../store/configuration/actions";

// Other Layout related Component
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";
import { RootState } from "../../store";

interface IState {
  isMenuOpened: boolean;
}

class Layout extends Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isMenuOpened: false,
    };
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
  }

  /**
   * Open/close right sidebar
   */
  toggleRightSidebar() {
    return this.props.toggleRightSidebar();
  }

  componentDidMount() {
    this.props.getPortalConfiguration();

    if (this.props.isPreloader === true) {
      document.getElementById("preloader")!.style.display = "block";
      document.getElementById("status")!.style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader")!.style.display = "none";
        document.getElementById("status")!.style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader")!.style.display = "none";
      document.getElementById("status")!.style.display = "none";
    }

    // Scrollto 0,0
    window.scrollTo(0, 0);

    // const title = this.props.location.pathname
    // let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    // document.title =
    //   currentage + " | Skote - React Admin & Dashboard Template"

    this.props.changeLayout("horizontal"); //vertical se nepouziva vubec
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }
    if (this.props.showRightSidebar) {
      this.toggleRightSidebar();
    }
  }

  /**
   * Opens the menu - mobile
   */
  openMenu = () => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened });
  };
  render() {
    return (
      <React.Fragment>
        <I18nextProvider i18n={i18n}>
          <div id="preloader">
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
          <div id="layout-wrapper">
            <Header
              theme={this.props.topbarTheme}
              // isMenuOpened={this.state.isMenuOpened}
              openLeftMenuCallBack={this.openMenu}
            />
            <Navbar menuOpen={this.state.isMenuOpened} />
            <div className="main-content">{this.props.children}</div>
            <Footer />
          </div>
        </I18nextProvider>
      </React.Fragment>
    );
  }
}

interface IOwnProps {
  isPreloader: boolean;
  layoutWidth: string;
  showRightSidebar: boolean;
  topbarTheme: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    ...state.Layout,
  };
};
const connector = connect(mapStateToProps, {
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayout,
  changeLayoutWidth,
  getPortalConfiguration,
});



type Props = ConnectedProps<typeof connector> & IOwnProps & RouteComponentProps;

export default connector(withRouter(Layout));
