
enum EAction {
  REGISTER_USER_BY_EMAIL_SUCCESSFUL = "register_user_by_email_SUCCESS",
  REGISTER_USER_BY_EMAIL_FAILED = "register_user_by_email_FAIL",
  REGISTER_USER_BY_EMAIL = "register_user_by_email",
  REGISTER_USER_BY_EMAIL_VALIDATION_FAIL = "register_user_by_email_validation_FAIL",
}

// export const REGISTER_USER_BY_EMAIL_SUCCESSFUL = "register_user_by_email_SUCCESS";
// export const REGISTER_USER_BY_EMAIL_FAILED = "register_user_by_email_FAIL";
// export const REGISTER_USER_BY_EMAIL = "register_user_by_email";
// export const REGISTER_USER_BY_EMAIL_VALIDATION_FAIL = "register_user_by_email_validation_FAIL";

export default EAction;
