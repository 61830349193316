import React, { useState, useMemo, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
    getEventsOverview,
    changeEventsOverviewPaging,
} from "../../../store/events/actions";
import { Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { FiAlertCircle, FiXCircle, FiInfo } from "react-icons/fi";
import { Column, TableCellProps, useTable, UseTableCellProps } from "react-table";
import { ActionCell, EventsTypeCell } from "./Cells";
import useAdaloTable from "../../../customHooks/useAdaloTable";
import EventsActions from "../../../store/events/actionTypes";
import { useTranslation } from "react-i18next";
import Paging from "../../../components/Common/Paging";
import LoadingAware from "../../../components/Common/LoadingAware";
import Skeleton from "react-loading-skeleton";
import { RootState } from "../../../store";

const TextCell = ({ value } : UseTableCellProps<ColumnData, string>) => {
    return (
        <p className="text-truncate" title={value}>
            {value}
        </p>
    );
};

const LevelCell = ({ value } : UseTableCellProps<ColumnData, string>) => {

    switch(value) {
        case "ERROR":
           return <FiXCircle size={30} color="red" />
        case "WARNING":
            return <FiAlertCircle size={30} color="orange" />
        case "INFO":
           return <FiInfo size={30} color="green" />
        default:
            return value
    }

};

const Placeholder = () => (
    <React.Fragment>
        <Skeleton count={1} height={35} className={"mt-4 mb-5"} />
        <Skeleton count={5} height={60} className={"mb-2"}></Skeleton>
    </React.Fragment>
);

type Props = ReduxProps;

export interface ColumnData {
    level: string;
    time : string | Date;
    message: string;
    longMessage: string;
}

const EventsTable = ({
                        events,
                        getEventsOverview,
                        paging,
                        changeEventsOverviewPaging,
                        totalItems,
                    }: Props) => {
    const { t } = useTranslation();

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "level" as string,
                Cell: LevelCell,
            },
            {
                Header: t("Events.table.time"),
                accessor: "time" as string,
                Cell: TextCell,
            },
            {
                Header: t("Events.table.message"),
                accessor: "message" as string,
                Cell: TextCell,
            },
            {
                Header: "",
                accessor: "longMessgae" as string,
                Cell: ActionCell,
            },
        ] as Column<ColumnData>[],
        []
    );

    const data = useMemo(() => {
        return events.map(e => {
            const converted: ColumnData = {
                message: e.message || "",
                longMessage: e.longMessage|| "",
                time: e.time != undefined && new Date(e.time).toLocaleString('cs-CZ', { timeZone: 'UTC' }) || "",
                level: e.level || "",
            };
            return converted;
        });
    }, [events]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data,
        });

    const {
        handleGotoFirst,
        handleGotoLast,
        handleGotoPrev,
        handleGotoNext,
        maxPage,
    } = useAdaloTable({
        totalItems,
        paging,
        onRefreshData: getEventsOverview,
        onChangePaging: changeEventsOverviewPaging,
    });

    return (
        <React.Fragment>
            <LoadingAware
                actiontypes={[EventsActions.GET_EVENTS_OVERVIEW]}
                placeholder={<Placeholder />}
            >
                {events.length > 0 ? (
                    <React.Fragment>
                        <Table {...getTableProps()}>
                            <thead>
                            {headerGroups.map((headerGroup, i) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, i) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, i) => {
                                            return (
                                                <td
                                                    style={{ width: 0 }}
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                        <div>
                            <Paging
                                onGotoFirst={handleGotoFirst}
                                onGotoNext={handleGotoNext}
                                onGotoPrev={handleGotoPrev}
                                onGotoLast={handleGotoLast}
                                page={(paging?.page || 0) + 1}
                                maxPage={maxPage + 1}
                            />
                        </div>
                    </React.Fragment>
                ) : <h4> {t("Events.table.noRecords")}</h4>}
            </LoadingAware>

        </React.Fragment>
    );
};

const connector = connect(
    (state: RootState) => ({
        events: state.Events.eventsOverview.data,
        paging: state.Events.eventsOverview.page || {},
        totalItems: state.Events.eventsOverview.total || 0,
    }),
    {
        getEventsOverview,
        changeEventsOverviewPaging,
    }
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(EventsTable);
