import { ConfigurationModel } from "../../models/common/fromGenerated";
import { reduxAction, reduxActionBasic } from "../../models/common/saga";
import EAction from "./actionTypes";

export const getPortalConfiguration = () =>
  reduxActionBasic(EAction.GET_PORTAL_CONFIGURATION);

export const getPortalConfigurationSuccess = (payload : ConfigurationModel) =>
  reduxAction(EAction.GET_PORTAL_CONFIGURATION_SUCCESS, payload);

export const getPortalConfigurationFail = (payload: Error) => reduxAction(
  EAction.GET_PORTAL_CONFIGURATION_FAIL,
  payload,
);

export type GetPortalConfigurationAction = ReturnType<typeof getPortalConfiguration>
export type GetPortalConfigurationSuccessAction = ReturnType<typeof getPortalConfigurationSuccess>
export type GetPortalConfigurationFailAction = ReturnType<typeof getPortalConfigurationFail>
