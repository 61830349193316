import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EshopActions from "../../../store/eshop/actionTypes";
import LoadingResultAlert from "../../../components/Common/LoadingResultAlert";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import PropTypes from "prop-types";

import classes from "../../IntegrationPage/integration.module.css";
import ConnectEshop from "../ConnectTab/ConnectEshopTab";
import EshopTable from "./EshopTable";
import { useHistory, useRouteMatch } from "react-router";
import { getSystemErrorMap } from "util";
import { RootState } from "../../../store";
const woocommerce = `/integration/logos/woocommerce.png`;
const shoptet = `/integration/logos/shoptet.png`;
const prestashop = `/integration/logos/prestashop.png`;

export enum EShopTypes {
  WOOCOMERCE = "WOOCOMERCE",
  SHOPTET = "SHOPTET",
  PRESTASHOP = "PRESTASHOP",
}

export enum EShopTypesLabels {
  WOOCOMERCE = "Woocomerce",
  SHOPTET = "ShopTet",
  PRESTASHOP = "PrestaShop",
}

const EshopTab = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickWooComerce = () => {
    history.push(`connect`, { eshoptype: EShopTypes.WOOCOMERCE });
  };

  const handleClickShoptet = () => {
    history.push(`connect`, { eshoptype: EShopTypes.SHOPTET });
  };

  const handleClickPrestaShop = () => {
    history.push(`connect`, { eshoptype: EShopTypes.PRESTASHOP });
  };

  return (
    <React.Fragment>
      <h5 style={{ marginBottom: "30px" }}>{t("EShop.title")}</h5>
      <LoadingResultAlert actiontype={EshopActions.CONNECT_ESHOP} />
      <Row>
        <Col md="4">
          <div className={classes.service__tab}>
            <img src={woocommerce} style={{ paddingTop: "25px" }} />
            <Button color="primary" onClick={handleClickWooComerce}>
              {t("EShop.connect")}
            </Button>
          </div>
        </Col>
        <Col md="4">
          <div className={classes.service__tab}>
            <img src={shoptet} />
            <Button color="primary" onClick={handleClickShoptet}>
              {t("EShop.connect")}
            </Button>
          </div>
        </Col>
        <Col md="4">
          <div className={classes.service__tab}>
            <img src={prestashop} style={{ paddingTop: "25px" }} />
            <Button color="primary" onClick={handleClickPrestaShop}>
              {t("EShop.connect")}
            </Button>
          </div>
        </Col>
      </Row>

      <EshopTable />
      <LoadingResultAlert actiontype={EshopActions.EDIT_ESHOP_BY_ID} />
    </React.Fragment>
  );
};

export default connect((state: RootState) => ({}), {})(EshopTab);
