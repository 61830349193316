import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// Redux
import { connect, ConnectedProps } from "react-redux";
import {
  Link,
  withRouter,
  useLocation,
  RouteComponentProps,
} from "react-router-dom";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//Import config
import { facebook, google } from "../../config";

// availity-reactstrap-validation

// actions
import { apiError, loginUser } from "../../store/actions";
import { getCustomServerValidation } from "../../helpers/validation_helper";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";

import trustbite_logo from "../../assets/images/trustbite/color_logo.png";
import CopyrightAdalo from "../../components/Common/CopyrightAdalo";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/Common/LoadingButton";
import LoginActions from "../../store/auth/login/actionTypes";
import ResetActions from "../../store/account/actionTypes";
import LoadingResultAlert from "../../components/Common/LoadingResultAlert";
import { RootState } from "../../store";
import { isValidationError, IValidationError } from "../../models/common/saga";
import EAction from "../../store/account/actionTypes";
const { AvField, AvForm, AvInput } = require("availity-reactstrap-validation");

function LoginStatus() {
  const location = useLocation();
  const [activationStatus, setActivationStatus] = useState<string | null>(null);
  const { t } = useTranslation();
  const handleClose = () => setActivationStatus(null);

  useEffect(() => {
    const search = location.search;
    //console.log(search);
    const params = new URLSearchParams(search);
    //console.log(params.get("activation"));
    setActivationStatus(params.get("activation"));
  }, [location]);

  return (
    <Modal isOpen={activationStatus != null} toggle={handleClose}>
      <ModalBody>
        <Alert color={activationStatus == "ok" ? "success" : "danger"}>
          {activationStatus == "ok" && t("Login.activationOk")}
          {activationStatus == "err" && t("Login.activationFail")}
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={handleClose}>
          {t("Login.continue")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

type Props = PropsFromRedux & RouteComponentProps;

function Login(props: Props) {
  // handleValidSubmit
  const handleValidSubmit = (event: Event, values: any) => {
    const { rememberLogin, ...restValues } = values;
    props.loginUser(restValues, props.history, rememberLogin);
  };

  React.useEffect(() => {
    props.apiError(null);
  }, []);



  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <img src={trustbite_logo} height="78"></img>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col> */}
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/* <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div> */}
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleValidSubmit}
                    >
                      <LoadingResultAlert
                        actiontype={LoginActions.LOGIN_USER}
                      />
                      <LoadingResultAlert
                          actiontype={ResetActions.RESET_PASSWORD}
                      />
                      <div className="mb-3">
                        <AvField
                          name="login"
                          label={t("Login.email")}
                          value=""
                          className="form-control"
                          placeholder={t("Login.email")}
                          // type="email"
                          required
                          validate={getCustomServerValidation(
                            "login",
                            isValidationError(props.error) && props.error || {} as IValidationError
                          )}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label={t("Login.password")}
                          value=""
                          type="password"
                          required
                          placeholder={t("Login.password")}
                          validate={getCustomServerValidation(
                            "password",
                            isValidationError(props.error) && props.error || {} as IValidationError
                          )}
                        />
                      </div>
                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Zapamatovat přihlášení
                        </label>
                      </div> */}
                      <AvInput type="checkbox" name="rememberLogin" />{" "}
                      {t("Login.rememberPassword")}
                      <div className="mt-3 d-grid">
                        {/* <button type="submit">{t("Login.login")}</button> */}
                        <LoadingButton
                          className="btn btn-primary btn-block"
                          type="submit"
                          title={t("Login.login")}
                          actiontype={LoginActions.LOGIN_USER}
                        ></LoadingButton>
                      </div>
                      <div className="mt-4 text-center">
                        {/* <h5 className="font-size-14 mb-3">Sign in with</h5> */}

                        <ul className="list-inline">
                          {/* tady byl social login */}
                        </ul>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />{" "}
                          {t("Login.forgotPassword")}
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t("Login.noAccountQuestion")} &nbsp;
                  <Link to="register" className="fw-medium text-primary">
                    {t("Login.register")}
                  </Link>
                </p>
                <CopyrightAdalo />
              </div>
            </Col>
          </Row>
        </Container>
        <LoginStatus></LoginStatus>
      </div>
    </React.Fragment>
  );
}

// Login.propTypes = {
//   apiError: PropTypes.any,
//   error: PropTypes.any,
//   history: PropTypes.object,
//   loginUser: PropTypes.func,
// };

const mapStateToProps = (state: RootState) => {
  const { error } = state.Login;
  return { error };
};

const connector = connect(mapStateToProps, { loginUser, apiError });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(Login));
