import React from "react";
import { getColorForSatisfactionLevel } from "../../utils/feedbacksUtils";
import SatisfactionEnum from "../../models/common/satisfactionEnum";
import { IconBaseProps } from "react-icons/lib";
import {
  BsEmojiHeartEyes,
  BsEmojiAngry,
  BsEmojiSmile,
  BsEmojiFrown,
} from "react-icons/bs";

const SatisfactionIcon = ({ level, ...props }: IProps) => {
  const color = getColorForSatisfactionLevel(level);

  if (level == "VERY_DISSATISFIED")
    return <BsEmojiAngry {...props} color={color}></BsEmojiAngry>;

  if (level == "DISSATISFIED") return <BsEmojiFrown {...props} color={color} />;

  if (level == "SATISFIED") return <BsEmojiSmile {...props} color={color} />;

  if (level == "VERY_SATISFIED")
    return <BsEmojiHeartEyes {...props} color={color} />;

  return null;
};


interface IProps extends IconBaseProps {
  level: SatisfactionEnum;
}

export default SatisfactionIcon;
