import EAction from "./actionTypes";
import {
  LoginUserAction,
  LoginFailAction,
  LoginSuccessAction,
  LogoutUserAction,
  LogoutUserSuccessAction,
  ApiErrorAction,
} from "./actions";
import { ApiError } from "../../../utils/errorUtils";

interface IReduxState {
  error: ApiError | null;
  loading: boolean;
}

const initialState = {
  error: null,
  loading: false,
} as IReduxState;

type IActionResult =
  | LoginUserAction
  | LoginFailAction
  | LoginSuccessAction
  | LogoutUserAction
  | LogoutUserSuccessAction
  | ApiErrorAction;

const login = (state = initialState, action: IActionResult) => {
  switch (action.type) {
    case EAction.LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EAction.LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case EAction.LOGOUT_USER:
      state = { ...state };
      break;
    case EAction.LOGOUT_USER_SUCCESS:
      state = { ...state };
      break;
    case EAction.API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
