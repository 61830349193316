import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ButtonGroup,
} from "reactstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { FiXCircle, FiEdit } from "react-icons/fi";
import { TableCellProps, UseTableCellProps } from "react-table";
import { ColumnData } from "./EshopTable";

const ActionCellComponent = (props: UseTableCellProps<ColumnData>) => {
  const { row } = props;
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();
  const handleEditClick = () => {
    history.push(`connect/${row.original.id}`);
  };

  const handleOpenModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const handleDeleteClick = () => {
    row.original.deleteEshopById(row.original.id);
    handleCloseModal();
  };

  const popoverTargetId = "popover-" + row.original.id;


  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="d-flex justify-content-end">
        <ButtonGroup>
          <Button color="primary" onClick={handleEditClick}>
            {/* {t("EShop.table.edit")} */}
            <FiEdit size={20}></FiEdit>
          </Button>
          <Button color="danger" id={popoverTargetId} onClick={handleOpenModal}>
            <FiXCircle size={20} />
          </Button>
        </ButtonGroup>
        {/* <LoadingButton
        onClick={handleDeleteClick}
        className="btn btn-primary btn-block"
        type="submit"
        color="danger"
        title={t("Eshop.table.dele")}
        actiontype={DELETE_ESHOP_BY_ID}
      ></LoadingButton> */}
      </div>

      <Modal
          centered
          isOpen={showModal}
          onHide={handleCloseModal}
      >
        <ModalHeader
            className="d-flex justify-content-center"
        >
          {t("EShop.modal.delete")}
        </ModalHeader>
        <ModalBody
            className="d-flex justify-content-center"
        >
          <Button
              centered
              color="danger"
              className="text-uppercase mx-1"
              onClick={handleCloseModal}
          >
            {t("EShop.modal.no")}
          </Button>
          <Button
              centered
              color="success"
              className="text-uppercase mx-1"
              onClick={handleDeleteClick}>
            {t("EShop.modal.yes")}
          </Button>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};



export const ActionCell = ActionCellComponent; //connect(null, { deleteEshopById })(
//  ActionCellComponent
//);

export const EshopTypeCell = (props: any) => {
  return props.value;
};

EshopTypeCell.propTypes = {
  value: PropTypes.string,
};
