import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap";

// availity-reactstrap-validation
const { AvField, AvForm } = require("availity-reactstrap-validation");
import Recaptcha from "../../components/Google/Recaptcha";

// action
import {
  apiError,
  registerUser,
  registerUserFailed,
} from "../../store/actions";

// Redux
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";

import {
  customServerValidation,
  getCustomServerValidation,
} from "../../helpers/validation_helper";
import { useTranslation } from "react-i18next";
import CopyrightAdalo from "../../components/Common/CopyrightAdalo";
import LoadingButton from "../../components/Common/LoadingButton";
import LoadingResultAlert from "../../components/Common/LoadingResultAlert";
import EAction from "../../store/auth/register/actionTypes";
import { IValidationError } from "../../models/common/saga";
import { RootState } from "../../store";

type Props = PropsFromRedux;

function Register(props: Props) {
  const recaptchaRef = useRef<Recaptcha>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const { t } = useTranslation();

  const handleValidSubmit = (event: Event, values: any) => {
    props.registerUser({ ...values, recaptchaToken });
    if (null != recaptchaRef.current) recaptchaRef.current.reset();
  };

  useEffect(() => {
    props.apiError("");
    // props.registerUserFailed(null);
  }, []);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{t("Register.title")}</h5>
                        <p></p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    {/* <Link to="/">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <img
                      src={logoImg}
                      alt=""
                      className="rounded-circle"
                      height="34"
                    />
                  </span>
                </div>
              </Link> */}
                  </div>
                  <div className="p-2">
                    {/* <ActionResultAlert
              success={props.data}
              error={props.registrationError}
            /> */}
                    <LoadingResultAlert
                      actiontype={EAction.REGISTER_USER_BY_EMAIL}
                    />

                    {props.isSuccessfulRegistration ? (
                      <p>
                        <Link to="login" className="fw-medium text-primary">
                          <Button>{t("Register.gobacktologin")}</Button>
                        </Link>{" "}
                      </p>
                    ) : (
                      <AvForm
                        className="needs-validation"
                        onValidSubmit={handleValidSubmit}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label={t("Register.email")}
                            className="form-control"
                            placeholder={t("Register.email")}
                            type="email"
                            required
                            validate={getCustomServerValidation(
                              "email",
                              props.registrationError
                            )}
                            errorMessage="Povinné pole!"
                          />
                        </div>

                        {/* <div className="mb-3">
                  <AvField
                    name="username"
                    label="Username"
                    type="text"
                    required
                    placeholder="Enter username"
                  />
                </div> */}
                        <div className="mb-3">
                          <AvField
                            name="password"
                            label={t("Register.password")}
                            type="password"
                            required
                            placeholder={t("Register.password")}
                            validate={{
                              serverError: customServerValidation(
                                props.registrationError,
                                "password"
                              ),
                            }}
                            errorMessage="Povinné pole!"
                          />
                        </div>

                        {/* <GoogleReCaptcha onVerify={handleVerify} /> */}
                        <Recaptcha
                          ref={recaptchaRef}
                          onChange={setRecaptchaToken}
                        />
                        <div className="mt-4 d-grid">
                          <LoadingButton
                            actiontype={EAction.REGISTER_USER_BY_EMAIL}
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled={recaptchaToken == null}
                            title={t("Register.register")}
                          ></LoadingButton>
                        </div>

                        <div className="mt-4 text-center">
                          {/* <p className="mb-0">
                    By registering you agree to the Skote{" "}
                    <Link to="#" className="text-primary">
                      Terms of Use
                    </Link>
                  </p> */}
                        </div>
                      </AvForm>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t("Register.alreadyHaveAccountQuestion")}{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    {t("Register.signIn")}
                  </Link>{" "}
                </p>
                <CopyrightAdalo></CopyrightAdalo>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  const { data, registrationError, loading } = state.RegisterAccount;
  return {
    data,
    registrationError,
    loading,
    isSuccessfulRegistration: data != null,
  };
};

const connector = connect(mapStateToProps, {
  registerUser,
  apiError,
  registerUserFailed,
});

export default connector(Register);
