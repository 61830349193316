import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import LayoutSaga from "./layout/saga";
import feedbacksSaga from "./feedbacks/saga";
import accountSaga from "./account/saga";
import authInfoSaga from "./auth/authInfo/saga";
import handleServerActionsSaga from "./loading/saga";
import EshopSaga from "./eshop/saga";
import EventsSaga from "./events/saga";
import IntegrationSaga from "./integration/saga";
import ConfigurationSaga from "./configuration/saga";
import * as Events from "events";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(LayoutSaga),
    fork(feedbacksSaga),
    fork(accountSaga),
    fork(authInfoSaga),
    fork(handleServerActionsSaga),
    fork(EshopSaga),
    fork(EventsSaga),
    fork(IntegrationSaga),
    fork(ConfigurationSaga)
  ]);
}
