import React from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import RatingBar from "../../components/Common/RatingBar";
import UploadState from "./UploadState"
import {connect, ConnectedProps} from "react-redux";
import {
    answeredImagePath,
} from "../../utils/imageService";
import SatisfactionIcon from "../../components/Common/SatisfactionIcon";
import {syncOverviewWithLatest} from "../../store/feedbacks/actions";
import {
    getSatisfactionTextFromLevel,
    getColorForSatisfactionLevel,
    ReviewHeading,
    getFontWeightForFeedback,
    getShadowStyleForFeedback,
} from "../../utils/feedbacksUtils";
import ServiceImage from "../../components/Common/ServiceImage";
import StoreTypeImage from "../../components/Common/StoreTypeImage";
import {fromNow} from "../../utils/timeUtils";
import {ReviewModel} from "../../models/common/fromGenerated";
import {getServiceEnumFromString} from "../../models/common/serviceEnum";
import {getStoreTypeEnumFromString} from "../../models/common/storeTypeEnum";
import ToggleImage from "../../components/Common/ToggleImage";

const FeedbackDashboardCard = ({feedback, syncOverviewWithLatest}: Props) => {
    const history = useHistory();
    const {t, i18n} = useTranslation();

    const handleGotoDetailClick = (id: string) => () => {
        syncOverviewWithLatest();
        history.push("/feedbacks/" + id);
    };

    return (
        <Card
            style={{
                boxShadow: getShadowStyleForFeedback(feedback),
            }}
        >
            <CardBody>
                <Row>
                    <Col lg="9" md="10">
                        <ReviewHeading isBold={feedback.unread!} className="text-truncate">
                            {feedback.customerMessage}
                        </ReviewHeading>
                        <p>
                            {" "}
                            {fromNow(new Date(feedback.created!).getTime(), i18n.language)}
                        </p>
                    </Col>
                    <Col lg="3" md="2" style={{textAlign: "end"}}>
                        <ServiceImage
                            serviceName={getServiceEnumFromString(feedback.reviewServiceName!)}
                        ></ServiceImage>
                    </Col>
                </Row>
                <Row style={{alignItems: "center"}}>
                    <Col lg="1" md="2">
                        {/* <img
                          src={feedback.profilePicture}
                          style={{
                            borderRadius: "50%",
                            width: "100%",
                            maxWidth: "40px",
                          }}
                        /> */}
                        <SatisfactionIcon
                            level={feedback.satisfactionLevel!}
                            size={40}
                        ></SatisfactionIcon>
                    </Col>
                    <Col lg="2" md="10">
                      <span>
                        {getSatisfactionTextFromLevel(t, feedback.satisfactionLevel!)}
                      </span>
                    </Col>
                    <Col lg="1" md="2" style={{padding: "0"}}>
                        <StoreTypeImage type={getStoreTypeEnumFromString(feedback.reviewFor!)}></StoreTypeImage>
                    </Col>
                    <Col lg="3" md="10">
                        {/* <img src={feedback.rating} /> */}
                        <RatingBar
                            color={getColorForSatisfactionLevel(feedback.satisfactionLevel!)}
                            rating={feedback.satisfactionValue! * 0.01}
                            containerStyle={{
                                fontWeight: getFontWeightForFeedback(feedback),
                            }}
                        ></RatingBar>
                    </Col>
                    <Col lg="3" md="3"
                    style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        {Object.keys(feedback.reviewServicesSyncStatusMap!).map((key, i) => (
                            <UploadState
                            shop={key}
                            status={feedback.reviewServicesSyncStatusMap![key]}
                            />
                        ))}
                        <div style={{minWidth: "18px"}}>
                            {feedback.hasFeedback && <img src={answeredImagePath}/>}
                        </div>
                    </Col>
                    <Col lg="1" md="6" style={{padding: "0"}}>
                        <Button
                            color="primary"
                            style={{fontWeight: getFontWeightForFeedback(feedback)}}
                            onClick={handleGotoDetailClick(feedback.id!)}
                        >
                            {t("Dashboard.feedbackDetail")}
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};


interface IOwnProps {
    feedback: ReviewModel
}

const connector = connect(null, {syncOverviewWithLatest});
type Props = ConnectedProps<typeof connector> & IOwnProps;
export default connector(FeedbackDashboardCard);
