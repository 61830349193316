import React from "react";
import { getImagePathForStoreType } from "../../utils/imageService";
import StoreTypeEnum from "../../models/common/storeTypeEnum";
const StoreTypeImage = ({ type, ...restProps } : IProps) => {
  return <img src={getImagePathForStoreType(type)} {...restProps} />;
};

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  type: StoreTypeEnum;
}

export default StoreTypeImage;
