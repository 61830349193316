import React from "react";
import { InputProps } from "reactstrap";
import styled from "styled-components";

const BarContainer = styled.div`
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
  border-radius: 4px;
`;

const Rating = styled.div`
  background-color: ${(props: RatingProps) => props.color || "black"};
  width: ${(props: RatingProps) => props.rating * 100 + "%"};
  height: 16px;
  border-radius: 4px;
`;

const RatingTextContainer = styled.p`
  position: absolute;
  text-align: center;
  width: 100%;
  font-size: 10px;
  color: ${(props: IRatingTextContainerProps) =>
    props.light ? "white" : "#888"};
`;

type RatingProps = Omit<IProps, "containerStyle">;

interface IRatingTextContainerProps {
  light: boolean;
}

interface IProps {
  rating: number;
  color: string;
  containerStyle?: any;
}

const RatingBar = ({ rating, color, containerStyle }: IProps) => {
  return (
    <div style={containerStyle}>
      <BarContainer>
        <RatingTextContainer light={rating > 0.6}>{`${
          rating * 100
        }%`}</RatingTextContainer>
        <Rating rating={rating} color={color}></Rating>
      </BarContainer>
    </div>
  );
};

export default RatingBar;
