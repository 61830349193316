import {
  takeEvery,
  put,
  call,
  select,
  fork,
  takeLatest,
} from "redux-saga/effects";
import {
  EventsListRequestModel,
  LogModel,
  PageModel,
  PageResultModelLogModel,
} from "../../models/common/fromGenerated";
import EventsActions from "./actionTypes";
import {
  getEventsOverview as getEventsOverviewAction,
  getEventsOverviewFail,
  getEventsOverviewSuccess,
  ChangeEventsOverviewPagingAction
} from "./actions";

import {
  getEventsOverview
} from "../../helpers/api";
import { RootState } from "..";


function* onEventsOverview() {
  try {
    const paging: PageModel = yield select(
        (state: RootState) => state.Events.eventsOverview.page
    );

    const response: PageResultModelLogModel = yield call(getEventsOverview, {
      page: paging,
      sort: { asc: true, column: "string" },
    });

    yield put(getEventsOverviewSuccess(response));
  } catch (error) {
    yield put(getEventsOverviewFail(error as Error));
  }
}

function* onChangeEventsOverviewPaging({payload,}: ChangeEventsOverviewPagingAction) {
  yield put(getEventsOverviewAction());
}


function* connectEventsSaga() {
  yield takeEvery(EventsActions.GET_EVENTS_OVERVIEW, onEventsOverview);
  yield takeEvery(EventsActions.CHANGE_EVENTS_OVERVIEW_PAGING, onChangeEventsOverviewPaging);
}

export default connectEventsSaga;
