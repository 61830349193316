import { MessageModel } from "../../../models/common/fromGenerated";
import { reduxAction } from "../../../models/common/saga";
import EAction from "./actionTypes";

export const userForgetPassword = (user: any) =>
  reduxAction(EAction.FORGET_PASSWORD, { user });
export const userForgetPasswordSuccess = (user: any, message: string) =>
  reduxAction(EAction.FORGET_PASSWORD_SUCCESS, { user, message });
export const userForgetPasswordError = (error: any) =>
  reduxAction(EAction.FORGET_PASSWORD_ERROR, error);

export type UserForgetPassword = ReturnType<typeof userForgetPassword>;
export type UserForgetPasswordSuccess = ReturnType<typeof userForgetPasswordSuccess>;
export type UserForgetPasswordError = ReturnType<typeof userForgetPasswordError>;
