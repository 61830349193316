import React from "react";
import ServiceEnum from "../../models/common/serviceEnum";
import { getImagePathForServiceType } from "../../utils/imageService";

const ServiceImage = ({ serviceName, ...restProps }: IProps) => {
  return <img src={getImagePathForServiceType(serviceName)} {...restProps} />;
};

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  serviceName: ServiceEnum;
}
export default ServiceImage;
