import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import EshopTab from "../SettingsPage/EshopTab/EshopTab";
import ZboziTab from "./ZboziTab";
import HeurekaTab from "./HeurekaTab";
import GooMojeFirmaTab from "./GooMojeFirmaTab";
import GooMerchantTab from "./GooMerchantTab";

import classes from "./integration.module.css";
import { connect, ConnectedProps } from "react-redux";
import { Redirect } from "react-router";
import { Row, Container, Col, Card, CardBody } from "reactstrap";
import SettingsRedirect from "../../components/Common/SettingsRedirect";

import { getPortalConfiguration } from "../../store/configuration/actions";
import { RootState } from "../../store";

enum TabType  {
  ESHOP = "ESHOP",
  ZBOZI = "ZBOZI",
  HEUREKA = "HEUREKA",
  GOOMOJEFIRMA = "GOOMOJEFIRMA",
  GOOMERCHANT = "GOOMERCHANT",
};

const IntegrationPage = ({ getPortalConfiguration } : Props) => {
  const [visibleTabType, setVisibleTabType] = useState(TabType.ZBOZI);

  const onClickChangeTab = (tabType : TabType)  => () => {
    setVisibleTabType(tabType);
  };

  useEffect(() => {
    getPortalConfiguration();
  }, []);

  return (
    <React.Fragment>
      <SettingsRedirect />
      <div className="page-content">
        <MetaTags>
          <title>Integrace | TrustBite</title>
        </MetaTags>
        <Container>
          <Row>
            <Col md="3">
              {/* <a href="#" onClick={onClickChangeTab(tabType.ESHOP)}>
                <div className={classes.service}>
                  <img src="./integration/icons/shopping-cart.png" />
                  <h5 className={classes.no__bottom_margin}>E-shop</h5>
                </div>
  </a>*/}
              <a href="#" onClick={onClickChangeTab(TabType.ZBOZI)}>
                <div className={classes.service}>
                  <img src="./integration/icons/zbozi.png" />
                  <h5 className={classes.no__bottom_margin}>Zboží.cz</h5>
                </div>
              </a>
              <a href="#" onClick={onClickChangeTab(TabType.HEUREKA)}>
                <div className={classes.service}>
                  <img src="./integration/icons/heureka.png" />
                  <h5 className={classes.no__bottom_margin}>Heureka</h5>
                </div>
              </a>
              {/* <a href="#" onClick={onClickChangeTab(tabType.GOOMOJEFIRMA)}>
                <div className={classes.service}>
                  <img src="./integration/icons/g-moje-firma.png" />
                  <h5 className={classes.no__bottom_margin}>
                    Google moje firma
                  </h5>
                </div>
              </a>
              <a href="#" onClick={onClickChangeTab(tabType.GOOMERCHANT)}>
                <div className={classes.service}>
                  <img src="./integration/icons/g-merchant-center.png" />
                  <h5 className={classes.no__bottom_margin}>
                    Google Merchant center
                  </h5>
                </div>
              </a> */}
            </Col>
            <Col md="9">
              <Card>
                <CardBody>
                  {/* {visibleTabType == tabType.ESHOP && <EshopTab />} */}
                  {visibleTabType == TabType.ZBOZI && <ZboziTab />}
                  {visibleTabType == TabType.HEUREKA && <HeurekaTab />}
                  {visibleTabType == TabType.GOOMOJEFIRMA && (
                    <GooMojeFirmaTab />
                  )}
                  {visibleTabType == TabType.GOOMERCHANT && <GooMerchantTab />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

const connector = connect(mapStateToProps, { getPortalConfiguration });

type Props = ConnectedProps<typeof connector>;

export default connector(IntegrationPage);
