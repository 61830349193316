import {
  LogModel,
  PageResultModelLogModel
} from "../../models/common/fromGenerated";
import {
  reduxAction,
  reduxActionBasic,
} from "../../models/common/saga";
import EAction from "./actionTypes";


export const getEventsOverview = () =>
    reduxActionBasic(EAction.GET_EVENTS_OVERVIEW);

export const getEventsOverviewSuccess = (payload: PageResultModelLogModel) =>
    reduxAction(EAction.GET_EVENTS_OVERVIEW_SUCCESS, payload);

export const getEventsOverviewFail = (error: Error) =>
    reduxAction(EAction.GET_EVENTS_OVERVIEW_FAIL, error);

export const changeEventsOverviewPaging = (payload: any) =>
    reduxAction(EAction.CHANGE_EVENTS_OVERVIEW_PAGING, payload);


export type GetEventsOverviewAction = ReturnType<typeof getEventsOverview>;
export type GetEventsOverviewSuccessAction = ReturnType<typeof getEventsOverviewSuccess>;
export type GetEventsOverviewFailAction = ReturnType<typeof getEventsOverviewFail>;
export type ChangeEventsOverviewPagingAction = ReturnType<typeof changeEventsOverviewPaging>;



