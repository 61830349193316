import Actions from "./actionTypes";

import { combineReducers } from "redux";
import {
    GetEventsOverviewAction,
    GetEventsOverviewSuccessAction,
    ChangeEventsOverviewPagingAction
} from "./actions";
import { LogModel, PageModel} from "../../models/common/fromGenerated";


const overviewInitialState = {
  data: [],
  page: {
    page: 0,
    recordPerPage: 10,
  },
  total: 100,
} as OverviewState;

type OverviewAction =
    | GetEventsOverviewAction
    | ChangeEventsOverviewPagingAction
    | GetEventsOverviewSuccessAction;

interface OverviewState {
  data: LogModel[];
  page?: PageModel;
  total?: number;
}

const eventsOverview = (
    state = overviewInitialState,
    action: OverviewAction
): OverviewState => {
  switch (action.type) {
    case Actions.GET_EVENTS_OVERVIEW_SUCCESS:
      return {
        ...state,
        data: action.payload.items || [],
        total: action.payload.totalItems,
        page: action.payload.page,
      };
    case Actions.CHANGE_EVENTS_OVERVIEW_PAGING:
      return {
        ...state,
        page: {
          ...state.page,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};


export default combineReducers({
  eventsOverview,
});
