import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";

// Redux
import { connect, ConnectedProps } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

// availity-reactstrap-validation

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import CopyrightAdalo from "../../components/Common/CopyrightAdalo";
import { ActionResultAlert } from "../../components/Common/ActionResultAlert";
import LoadingButton from "../../components/Common/LoadingButton";
import EAction from "../../store/auth/forgetpwd/actionTypes";
import LoadingResultAlert from "../../components/Common/LoadingResultAlert";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store";
const { AvField, AvForm } = require("availity-reactstrap-validation");

type Props = PropsFromRedux & RouteComponentProps;

function ForgetPasswordPage(props: Props) {
  // handleValidSubmit
  const handleValidSubmit = (event: Event, values: any) => {
    props.userForgetPassword(values);
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          {t("ForgetPassword.passwordRecovery")}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <LoadingResultAlert actiontype={EAction.FORGET_PASSWORD} />
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleValidSubmit}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label={t("Common.email")}
                          className="form-control"
                          placeholder={t("Common.email")}
                          type="email"
                          required
                          validate={{
                            required: {value: true, errorMessage: t("Common.required")},
                            pattern: {value: '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$', errorMessage: t("ForgetPassword.wrongEmail")}
                          }}
                          errorMessage={t("ForgetPassword.wrongEmail")}
                        />
                      </div>
                      <div className="text-end">
                        <LoadingButton
                          title={t("ForgetPassword.resetPassword")}
                          className="btn btn-primary w-md"
                          type="submit"
                          actiontype={EAction.FORGET_PASSWORD}
                        ></LoadingButton>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t("ForgetPassword.gobackto")}{" "}
                  <Link to="login" className="fw-medium text-primary">
                    {t("ForgetPassword.login")}
                  </Link>
                </p>
                <CopyrightAdalo />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state: RootState) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

const connector = connect(mapStateToProps, { userForgetPassword });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(ForgetPasswordPage));
